import React from 'react';
import mosRuPicSrc from '../../../../assets/img/content/partners/mos-ru.png';
import ditPicSrc from '../../../../assets/img/content/partners/dit.png';
import msrPicSrc from '../../../../assets/img/content/partners/msr.png';
import mvdPicSrc from '../../../../assets/img/content/partners/mvd.png';
import mincifriPicSrc from '../../../../assets/img/content/partners/mincifri.png';
import { LayoutContent } from '../../../../common/components/layout/LayoutContent/LayoutContent';
import { BaseLink } from '../../../../common/components/ui/BaseLink/BaseLink';
import { SECTION_IDS } from '../../../../constants';
import { useMetricsContext } from '../../../../store/metrics/hooks/useMetricsContext';
import s from './SectionPartners.module.scss';

const logos = [
  {
    imgSrc: mosRuPicSrc,
    link: 'https://www.mos.ru/'
  },
  {
    imgSrc: ditPicSrc,
    link: 'https://www.mos.ru/dit/'
  },
  {
    imgSrc: msrPicSrc,
    link: 'https://www.mos.ru/msr/'
  },
  // {
  //   imgSrc: govRuPicSrc,
  //   link: 'https://epp.genproc.gov.ru/web/gprf'
  // },
  {
    imgSrc: mvdPicSrc,
    link: 'https://мвд.рф/'
  },
  // {
  //   imgSrc: bankPicSrc,
  //   link: 'https://cbr.ru/'
  // },
  {
    imgSrc: mincifriPicSrc,
    link: 'https://digital.gov.ru/ru/'
  }
];

export const SectionPartners: React.FC = () => {
  const { observeNode } = useMetricsContext();

  return (
    <LayoutContent
      ref={(r) => r && observeNode(SECTION_IDS.partners, r)}
      className={s.SectionPartners}
      narrow
    >
      <div className={s.SectionPartners__grid}>
        <div className={s.SectionPartners__main}>
          <h2 className={s.SectionPartners__title}>Партнёры</h2>
          <div className={s.SectionPartners__text}>
            Проект реализован при поддержке Правительства Москвы совместно
            с партнерами
          </div>
        </div>
        <div className={s.SectionPartners__ext}>
          <div className={s.SectionPartners__logos}>
            {logos.map((item, iItem) => (
              <BaseLink
                key={iItem}
                className={s.SectionPartners__logo}
                href={item.link}
                isClickable={!!item.link}
                // style={{ backgroundImage: `url(${item.imgSrc})` }}
              >
                <img src={item.imgSrc} alt="" />
              </BaseLink>
            ))}
          </div>
        </div>
      </div>
    </LayoutContent>
  );
};
