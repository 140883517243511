import React from 'react';
import { LayoutContent } from '../../layout/LayoutContent/LayoutContent';
import { ScrollToTopButton } from './ScrollToTopButton/ScrollToTopButton';
import s from './FlyActions.module.scss';

export const FlyActions: React.FC = () => {
  return (
    <LayoutContent
      className={s.FlyActions}
      innerClassName={s.FlyActions__inner}
    >
      <div className={s.FlyActions__actions}>
        <ScrollToTopButton />
      </div>
    </LayoutContent>
  );
};
