import React from 'react';
import picSrc from '../../../../assets/img/content/main/pic.svg';
import { LayoutContent } from '../../../../common/components/layout/LayoutContent/LayoutContent';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../../common/components/ui/Button';
import { ScrollLink } from '../../../../common/components/ui/reactScroll/ScrollLink';
import { SECTION_IDS, sectionLabelsById } from '../../../../constants';
import { useMetricsContext } from '../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../store/metrics/types';
import { useMainScreenQuery } from '../../../../api/hooks/useMainScreenQuery';
import { useQueryLoading } from '../../../../store/api/hooks/useQueryLoading';
import { createImageUrl } from '../../../../api/hooks/useImageUrl';
import { externalLinkHtmlProps } from '../../../../common/constants';
import s from './SectionMain.module.scss';

export interface SectionMainProps {}

export const SectionMain: React.FC<SectionMainProps> = () => {
  const mainScreenQuery = useMainScreenQuery();
  const loading = useQueryLoading(mainScreenQuery);
  const main = mainScreenQuery.state.data;

  const { params, observeNode } = useMetricsContext();

  const handleButtonClick = (label: string) => {
    if (label) {
      params({
        Главная: {
          Кнопки: {
            [label]: MetricsParams.Click
          }
        }
      });
    }
  };

  const imageSrc = createImageUrl(main?.image) || picSrc;
  const imageLink = main?.image_link;

  const imageComp = (
    <div className={s.Image}>
      <img
        className={s.Image__img}
        src={imageSrc}
        alt={main?.image_alt || ''}
      />
    </div>
  );

  return (
    <LayoutContent
      ref={(r) => r && observeNode(SECTION_IDS.main, r)}
      className={s.SectionMain}
      innerClassName={s.SectionMain__inner}
      narrow
    >
      {loading || (
        <div className={s.SectionMain__grid}>
          <div className={s.SectionMain__content}>
            <h1 className={s.SectionMain__title}>Перезвони сам</h1>
            <p className={s.SectionMain__description}>
              Информационный проект для всех, кто хочет узнать,
              как&nbsp;обезопасить себя и&nbsp;не попасться в&nbsp;руки
              мошенникам
            </p>
            <div className={s.SectionMain__actions}>
              <div className={s.SectionMain__action}>
                <ScrollLink to={SECTION_IDS.webinars}>
                  <Button
                    className={s.SectionMain__eventsButton}
                    size={ButtonSize.xlarge}
                    onClick={() =>
                      handleButtonClick(sectionLabelsById.webinars)
                    }
                  >
                    {sectionLabelsById.webinars}
                  </Button>
                </ScrollLink>
              </div>
              <div className={s.SectionMain__action}>
                <ScrollLink to={SECTION_IDS.call_center}>
                  <Button
                    className={s.SectionMain__callCenterButton}
                    size={ButtonSize.xlarge}
                    variant={ButtonVariant.secondary}
                    onClick={() =>
                      handleButtonClick(sectionLabelsById.call_center)
                    }
                  >
                    {sectionLabelsById.call_center}
                  </Button>
                </ScrollLink>
              </div>
            </div>
          </div>
          <div className={s.SectionMain__pic}>
            {imageLink ? (
              <a href={imageLink} {...externalLinkHtmlProps}>
                {imageComp}
              </a>
            ) : (
              imageComp
            )}
          </div>
        </div>
      )}
    </LayoutContent>
  );
};
