import React from 'react';
import { PageLayout } from '../../common/components/layout/PageLayout/PageLayout';
import { SECTION_IDS } from '../../constants';
import { ScrollElement } from '../../common/components/ui/reactScroll/ScrollElement';
import { BannerWebinarCancelled } from '../../common/components/ui/banner/BannerWebinarCancelled/BannerWebinarCancelled';
import { useBanners } from '../../store/banners/hooks/useBanners';
import { BannerEvent } from '../../common/components/ui/banner/BannerEvent/BannerEvent';
import { SectionMain } from './sections/SectionMain/SectionMain';
import { SectionAbout } from './sections/SectionAbout/SectionAbout';
import { SectionTimer } from './sections/SectionTimer/SectionTimer';
import { SectionPartners } from './sections/SectionPartners/SectionPartners';
import { SectionCallCenter } from './sections/SectionCallCenter/SectionCallCenter';
import { SectionTests } from './sections/SectionTests/SectionTests';
import { SectionSubscription } from './sections/SectionSubscription/SectionSubscription';
import { SectionStories } from './sections/SectionStories/SectionStories';
import { SectionMaterials } from './sections/SectionMaterials/SectionMaterials';
import { SectionWebinarsArchive } from './sections/SectionWebinarsArchive/SectionWebinarsArchive';
import { SectionWebinars } from './sections/SectionWebinars/SectionWebinars';
import { SectionAgreements } from './sections/SectionAgreements/SectionAgreements';
import { SectionGame } from './sections/SectionGame/SectionGame';

export function IndexPage() {
  const { eventBanner } = useBanners();

  return (
    <PageLayout>
      <SectionMain />

      <ScrollElement name={SECTION_IDS.about_project}>
        <SectionAbout />
      </ScrollElement>

      {eventBanner && <BannerEvent {...eventBanner} />}

      <SectionTimer />

      <BannerWebinarCancelled />

      <ScrollElement name={SECTION_IDS.webinars}>
        <SectionWebinars />
      </ScrollElement>

      <ScrollElement name={SECTION_IDS.subscription}>
        <SectionSubscription />
      </ScrollElement>

      <ScrollElement name={SECTION_IDS.webinars_past}>
        <SectionWebinarsArchive />
      </ScrollElement>

      <ScrollElement name={SECTION_IDS.stories}>
        <SectionStories />
      </ScrollElement>

      <ScrollElement name={SECTION_IDS.tests}>
        <SectionTests />
      </ScrollElement>

      <ScrollElement name={SECTION_IDS.safe_click}>
        <SectionGame />
      </ScrollElement>

      <ScrollElement name={SECTION_IDS.materials}>
        <SectionMaterials />
      </ScrollElement>

      <ScrollElement name={SECTION_IDS.call_center}>
        <SectionCallCenter />
      </ScrollElement>

      <SectionPartners />

      <SectionAgreements />
    </PageLayout>
  );
}
