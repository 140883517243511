import React from 'react';
import {
  Modal,
  ModalProps
} from '../../../../../common/components/ui/Modal/Modal';
import s from './ModalStory.module.scss';

export interface ModalStoryProps
  extends Pick<ModalProps, 'isOpen' | 'onClose' | 'onClosed' | 'children'> {}

export const ModalStory = ({ children, ...modalProps }: ModalStoryProps) => {
  return (
    <Modal className={s.ModalStory} {...modalProps}>
      {children}
    </Modal>
  );
};
