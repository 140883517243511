import { useCallback, useEffect, useState } from 'react';
import { SECTION_IDS, sectionLabelsById } from '../../constants';
import { useApi } from '../api/hooks/useApi';
import { useWindowSize } from '../../common/hooks/useWindowSize';
import { MetricsContextType } from './MetricsContext';
import { MetricsParams } from './types';

export function useMetricsObservable(params: MetricsContextType['params']) {
  const { allLoaded } = useApi();
  const { isMobile } = useWindowSize();

  const [observableNodes, setObservableNodes] = useState<
    { id: SECTION_IDS; node: HTMLElement }[]
  >([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const visibleEntries = entries.filter((e) => e.isIntersecting);
        if (visibleEntries.length) {
          visibleEntries.forEach((ve) => {
            const nodeId = observableNodes.find(
              (n) => n.node === ve.target
            )?.id;

            const label = nodeId && sectionLabelsById[nodeId];

            if (label) {
              params({
                [label]: MetricsParams.Scroll
              });
            }
          });
        }
      },
      {
        threshold: isMobile ? 0.3 : 0.5
      }
    );

    if (allLoaded) {
      observableNodes.forEach((n) => observer.observe(n.node));
    }

    return () => {
      observer.disconnect();
    };
  }, [allLoaded, observableNodes, isMobile, params]);

  return useCallback((id, node) => {
    setObservableNodes((nodes) => {
      const nodeExists = nodes.find((n) => n.id === id);
      if (nodeExists) return nodes;
      return [...nodes, { id, node }];
    });
  }, []) as MetricsContextType['observeNode'];
}
