import { useMemo } from 'react';
import { tryParseIso } from '@proscom/ui-utils-date';
import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import { DATETIME_FORMAT_FULL } from '../../utils/date/date';

export function formatDate(
  dateIso: Date | string,
  formatString: string,
  options?: any
): string;
export function formatDate(
  dateIso: Date | string | undefined | null,
  formatString: string,
  options?: any
): string | null;
export function formatDate(
  dateIso: Date | string | undefined | null,
  formatString: string = DATETIME_FORMAT_FULL,
  options?: any
) {
  const date =
    typeof dateIso === 'string' ? tryParseIso(dateIso) : dateIso || null;
  return (
    (date && format(date, formatString, { locale: ruLocale, ...options })) ||
    null
  );
}

export const useFormatDate = (
  dateIso: Date | string | undefined | null,
  formatString: string = DATETIME_FORMAT_FULL
) => {
  return useMemo(() => {
    return formatDate(dateIso, formatString);
  }, [dateIso, formatString]);
};
