import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { MDConverter } from '../../../../utils/MDConverter';
import { mergeRefs } from '../../../../utils/mergeRefs';
import s from './MDtoHTMLContainer.module.scss';

export interface MDtoHTMLContainerProps {
  className?: string;
  mdString?: string | null;
  onLinkClick?: (e: MouseEvent) => void;
}

export const MDtoHTMLContainer = React.forwardRef<
  HTMLDivElement,
  MDtoHTMLContainerProps
>(({ className, mdString, onLinkClick }, ref) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const containerNode = containerRef.current;
    let links: NodeListOf<HTMLAnchorElement> | undefined;

    if (containerNode) {
      if (onLinkClick) {
        links = containerNode.querySelectorAll('a');
        links.forEach((link) => {
          link.addEventListener('click', onLinkClick);
        });
      }
    }

    return () => {
      if (onLinkClick) {
        links?.forEach((link) => {
          link.removeEventListener('click', onLinkClick);
        });
      }
    };
  }, [onLinkClick]);

  return (
    <div
      ref={mergeRefs(containerRef, ref)}
      className={clsx(s.MDtoHTMLContainer, className)}
      dangerouslySetInnerHTML={{ __html: MDConverter.convertToHtml(mdString) }}
    />
  );
});
