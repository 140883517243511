import { isNil } from 'lodash-es';

type ObjectType = {
  [key: number | string]: number | number[] | string | string[] | File | File[];
};

export function objectToFormData(data: ObjectType): FormData {
  function convert(
    data: ObjectType,
    formData: FormData = new FormData(),
    parentKey = ''
  ): FormData {
    for (const key in data) {
      const value = data[key];
      if (data.hasOwnProperty(key) && !isNil(value)) {
        const fullKey = parentKey ? `${parentKey}[${key}]` : key;
        if (value instanceof File) {
          // If it's a file, append it with its field name
          formData.append(fullKey, value, value.name);
        } else if (Array.isArray(value)) {
          // If it's an array, append each item with the same key
          value.forEach((item, index) => {
            convert({ [index]: item }, formData, `${fullKey}`);
          });
        } else if (typeof value === 'object') {
          // If it's a nested object, recursively process it
          convert(value, formData, fullKey);
        } else {
          // If it's a string or number, append it as a field
          formData.append(fullKey, value.toString());
        }
      }
    }

    return formData;
  }

  return convert(data);
}
