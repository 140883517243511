import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { WebinarType } from '../../../../../api/types/WebinarType';
import {
  Button,
  ButtonAnchor,
  ButtonVariant
} from '../../../../../common/components/ui/Button';
import { ReactComponent as IconChevron } from '../../../../../assets/img/icons/i-chevron-down.svg';
import { MDtoHTMLContainer } from '../../../../../common/components/ui/MDtoHTMLContainer/MDtoHTMLContainer';
import { WebinarsContextType } from '../../../../../store/webinar/WebinarsContext';
import { WebinarUtils } from '../../../../../store/webinar/WebinarUtils';
import {
  DateLabel,
  DateLabelVariant
} from '../../../../../common/components/ui/DateLabel/DateLabel';
import { useWindowSize } from '../../../../../common/hooks/useWindowSize';
import { useMetricsContext } from '../../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../../store/metrics/types';
import { sectionLabelsById } from '../../../../../constants';
import { externalLinkHtmlProps } from '../../../../../common/constants';
import s from './WebinarsList.module.scss';

interface ExtItemProps {
  title: string;
  mdString?: string;
  children?: React.ReactNode;
}

const ExtItem = ({ title, mdString, children }: ExtItemProps) => {
  return (
    <div className={s.ExtItem}>
      <div className={s.ExtItem__title}>{title}</div>
      {mdString && (
        <MDtoHTMLContainer className={s.ExtItem__content} mdString={mdString} />
      )}
      {children && <div className={s.ExtItem__content}>{children}</div>}
    </div>
  );
};

export type WebinarNodeType = { id: number; node: HTMLDivElement | null };

export interface WebinarCardProps extends WebinarType {
  className?: string;
  isPast?: boolean;
  selected?: boolean;
  onRefReady: (node: WebinarNodeType) => void;
  onWebinarLiveSignUp: WebinarsContextType['onWebinarLiveSignUp'];
  onWebinarRemind: WebinarsContextType['onWebinarRemind'];
  onScrollToWebinar: (id: WebinarNodeType['id']) => void;
}

export const WebinarCard = ({
  className,
  isPast,
  selected,
  onWebinarLiveSignUp,
  onWebinarRemind,
  onRefReady,
  onScrollToWebinar,
  ...webinar
}: WebinarCardProps) => {
  const {
    id,
    date_start,
    name,
    description,
    link,
    speaker,
    place,
    live_available_places,
    is_canceled
  } = webinar;
  const { params } = useMetricsContext();
  const ref = useRef<HTMLDivElement | null>(null);
  const [showMore, setShowMore] = useState(false);
  const { isDesktop } = useWindowSize();

  useEffect(() => {
    if (ref.current) {
      onRefReady({ id, node: ref.current });
    }
  }, [id, onRefReady]);

  useEffect(() => {
    if (showMore) {
      onScrollToWebinar(id);
    }
  }, [id, showMore, onScrollToWebinar]);

  const isOnlineOnly = !live_available_places;
  const availablePlaces = WebinarUtils.countAvailablePlaces(webinar);

  const handleLiveSignUp = () => {
    if (!availablePlaces) return;
    onWebinarLiveSignUp(id);
  };

  const handleShowMore = () => {
    const more = !showMore;
    setShowMore(more);

    if (more) {
      const moreLabel = isPast ? 'Прошедшее' : 'Предстоящее';
      params({
        [sectionLabelsById.webinars]: {
          Подробнее: {
            [moreLabel]: MetricsParams.Click
          }
        }
      });
    }
  };

  const handleActionClick = (label: string) => {
    if (label) {
      params({
        [sectionLabelsById.webinars]: {
          [label]: MetricsParams.Click
        }
      });
    }
  };

  const typeStr = (() => {
    if (is_canceled) {
      return 'Мероприятие отменено';
    }
    if (isPast) {
      return 'Запись трансляции';
    }
    if (isOnlineOnly) {
      return 'Только онлайн-трансляция';
    }
    return 'Семинар + онлайн-трансляция';
  })();

  const disabled = isPast || is_canceled;

  const actionsComp = !is_canceled && (
    <div className={s.WebinarCard__actions}>
      {isPast ? (
        link && (
          <ButtonAnchor
            href={link}
            {...externalLinkHtmlProps}
            variant={ButtonVariant.tertiary}
            onClick={() => handleActionClick('Смотреть архивную запись')}
          >
            {isDesktop ? 'Смотреть архивную запись' : 'Архивная запись'}
          </ButtonAnchor>
        )
      ) : (
        <>
          {isOnlineOnly ? (
            <Button
              variant={ButtonVariant.secondary}
              onClick={() => {
                onWebinarRemind(id);
                handleActionClick('Напомнить о событии');
              }}
            >
              {isDesktop
                ? 'Напомнить о событии по почте'
                : 'Напомнить о событии'}
            </Button>
          ) : (
            <>
              <div className={s.WebinarCard__places}>
                Осталось мест на семинар: {availablePlaces}
              </div>
              <Button
                disabled={!availablePlaces}
                onClick={() => {
                  handleLiveSignUp();
                  handleActionClick('Записаться на очный вебинар');
                }}
              >
                {isDesktop
                  ? 'Записаться на очный семинар'
                  : 'Записаться на семинар'}
              </Button>
            </>
          )}
          {link && (
            <ButtonAnchor
              href={link}
              {...externalLinkHtmlProps}
              variant={ButtonVariant.secondary}
              onClick={() => handleActionClick('Посмотреть трансляцию')}
            >
              {isDesktop ? 'Посмотреть трансляцию в VK' : 'Трансляция в VK'}
            </ButtonAnchor>
          )}
        </>
      )}
    </div>
  );

  return (
    <div
      ref={ref}
      className={clsx(s.WebinarCard, className, {
        [s.WebinarCard_disabled]: disabled,
        [s.WebinarCard_selected]: selected && !isOnlineOnly,
        [s.WebinarCard_selected_onlineOnly]: selected && isOnlineOnly
      })}
    >
      <div className={s.WebinarCard__content}>
        <div className={s.WebinarCard__info}>
          <div className={s.WebinarCard__head}>
            <DateLabel
              date={date_start}
              variant={
                isOnlineOnly ? DateLabelVariant.Orange : DateLabelVariant.Green
              }
              disabled={disabled}
            />
            <div
              className={clsx(s.WebinarCard__type, {
                [s.WebinarCard__type_cancelled]: is_canceled
              })}
            >
              {typeStr}
            </div>
          </div>
          <p
            className={clsx(s.WebinarCard__name, {
              [s.WebinarCard__name_cancelled]: is_canceled
            })}
          >
            {name}
          </p>
          {!is_canceled && (
            <div className={s.WebinarCard__more}>
              <Button
                className={s.WebinarCard__moreButton}
                classes={{
                  iconRight: clsx(s.WebinarCard__moreButtonIcon, {
                    [s.WebinarCard__moreButtonIcon_active]: showMore
                  })
                }}
                variant={ButtonVariant.ghost}
                iconRight={<IconChevron />}
                onClick={handleShowMore}
              >
                Подробнее
              </Button>
            </div>
          )}
        </div>
        {isDesktop && actionsComp}
      </div>

      {showMore && (
        <div className={s.Ext}>
          <div className={s.Ext__column}>
            <ExtItem
              title={'О чем узнаете на вебинаре:'}
              mdString={description}
            />
          </div>
          <div className={s.Ext__column}>
            <ExtItem title={'Спикер'}>{speaker}</ExtItem>
            <ExtItem title={'Площадки'}>{place}</ExtItem>
          </div>
        </div>
      )}

      {!isDesktop && actionsComp}
    </div>
  );
};
