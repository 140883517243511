import * as yup from 'yup';
import {
  CountryCode,
  isValidNumber,
  isValidNumberForRegion
} from 'libphonenumber-js';
import { trimString } from '../utils/string/trimString';
import { StoryUtils } from './story/StoryUtils';
import { WebinarUtils } from './webinar/WebinarUtils';

export const REQUIRED_MESSAGE = 'Заполните поле';
export const PHONE_MESSAGE = 'Некорректный телефонный номер';
export const EMAIL_MESSAGE = 'Некорректный адрес email';
export const DATE_MESSAGE = 'Некорректная дата';
export const WRONG_SYMBOLS_MESSAGE =
  'Допустимые символы: заглавные и строчные буквы русского алфавита, заглавные буквы I и V латинского алфавита, пробел, точка, запятая, дефис, апостроф, круглые скобки';
export const MIN_LENGTH_MESSAGE_FUNC = (data) =>
  `Минимум ${data.min} символов.`;
export const MAX_LENGTH_MESSAGE_FUNC = (data) =>
  `Максимум ${data.max} символов.`;

const dateRegexp = new RegExp(/\d{2}\.\d{2}\.\d{4}/, 'g');

const namePartRegexp = new RegExp(
  /^[А-ЯËа-яё][А-ЯËа-яё.,'\-\sIV()]*[а-яёIV)]*$/
);

const punctSymbols = ['.', ',', '-', "'"];
const brackets = ['(', ')'];
const restrictedStartSymbols = ['I', 'V', ...punctSymbols, ...brackets];
const restrictedEndSymbols = [...punctSymbols, brackets[0]];
const restrictedSiblingSymbols = [...punctSymbols, ...brackets];

function hasSiblingSymbols(value: string, symbols: string[]): boolean {
  const indexes: number[] = [];
  let hasSiblings = false;

  for (let i = 0; i < value.length; i++) {
    if (symbols.find((s) => s === value[i])) {
      const prevSymbolIndex = indexes[indexes.length - 1];
      if (prevSymbolIndex !== undefined && prevSymbolIndex === i - 1) {
        hasSiblings = true;
        break;
      }
      indexes.push(i);
    }
  }

  return hasSiblings;
}

function hasPairedBrackets(value: string): boolean {
  const bracketsArr: string[] = [];

  for (let char of value) {
    const lastBracket = bracketsArr[bracketsArr.length - 1];
    if (char === brackets[1] && lastBracket === brackets[0]) {
      bracketsArr.pop();
    } else if (brackets.includes(char)) {
      bracketsArr.push(char);
    }
  }

  return bracketsArr.length === 0;
}

function validateName(value, context) {
  const { path, createError } = context;

  value = trimString(value);

  if (!value) {
    return createError({ path, message: REQUIRED_MESSAGE });
  }

  if (restrictedStartSymbols.some((s) => value === s)) {
    return createError({
      path,
      message: `Значение не может состоять из единственного символа: ${restrictedStartSymbols
        .map((s) => `"${s}"`)
        .join(', ')}`
    });
  }

  if (restrictedStartSymbols.includes(value[0])) {
    return createError({
      path,
      message: `Значение не может начинаться с символов: ${restrictedStartSymbols
        .map((s) => `"${s}"`)
        .join(', ')}`
    });
  }

  if (restrictedEndSymbols.includes(value[value.length - 1])) {
    return createError({
      path,
      message: `Значение не может оканчиваться символами: ${restrictedStartSymbols
        .map((s) => `"${s}"`)
        .join(', ')}`
    });
  }

  if (hasSiblingSymbols(value, restrictedSiblingSymbols)) {
    return createError({
      path,
      message: `Значение не может содержать подряд символы: ${restrictedSiblingSymbols
        .map((s) => `"${s}"`)
        .join(', ')}`
    });
  }

  if (brackets.some((s) => value.includes(s) && !hasPairedBrackets(value))) {
    return createError({
      path,
      message:
        'Значение должно содержать парные скобки в верном порядке: "(" и ")"'
    });
  }

  return (
    namePartRegexp.test(value) ||
    createError({ path, message: WRONG_SYMBOLS_MESSAGE })
  );
}

function validatePhone(countryCode: CountryCode, required: boolean = true) {
  return (value, context) => {
    const { path, createError } = context;
    if (!value) return !required;
    const isValidNum = countryCode
      ? isValidNumberForRegion(value, countryCode)
      : isValidNumber(value);
    return isValidNum || createError({ path, message: PHONE_MESSAGE });
  };
}

export const webinarSubscriptionValidationSchema = yup.object().shape({
  name: yup
    .string()
    .max(WebinarUtils.nameMaxLength, MAX_LENGTH_MESSAGE_FUNC)
    .test(validateName),
  surname: yup
    .string()
    .max(WebinarUtils.nameMaxLength, MAX_LENGTH_MESSAGE_FUNC)
    .test(validateName),
  patronymic: yup.string().when('noPatronymic', {
    is: false,
    then: (schema) =>
      schema
        .max(WebinarUtils.nameMaxLength, MAX_LENGTH_MESSAGE_FUNC)
        .test(validateName)
  }),
  email: yup.string().email(EMAIL_MESSAGE).required(REQUIRED_MESSAGE),
  phone: yup.string().test(validatePhone('RU', false)),
  terms: yup.boolean().oneOf([true], REQUIRED_MESSAGE)
});

export const webinarRemindValidationSchema = yup.object().shape({
  name: yup
    .string()
    .max(WebinarUtils.nameMaxLength, MAX_LENGTH_MESSAGE_FUNC)
    .test(validateName),
  surname: yup
    .string()
    .max(WebinarUtils.nameMaxLength, MAX_LENGTH_MESSAGE_FUNC)
    .test(validateName),
  patronymic: yup.string().when('noPatronymic', {
    is: false,
    then: (schema) =>
      schema
        .max(WebinarUtils.nameMaxLength, MAX_LENGTH_MESSAGE_FUNC)
        .test(validateName)
  }),
  email: yup.string().email(EMAIL_MESSAGE).required(REQUIRED_MESSAGE),
  terms: yup.boolean().oneOf([true], REQUIRED_MESSAGE)
});

export const webinarLiveSignUpValidationSchema = yup.object().shape({
  name: yup
    .string()
    .max(WebinarUtils.nameMaxLength, MAX_LENGTH_MESSAGE_FUNC)
    .test(validateName),
  surname: yup
    .string()
    .max(WebinarUtils.nameMaxLength, MAX_LENGTH_MESSAGE_FUNC)
    .test(validateName),
  patronymic: yup.string().when('noPatronymic', {
    is: false,
    then: (schema) =>
      schema
        .max(WebinarUtils.nameMaxLength, MAX_LENGTH_MESSAGE_FUNC)
        .test(validateName)
  }),
  birthdate: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(dateRegexp, DATE_MESSAGE),
  email: yup.string().email(EMAIL_MESSAGE).required(REQUIRED_MESSAGE),
  phone: yup.string().required(REQUIRED_MESSAGE).test(validatePhone('RU')),
  question: yup
    .string()
    .max(WebinarUtils.signUpQuestionMaxLength, MAX_LENGTH_MESSAGE_FUNC),
  terms: yup.boolean().oneOf([true], REQUIRED_MESSAGE)
});

export const shareStoryValidationSchema = yup.object().shape({
  name: yup
    .string()
    .max(StoryUtils.nameMaxLength, MAX_LENGTH_MESSAGE_FUNC)
    .test(validateName),
  surname: yup
    .string()
    .max(StoryUtils.nameMaxLength, MAX_LENGTH_MESSAGE_FUNC)
    .test(validateName),
  patronymic: yup.string().when('noPatronymic', {
    is: false,
    then: (schema) =>
      schema
        .max(StoryUtils.nameMaxLength, MAX_LENGTH_MESSAGE_FUNC)
        .test(validateName)
  }),
  email: yup
    .string()
    .max(StoryUtils.emailMaxLength, MAX_LENGTH_MESSAGE_FUNC)
    .email(EMAIL_MESSAGE)
    .required(REQUIRED_MESSAGE),
  phone: yup
    .string()
    .max(StoryUtils.phoneMaxLength, MAX_LENGTH_MESSAGE_FUNC)
    .test(validatePhone('RU', false)),
  message: yup
    .string()
    .min(StoryUtils.messageMinLength, MIN_LENGTH_MESSAGE_FUNC)
    .max(StoryUtils.messageMaxLength, MAX_LENGTH_MESSAGE_FUNC)
    .required(REQUIRED_MESSAGE),
  terms: yup.boolean().oneOf([true], REQUIRED_MESSAGE)
});

export const testQuestionValidationSchema = yup.object().shape({
  answer: yup.string().required('Выберите вариант ответа')
});

export const gameQuestionValidationSchema = yup.object().shape({
  answer: yup.string().required('Выберите вариант ответа')
});
