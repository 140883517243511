import React from 'react';
import clsx from 'clsx';
import { formatDate } from '../../../hooks/useFormatDate';
import s from './DateLabel.module.scss';

export enum DateLabelVariant {
  Green = 'green',
  Orange = 'orange'
}

export interface DateLabelProps {
  className?: string;
  variant?: DateLabelVariant;
  date: string | Date;
  formatString?: string;
  disabled?: boolean;
}

export const DateLabel = ({
  className,
  variant = DateLabelVariant.Green,
  date,
  formatString = 'dd MMM, HH:mm',
  disabled
}: DateLabelProps) => {
  return (
    <div
      className={clsx(s.DateLabel, s[`DateLabel_${variant}`], className, {
        [s.DateLabel_disabled]: disabled
      })}
    >
      {formatDate(date, formatString)}
    </div>
  );
};
