import React from 'react';
import { ErrorMessage } from '../components/ui/ErrorMessage/ErrorMessage';

export interface ErrorComponentProps {
  title: string;
  description: string;
}

export interface ErrorBoundaryProps {
  children: React.ReactNode;
  component: React.ComponentType<ErrorComponentProps>;
  renderError?: (props: ErrorComponentProps) => React.ReactNode;
}

export interface ErrorBoundaryState {
  error: any;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    error: null
  };

  static defaultProps = {
    component: ErrorMessage
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.children !== prevProps.children) {
      this.setState({ error: null });
    }
  }

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  render() {
    const { error } = this.state;
    const { children, component: ErrorComponent, renderError } = this.props;

    if (error) {
      const props = {
        title: 'Error occurred',
        description: error.toString()
      };

      if (ErrorComponent) {
        return <ErrorComponent {...props} />;
      } else if (renderError) {
        return renderError(props);
      } else {
        return false;
      }
    }

    return children;
  }
}
