import React from 'react';
import clsx from 'clsx';
import { DocType } from '../../../../api/types/DocumentType';
import { createFileUrl } from '../../../../api/hooks/useFileUrl';
import { createImageUrl } from '../../../../api/hooks/useImageUrl';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { DATE_FORMAT_SHORT_FULL_YEAR } from '../../../../utils/date/date';
import { BaseLink } from '../BaseLink/BaseLink';
import docPicSrc from '../../../../assets/img/content/materials/docPic.jpg';
import { ReactComponent as IconEye } from '../../../../assets/img/icons/i-eye.svg';
import { Icon } from '../Icon/Icon';
import { useWindowSize } from '../../../hooks/useWindowSize';
import s from './Document.module.scss';

export interface DocumentProps extends Omit<DocType, 'id'> {
  className?: string;
  link?: string;
  onClick?: () => void;
}

export const Document = ({
  className,
  name,
  link,
  image,
  file,
  updatedAt,
  onClick
}: DocumentProps) => {
  const { isDesktop } = useWindowSize();
  const fileUrl = createFileUrl(file);
  const imageSrc = createImageUrl(image);
  const dateStr = useFormatDate(updatedAt, DATE_FORMAT_SHORT_FULL_YEAR);

  const content = (
    <>
      <span className={s.Document__date}>{dateStr}</span>
      <p className={s.Document__name}>{name}</p>
    </>
  );

  const linkProps = link
    ? {
        to: link
      }
    : fileUrl
    ? {
        href: fileUrl
      }
    : {};

  return (
    <BaseLink
      className={clsx(s.Document, className)}
      onClick={onClick}
      {...linkProps}
    >
      <div
        className={s.Document__pic}
        style={{ backgroundImage: `url(${imageSrc || docPicSrc})` }}
      >
        <Icon
          className={s.Document__picOverlay}
          icon={<IconEye />}
          boxSize={'auto'}
          iconSize={24}
        />
        <div className={s.Document__picCorner} />
      </div>
      {isDesktop ? (
        <div className={s.Document__content}>{content}</div>
      ) : (
        content
      )}
    </BaseLink>
  );
};
