import React from 'react';
import clsx from 'clsx';
import s from './CallCenterSide.module.scss';

export interface CallCenterSideProps {
  className?: string;
  children?: React.ReactNode;
}

export const CallCenterSide = ({
  className,
  children
}: CallCenterSideProps) => {
  return <div className={clsx(s.CallCenterSide, className)}>{children}</div>;
};
