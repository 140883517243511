import { ImageType, PopulatedType } from './strapi.types';

export enum StoryDialogMessageAuthorEnum {
  SCAMMER = 'SCAMMER',
  VICTIM = 'VICTIM',
  AUTHOR = 'AUTHOR'
}

export type StoryDialogMessageType = {
  id: number;
  text: string;
  time?: string | null;
  messageAuthor: StoryDialogMessageAuthorEnum;
};

export type StoryType = {
  id: number;
  title: string;
  title_short: string;
  description: string;
  image: PopulatedType<ImageType>;
  avatar: PopulatedType<ImageType>;
  recommendations?: string | null;
  dialog: StoryDialogMessageType[];
};
