import { useEffect } from 'react';
import { QueryResultType } from '../../../api/types/QueryResultType';
import { useApi } from './useApi';

export function useQueryLoading(query: QueryResultType<any>) {
  const { setLoading } = useApi();

  const queryName = query.store.query.url;
  const queryLoading = query.check.spinner;

  useEffect(() => {
    if (queryName) {
      setLoading(queryName, queryLoading);
    }
  }, [queryName, queryLoading, setLoading]);

  return queryLoading;
}
