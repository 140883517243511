import React from 'react';

export interface ErrorMessageProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  title,
  description,
  children
}) => {
  return (
    <>
      {title}
      <br />
      {description}
      <br />
      {children}
    </>
  );
};
