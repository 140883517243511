import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { intervalToDuration } from 'date-fns';
import { declineUnit } from '@proscom/ui-utils';
import { useInterval } from 'react-use';
import { tryParseIso } from '@proscom/ui-utils-date';
import isBefore from 'date-fns/esm/isBefore';
import clsx from 'clsx';
import { addZero } from '../../../../../utils/date/date';
import s from './Timer.module.scss';

enum Units {
  month = 'month',
  day = 'day',
  hour = 'hour',
  minute = 'minute'
}

const declinedUnits = {
  [Units.month]: (value) =>
    declineUnit(value || 0, ['Месяц', 'Месяца', 'Месяцев']),
  [Units.day]: (value) => declineUnit(value || 0, ['День', 'Дня', 'Дней']),
  [Units.hour]: (value) => declineUnit(value || 0, ['Час', 'Часа', 'Часов']),
  [Units.minute]: (value) =>
    declineUnit(value || 0, ['Минута', 'Минуты', 'Минут'])
};

type TimerNumType = {
  label: string;
  value: number;
};

export interface TimerProps {
  date: string | null;
}

export const Timer: React.FC<TimerProps> = ({ date }) => {
  const [intervalMonths, setIntervalMonths] = useState<TimerNumType>({
    label: declinedUnits[Units.month](0),
    value: 0
  });
  const [intervalDays, setIntervalDays] = useState<TimerNumType>({
    label: declinedUnits[Units.day](0),
    value: 0
  });
  const [intervalHours, setIntervalHours] = useState<TimerNumType>({
    label: declinedUnits[Units.hour](0),
    value: 0
  });
  const [intervalMinutes, setIntervalMinutes] = useState<TimerNumType>({
    label: declinedUnits[Units.minute](0),
    value: 0
  });
  const [isOutDate, setOutDate] = useState(false);

  const dateEnd = useMemo(() => {
    return tryParseIso(date || '');
  }, [date]);

  const updateNumbers = useCallback(() => {
    if (dateEnd) {
      if (isBefore(new Date(), dateEnd)) {
        const interval = intervalToDuration({
          start: new Date(),
          end: dateEnd
        });
        setIntervalMonths({
          label: declinedUnits[Units.month](interval.months || 0),
          value: interval.months || 0
        });
        setIntervalDays({
          label: declinedUnits[Units.day](interval.days || 0),
          value: interval.days || 0
        });
        setIntervalHours({
          label: declinedUnits[Units.hour](interval.hours || 0),
          value: interval.hours || 0
        });
        setIntervalMinutes({
          label: declinedUnits[Units.minute](interval.minutes || 0),
          value: interval.minutes || 0
        });
      } else {
        setOutDate(true);
      }
    }
  }, [dateEnd]);

  useInterval(
    () => {
      updateNumbers();
    },
    !isOutDate ? 1000 : null
  );

  useEffect(() => {
    updateNumbers();
  }, [updateNumbers]);

  const intervalArray = useMemo(() => {
    const monthsExist = intervalMonths.value > 0;
    return [intervalMonths, intervalDays, intervalHours, intervalMinutes].slice(
      monthsExist ? 0 : 1
    );
  }, [intervalMonths, intervalDays, intervalHours, intervalMinutes]);

  return (
    <div
      className={clsx(s.Timer, {
        [s.Timer_outDate]: isOutDate
      })}
    >
      {intervalArray.map((item, iItem) => {
        const valueStr = addZero(item.value);

        return (
          <div key={iItem} className={s.Timer__group}>
            <div className={s.Timer__numbers}>
              {valueStr.split('').map((num, iNum) => (
                <div key={iNum} className={s.Timer__number}>
                  {num}
                </div>
              ))}
            </div>
            <div className={s.Timer__label}>{item.label}</div>
          </div>
        );
      })}
    </div>
  );
};
