import React from 'react';
import { Modal, ModalProps } from '../Modal';
import { ScammersSayType } from '../../../../../api/types/ScammersSayType';
import { ScammersSayMessage } from '../../ScammersSayMessage/ScammersSayMessage';
import { Button, ButtonSize } from '../../Button';
import s from './ModalScammersSay.module.scss';

export interface ModalScammersSayProps
  extends Pick<ModalProps, 'isOpen' | 'onClose' | 'onClosed'> {
  messages: ScammersSayType[];
}

export const ModalScammersSay: React.FC<ModalScammersSayProps> = ({
  messages,
  ...props
}) => {
  return (
    <Modal
      className={s.ModalScammersSay}
      classes={{
        title: s.ModalScammersSay__title
      }}
      title={'Что обычно говорят мошенники?'}
      {...props}
    >
      {({ close }) => (
        <>
          <div className={s.List}>
            {messages.map((msg) => (
              <ScammersSayMessage key={msg.id} {...msg} />
            ))}
          </div>

          <div className={s.Actions}>
            <Button
              className={s.ButtonClose}
              size={ButtonSize.xlarge}
              onClick={close}
            >
              Спасибо, всё понятно
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};
