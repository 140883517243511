import React from 'react';
import clsx from 'clsx';
import { LayoutContent } from '../../../layout/LayoutContent/LayoutContent';
import { EventBannerType } from '../../../../../api/types/BannerType';
import { LayoutCard } from '../../../layout/LayoutCard/LayoutCard';
import { createImageUrl } from '../../../../../api/hooks/useImageUrl';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import circlesTopDesktopSrc from './assets/circles-top-desktop.svg';
import circlesBottomDesktopSrc from './assets/circles-bottom-desktop.svg';
import circlesTopMobileSrc from './assets/circles-top-mobile.svg';
import circlesBottomMobileSrc from './assets/circles-bottom-mobile.svg';
import s from './BannerEvent.module.scss';

export interface BannerEventProps extends EventBannerType {
  className?: string;
}

export const BannerEvent = ({
  className,
  title,
  hint,
  image,
  background_color
}: BannerEventProps) => {
  const { isDesktop } = useWindowSize();
  const imageSrc = createImageUrl(image);

  return (
    <LayoutContent
      className={clsx(s.BannerEvent, className, {
        [s.BannerEvent_noImage]: !imageSrc
      })}
    >
      <LayoutCard
        className={s.BannerEvent__card}
        style={{ backgroundColor: background_color }}
      >
        <div className={s.BannerEvent__grid}>
          <div className={s.BannerEvent__content}>
            <h2 className={s.BannerEvent__title}>{title}</h2>
            {hint && <div className={s.BannerEvent__hint}>{hint}</div>}
          </div>
          {imageSrc && (
            <div className={s.BannerEvent__pic}>
              <img
                src={isDesktop ? circlesTopDesktopSrc : circlesTopMobileSrc}
                alt=""
                className={s.BannerEvent__circles_top}
              />
              <img
                src={
                  isDesktop ? circlesBottomDesktopSrc : circlesBottomMobileSrc
                }
                alt=""
                className={s.BannerEvent__circles_bottom}
              />

              <img className={s.BannerEvent__image} src={imageSrc} alt="" />
            </div>
          )}
        </div>
      </LayoutCard>
    </LayoutContent>
  );
};
