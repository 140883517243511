import React, { useCallback } from 'react';
import { Modal, ModalProps } from '../Modal';
import { Button, ButtonSize } from '../../Button';
import { ResultAlert } from '../../ResultAlert/ResultAlert';
import s from './ModalUnsub.module.scss';

export interface ModalUnsubProps
  extends Pick<ModalProps, 'title' | 'isOpen' | 'onClose' | 'onClosed'> {
  description?: React.ReactNode;
}

export const ModalUnsub: React.FC<ModalUnsubProps> = ({
  title,
  description,
  onClosed,
  ...props
}) => {
  const handleClosed = useCallback(() => {
    onClosed?.();
    window.history.replaceState({}, document.title, window.location.pathname);
  }, [onClosed]);

  return (
    <Modal
      className={s.ModalUnsub}
      title={title || 'Отписка от рассылки'}
      onClosed={handleClosed}
      {...props}
    >
      {({ close }) => (
        <>
          <ResultAlert className={s.SuccessAlert}>
            {description || 'Вы успешно отписались от рассылки'}
          </ResultAlert>

          <div className={s.ModalUnsub__actions}>
            <Button
              className={s.ModalUnsub__submitBtn}
              size={ButtonSize.xlarge}
              onClick={close}
            >
              Спасибо, всё понятно
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};
