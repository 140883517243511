import React, { useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import { useContextAxiosClient } from '@proscom/prostore-axios-react';
import { useAsyncOperation } from '@proscom/prostore-react';
import { isNil } from 'lodash-es';
import { Modal, ModalProps } from '../Modal';
import { Button, ButtonSize, ButtonVariant } from '../../Button';
import { FormTextField } from '../../form/FormTextField/FormTextField';
import { TextFieldSize } from '../../TextField/TextField';
import { webinarRemindValidationSchema } from '../../../../../store/validationSchemas';
import { FormCheckbox } from '../../form/FormCheckbox/FormCheckbox';
import { TextLink } from '../../TextLink/TextLink';
import {
  WebinarsSubscriptionFormValues,
  WebinarUtils
} from '../../../../../store/webinar/WebinarUtils';
import { ResultAlert } from '../../ResultAlert/ResultAlert';
import { agreementLinks, sectionLabelsById } from '../../../../../constants';
import { parseApiError } from '../../../../../api/utils/errors';
import { useMetricsContext } from '../../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../../store/metrics/types';
import s from './ModalWebinarRemind.module.scss';

export interface ModalWebinarRemindProps
  extends Pick<ModalProps, 'isOpen' | 'onClose' | 'onClosed'> {
  webinarId?: number;
}

const initialValues: WebinarsSubscriptionFormValues = {
  name: '',
  surname: '',
  patronymic: '',
  email: '',
  noPatronymic: false,
  terms: false
};

export const ModalWebinarRemind: React.FC<ModalWebinarRemindProps> = ({
  webinarId,
  onClose,
  onClosed,
  ...props
}) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const { params } = useMetricsContext();

  const handleActionClick = useCallback(
    (label: string) => {
      if (label) {
        params({
          [sectionLabelsById.webinars]: {
            Модалка: {
              [label]: MetricsParams.Click
            }
          }
        });
      }
    },
    [params]
  );

  const client = useContextAxiosClient();
  const webinarRemindMutation = useAsyncOperation((data) => {
    return client.post('/webinar-reminds', data);
  });

  const { run, loading } = webinarRemindMutation;

  const handleSubscribe = useCallback(
    async (data) => {
      if (isNil(webinarId)) return;

      try {
        const result = await run({
          data: WebinarUtils.remindFromForm(data, webinarId)
        });
        if (result.status !== 200) {
          throw new Error(`Error: ${result.status} - webinar remind`);
        }
        setSuccess(true);
        setError('');
        handleActionClick('Отправить');
        // reachGoal(MetricsGoals.OnlineWebinar);
      } catch (err) {
        setError(
          parseApiError(err, 'Ошибка подписки на событие. Попробуйте еще раз.')
        );
        console.error(err);
      }
    },
    [run, webinarId, handleActionClick]
  );

  const handleClose = useCallback(() => {
    onClose?.();
    if (!success) {
      handleActionClick('Отмена');
    }
  }, [success, handleActionClick, onClose]);

  const handleClosed = useCallback(() => {
    onClosed?.();
    setError('');
    setSuccess(false);
  }, [onClosed]);

  return (
    <Modal
      className={s.ModalWebinarRemind}
      title={'Напоминание о событии'}
      onClose={handleClose}
      onClosed={handleClosed}
      {...props}
    >
      {({ close }) => (
        <>
          {success ? (
            <ResultAlert className={s.SuccessAlert}>
              Мы направим вам письмо с&nbsp;напоминанием, когда событие станет
              доступно
            </ResultAlert>
          ) : (
            <div className={s.ModalWebinarRemind__hint}>
              Оставьте адрес своей электронной почты и&nbsp;мы пришлём вам
              письмо с&nbsp;напоминанием о&nbsp;событии
            </div>
          )}

          <div className={s.ModalWebinarRemind__content}>
            {success ? (
              <Button
                className={s.Success__submitBtn}
                size={ButtonSize.xlarge}
                onClick={close}
              >
                Хорошо
              </Button>
            ) : (
              <Formik
                initialValues={initialValues}
                validationSchema={webinarRemindValidationSchema}
                validateOnChange={false}
                onSubmit={handleSubscribe}
              >
                {({ values }) => (
                  <Form className={s.Form}>
                    <div className={s.Form__line}>
                      <FormTextField
                        name={'surname'}
                        label={'Фамилия *'}
                        placeholder={'Введите фамилию'}
                        size={TextFieldSize.xlarge}
                        disabled={loading}
                      />
                    </div>
                    <div className={s.Form__line}>
                      <FormTextField
                        name={'name'}
                        label={'Имя *'}
                        placeholder={'Введите имя'}
                        size={TextFieldSize.xlarge}
                        disabled={loading}
                      />
                    </div>
                    <div className={s.Form__line}>
                      <FormTextField
                        name={'patronymic'}
                        label={'Отчество *'}
                        placeholder={'Введите отчество'}
                        size={TextFieldSize.xlarge}
                        disabled={loading || values.noPatronymic}
                      />
                      <FormCheckbox
                        classes={{
                          root: s.Form__NoPatronymicCheckbox,
                          label: s.Form__NoPatronymicCheckboxLabel
                        }}
                        name={'noPatronymic'}
                        label={'У меня нет отчества'}
                      />
                    </div>
                    <div className={s.Form__line}>
                      <FormTextField
                        name={'email'}
                        label={'E-mail *'}
                        placeholder={'Введите E-mail'}
                        size={TextFieldSize.xlarge}
                        disabled={loading}
                      />
                    </div>
                    <div className={s.Form__line}>
                      <div className={s.Form__termsCheckbox}>
                        <FormCheckbox
                          className={s.Form__termsCheckboxInput}
                          name={'terms'}
                        />
                        <div className={s.Form__termsCheckboxText}>
                          Нажимая на &laquo;Отправить&raquo;, я соглашаюсь на{' '}
                          <TextLink
                            className={s.Form__termsCheckboxLink}
                            href={agreementLinks.personalDataTerms.href}
                          >
                            обработку персональных данных
                          </TextLink>{' '}
                          и с{' '}
                          <TextLink
                            className={s.Form__termsCheckboxLink}
                            href={agreementLinks.userTerms.href}
                          >
                            правилами пользования Платформой
                          </TextLink>
                        </div>
                      </div>
                    </div>

                    {error && <div className={s.Form__error}>{error}</div>}

                    <div className={s.Form__actions}>
                      <Button
                        className={s.Form__submitBtn}
                        size={ButtonSize.xlarge}
                        type={'submit'}
                        disabled={loading}
                      >
                        Отправить
                      </Button>
                      <Button
                        className={s.Form__cancelBtn}
                        size={ButtonSize.xlarge}
                        variant={ButtonVariant.ghost}
                        disabled={loading}
                        onClick={close}
                      >
                        Отмена
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};
