import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars-2';
import {
  Button,
  ButtonVariant
} from '../../../../../common/components/ui/Button';
import { MDtoHTMLContainer } from '../../../../../common/components/ui/MDtoHTMLContainer/MDtoHTMLContainer';
import { useWindowSize } from '../../../../../common/hooks/useWindowSize';
import { useImageUrl } from '../../../../../api/hooks/useImageUrl';
import {
  StoryDialogMessageAuthorEnum,
  StoryDialogMessageType,
  StoryType
} from '../../../../../api/types/StoryType';
import scammerAvaSrc from '../../../../../assets/img/content/stories/scammer_avatar.svg';
import { ReactComponent as IconArrLeft } from '../../../../../assets/img/icons/i-arrow-left.svg';
import { ScrollBox } from '../../../../../common/components/ui/ScrollBox/ScrollBox';
import { ModalProps } from '../../../../../common/components/ui/Modal/Modal';
import s from './ModalStory.module.scss';

interface DialogMessageProps extends StoryDialogMessageType {
  className?: string;
  avatarSrc: string;
}

const DialogMessage = ({
  className,
  avatarSrc,
  time,
  text,
  messageAuthor
}: DialogMessageProps) => {
  const avaSrc = (() => {
    switch (messageAuthor) {
      case StoryDialogMessageAuthorEnum.VICTIM:
        return avatarSrc;
      case StoryDialogMessageAuthorEnum.SCAMMER:
        return scammerAvaSrc;
      case StoryDialogMessageAuthorEnum.AUTHOR:
      default:
        return '';
    }
  })();

  return (
    <div
      className={clsx(
        s.DialogMessage,
        s[`DialogMessage_${messageAuthor.toLowerCase()}`],
        className
      )}
    >
      {avaSrc && (
        <div
          className={s.DialogMessage__avatar}
          style={{ backgroundImage: `url(${avaSrc})` }}
        />
      )}
      <div
        className={clsx(
          s.DialogMessage__content,
          s[`DialogMessage__content_${messageAuthor.toLowerCase()}`],
          {
            [s.DialogMessage__content_person]:
              messageAuthor !== StoryDialogMessageAuthorEnum.AUTHOR
          }
        )}
      >
        {time && <span className={s.DialogMessage__time}>{time}</span>}
        <p className={s.DialogMessage__text}>{text}</p>
      </div>
    </div>
  );
};

export interface StoryProps extends StoryType {
  modalOffsetRef: ModalProps['offsetRef'];
  onGoBack?: () => void;
  onAction?: (label: string) => void;
}

export const Story = ({
  modalOffsetRef,
  image,
  avatar,
  title,
  title_short,
  description,
  dialog,
  recommendations,
  onGoBack,
  onAction
}: StoryProps) => {
  const { isDesktop } = useWindowSize();
  const [recsOpen, setRecsOpen] = useState(false);
  const [recsNode, setRecsNode] = useState<HTMLDivElement | null>(null);
  const scrollRef = useRef<ScrollbarProps['ref']>();

  useEffect(() => {
    if (recsNode) {
      if (isDesktop) {
        const scroll = scrollRef.current as Scrollbars | undefined;
        if (scroll) {
          //@ts-ignore
          (scroll.view as HTMLDivElement).scrollTo({
            top: recsNode.offsetTop,
            behavior: 'smooth'
          });
        }
      } else {
        modalOffsetRef?.current?.scrollTo({
          top: recsNode.offsetTop,
          behavior: 'smooth'
        });
      }
    }
  }, [recsNode, modalOffsetRef, isDesktop]);

  const imgSrc = useImageUrl(image);
  const avatarSrc = useImageUrl(avatar);

  const titleComp = title_short && (
    <div className={s.Story__title}>{title_short}</div>
  );

  const contentComp = (
    <div className={s.Dialog}>
      <div className={s.Dialog__description}>{description}</div>
      {dialog.map((item, iItem) => (
        <DialogMessage key={iItem} {...item} avatarSrc={avatarSrc} />
      ))}

      <div className={s.Dialog__actions}>
        <Button
          variant={ButtonVariant.secondary}
          onClick={() => {
            setRecsOpen(true);
            onAction?.('Посмотреть правильные действия');
          }}
          disabled={recsOpen}
        >
          Посмотреть правильные действия
        </Button>
      </div>

      {recsOpen && (
        <MDtoHTMLContainer
          ref={setRecsNode}
          className={s.Dialog__recommendations}
          mdString={recommendations}
        />
      )}
    </div>
  );

  return (
    <div className={s.Story}>
      {isDesktop && (
        <div className={s.Story__aside}>
          {titleComp}
          <img className={s.Story__img} src={imgSrc} alt={title} />

          <div className={s.Story__actions}>
            <Button
              variant={ButtonVariant.ghost}
              iconLeft={<IconArrLeft />}
              onClick={() => {
                onGoBack?.();
                onAction?.('Вернуться');
              }}
            >
              Вернуться к списку историй
            </Button>
          </div>
        </div>
      )}
      {!isDesktop && titleComp}
      <div className={s.Story__dialog}>
        {isDesktop ? (
          <ScrollBox
            ref={scrollRef}
            classes={{
              root: s.Dialog__scroll,
              content: s.Dialog__scrollContent
            }}
          >
            {contentComp}
          </ScrollBox>
        ) : (
          contentComp
        )}
      </div>
    </div>
  );
};
