import React, { useCallback } from 'react';
import { MetricsContext, MetricsContextType } from './MetricsContext';
import { MetricsUtils } from './MetricsUtils';
import { useMetricsObservable } from './useMetricsObservable';

export interface MetricsProviderProps {
  children?: React.ReactNode;
}

export const MetricsProvider: React.FC<MetricsProviderProps> = ({
  children
}) => {
  const reachGoal: MetricsContextType['reachGoal'] = useCallback((...props) => {
    MetricsUtils.reachGoal(...props);
  }, []);

  const params: MetricsContextType['params'] = useCallback((params) => {
    MetricsUtils.params(params);
  }, []);

  const observeNode = useMetricsObservable(params);

  return (
    <MetricsContext.Provider
      value={{
        reachGoal,
        params,
        observeNode
      }}
    >
      {children}
    </MetricsContext.Provider>
  );
};
