import parsePhoneNumber from 'libphonenumber-js';
import { PhoneNumberType } from '../types/PhoneNumberType';

export type CreatePhoneNumberType = {
  solid: string;
  formatted: string;
};

export function createPhoneNumber(
  phoneNumber: PhoneNumberType
): CreatePhoneNumberType | null {
  if (!phoneNumber) return null;
  if (!phoneNumber.code) {
    return {
      solid: phoneNumber.number.toString(),
      formatted: phoneNumber.number.toString()
    };
  }

  const phNumber = parsePhoneNumber(
    `8${phoneNumber.code}${phoneNumber.number}`,
    'RU'
  );

  return {
    solid: phNumber
      ? `8${phNumber.nationalNumber}`
      : `8${phoneNumber.code}${phoneNumber.number}`,
    formatted: phNumber
      ? phNumber.formatNational()
      : `8${phoneNumber.code}${phoneNumber.number}`
  };
}
