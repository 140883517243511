import React, { HTMLProps } from 'react';
import clsx from 'clsx';
import { BaseLink } from '../BaseLink/BaseLink';
import s from './ButtonIcon.module.scss';

export enum ButtonIconSize {
  small = 'small',
  large = 'large',
  xlarge = 'xlarge'
}

export enum ButtonIconVariant {
  primary = 'primary',
  inverted = 'inverted'
}

export interface ButtonIconClasses {
  root?: string;
  icon?: string;
}

export interface ButtonIconProps
  extends Omit<HTMLProps<HTMLButtonElement>, 'size' | 'type' | 'selected'> {
  className?: string;
  classes?: ButtonIconClasses;
  variant?: ButtonIconVariant;
  size?: ButtonIconSize;
  icon: React.ReactNode;
  type?: 'button' | 'submit';
  to?: string;
  href?: string;
}

export const ButtonIcon: React.FC<ButtonIconProps> = ({
  className,
  classes,
  variant = ButtonIconVariant.primary,
  size = ButtonIconSize.large,
  icon,
  ...props
}) => {
  return (
    <BaseLink
      className={clsx(
        s.ButtonIcon,
        s[`ButtonIcon_${size}`],
        s[`ButtonIcon_${variant}`],
        className,
        classes?.root
      )}
      {...props}
    >
      <div
        className={clsx(
          s.ButtonIcon__icon,
          s[`ButtonIcon__icon_${size}`],
          classes?.icon
        )}
      >
        {icon}
      </div>
    </BaseLink>
  );
};
