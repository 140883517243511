import React, { useMemo } from 'react';
import clsx from 'clsx';
import InputMask from 'react-input-mask';
import { ReactComponent as IconSearch } from '../../../../assets/img/icons/i-search.svg';
import { Icon } from '../Icon/Icon';
import { HelpText, HelpTextType } from '../HelpText/HelpText';
import { defaultMaskChar, defaultMaskFormatChars } from './constants';
import s from './TextField.module.scss';

export interface InputMaskProps {
  mask?: string;
  maskChar?: string;
  alwaysShowMask?: boolean;
  beforeMaskedValueChange?: (...props: any) => void;
}

export enum TextFieldSize {
  medium = 'medium',
  xlarge = 'xlarge'
}

export interface TextFieldClasses {
  root?: string;
  label?: string;
  input?: string;
  helperText?: string;
}

export interface TextFieldProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'size'
  > {
  size?: TextFieldSize;
  label?: string;
  errorMessage?: string;
  hintMessage?: string;
  classes?: TextFieldClasses;
  maskProps?: InputMaskProps;
  inputRef?: React.ForwardedRef<HTMLInputElement>;
  onChange?: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextField: React.FC<TextFieldProps> = ({
  className,
  classes,
  label,
  size = TextFieldSize.medium,
  type,
  errorMessage,
  hintMessage,
  maskProps,
  inputRef,
  onChange,
  ...inputProps
}) => {
  const isSearch = type === 'search';

  const maskRegexp = useMemo(() => {
    const mask = maskProps?.mask;
    if (!mask) return null;

    const maskChar = maskProps.maskChar || defaultMaskChar;
    const maskedSymbols = mask.match(
      new RegExp(`[${defaultMaskFormatChars}]`, 'g')
    );

    return {
      regexp: new RegExp(maskChar, 'g'),
      maskedSymbols: maskedSymbols?.length || 0
    };
  }, [maskProps]);

  const _inputProps = {
    ref: inputRef,
    type: type || 'text',
    className: clsx(
      s.TextField__input,
      s[`TextField__input_${size}`],
      s[`TextField__input_${type}`],
      {
        [s.TextField__input_error]: !!errorMessage,
        [s.TextField__input_search]: isSearch
      },
      classes?.input
    ),
    onChange: (e) => {
      let value = e.target.value;
      if (maskRegexp) {
        const matchedSymbols = value.match(maskRegexp.regexp)?.length || 0;
        value = matchedSymbols === maskRegexp.maskedSymbols ? '' : value;
      }
      onChange?.(value, e);
    },
    ...inputProps
  };

  return (
    <label className={clsx(s.TextField, className, classes?.root)}>
      {label && (
        <span className={clsx(s.TextField__label, classes?.label)}>
          {label}
        </span>
      )}
      <span className={s.TextField__inputWrapper}>
        {isSearch && (
          <Icon className={s.TextField__searchIcon} icon={<IconSearch />} />
        )}

        {maskProps ? (
          <InputMask {..._inputProps} {...maskProps} />
        ) : (
          <input {..._inputProps} />
        )}
      </span>
      <HelpText
        className={classes?.helperText}
        text={errorMessage || hintMessage}
        type={errorMessage ? HelpTextType.error : HelpTextType.hint}
      />
    </label>
  );
};
