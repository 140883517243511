import React from 'react';
import { ScrollbarProps } from 'react-custom-scrollbars-2';
import { WebinarType } from '../../../../../api/types/WebinarType';
import { ScrollBox } from '../../../../../common/components/ui/ScrollBox/ScrollBox';
import { WebinarsContextType } from '../../../../../store/webinar/WebinarsContext';
import { useWindowSize } from '../../../../../common/hooks/useWindowSize';
import { WebinarsListPlaceholder } from '../WebinarsListPlaceholder/WebinarsListPlaceholder';
import { WebinarCard, WebinarCardProps } from './WebinarCard';
import s from './WebinarsList.module.scss';

export interface WebinarsListProps
  extends Pick<WebinarCardProps, 'onRefReady' | 'onScrollToWebinar'> {
  webinars: WebinarType[];
  isPast?: boolean;
  selectedWebinarId?: number;
  onWebinarLiveSignUp: WebinarsContextType['onWebinarLiveSignUp'];
  onWebinarRemind: WebinarsContextType['onWebinarRemind'];
  onWebinarsSubscription: WebinarsContextType['onWebinarsSubscription'];
}

export const WebinarsList = React.forwardRef<
  ScrollbarProps['ref'],
  WebinarsListProps
>(
  (
    {
      webinars,
      isPast,
      selectedWebinarId,
      onRefReady,
      onWebinarLiveSignUp,
      onWebinarRemind,
      onWebinarsSubscription,
      onScrollToWebinar
    },
    scrollRef
  ) => {
    const { isDesktop } = useWindowSize();

    const listComp = webinars.map((w) => (
      <WebinarCard
        key={w.id}
        {...w}
        isPast={isPast}
        selected={selectedWebinarId === w.id}
        onRefReady={onRefReady}
        onWebinarLiveSignUp={onWebinarLiveSignUp}
        onWebinarRemind={onWebinarRemind}
        onScrollToWebinar={onScrollToWebinar}
      />
    ));

    return (
      <div className={s.WebinarsList}>
        {!webinars.length ? (
          <WebinarsListPlaceholder
            onWebinarsSubscription={onWebinarsSubscription}
          />
        ) : (
          <>
            {isDesktop ? (
              <ScrollBox
                ref={scrollRef}
                classes={{
                  content: s.WebinarsList__scrollContent,
                  trackVertical: s.WebinarsList__scrollTrack
                }}
              >
                {listComp}
              </ScrollBox>
            ) : (
              <div className={s.WebinarsList__scrollContent}>{listComp}</div>
            )}
          </>
        )}
      </div>
    );
  }
);
