import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Form, Formik } from 'formik';
import clsx from 'clsx';
import { Modal, ModalProps } from '../Modal';
import { Button, ButtonSize, ButtonVariant } from '../../Button';
import { gameQuestionValidationSchema } from '../../../../../store/validationSchemas';
import { FormRadioGroup } from '../../form/FormRadioGroup/FormRadioGroup';
import { TestResultType } from '../../../../../api/types/TestType';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import {
  GameQuestionType,
  GameResultType
} from '../../../../../api/types/GameType';
import { GameFormValues, GameUtils } from '../../../../../store/game/GameUtils';
import { MDtoHTMLContainer } from '../../MDtoHTMLContainer/MDtoHTMLContainer';
import { createImageUrl } from '../../../../../api/hooks/useImageUrl';
import { SaveClickType } from '../../../../../api/types/SaveClickType';
import { useMetricsContext } from '../../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../../store/metrics/types';
import { gameMetricsLabel } from '../../../../../store/game/GameContext';
import s from './ModalGame.module.scss';

const modalActionsDefaults = {
  question: '',
  questionResult: '',
  gameResult: ''
};

export interface ModalGameProps
  extends Pick<ModalProps, 'isOpen' | 'onClose' | 'onClosed'>,
    Partial<Omit<SaveClickType, 'questions'>> {
  question?: GameQuestionType;
  questionResult: TestResultType | null;
  gameResult: GameResultType | null;
  gameState: GameResultType;
  gameCompleted: boolean;
  onSubmit: (answerId: number) => void;
  onComplete: () => void;
  onSubscribe: () => void;
  onRestart: () => void;
}

export const ModalGame: React.FC<ModalGameProps> = ({
  success_title,
  success_description,
  success_image,
  fail_title,
  fail_description,
  fail_image,
  question,
  questionResult,
  gameResult,
  gameState,
  gameCompleted,
  onSubmit,
  onComplete,
  onSubscribe,
  onRestart,
  onClose,
  ...props
}) => {
  const { params } = useMetricsContext();
  const { isMobile } = useWindowSize();
  const modalActionsRef = useRef(modalActionsDefaults);

  const initialValues: GameFormValues = {
    answer: ''
  };

  const answers = useMemo(() => {
    if (!question?.answers?.length) return [];
    return question.answers.map((answer) => ({
      label: answer.text,
      name: answer.id.toString()
    }));
  }, [question]);

  const questionTitle = question?.title;

  const title = (() => {
    if (gameResult) {
      return gameResult.correctAmount === gameResult.totalAmount
        ? success_title
        : fail_title;
    }
    if (questionResult) {
      return GameUtils.getQuestionResultTitle(questionResult);
    }
    return questionTitle;
  })();

  useEffect(() => {
    if (gameResult) {
      const resultLabel =
        gameResult.correctAmount === gameResult.totalAmount
          ? 'Все правильно'
          : 'Есть ошибки';

      params({
        [gameMetricsLabel]: {
          Итоги: {
            [resultLabel]: {
              [gameResult.correctAmount]: MetricsParams.View
            }
          }
        }
      });
    } else {
      let questionLabel = questionTitle;

      if (questionLabel) {
        if (questionResult) {
          const answerLabel = !!questionResult.answer?.isCorrectAnswer
            ? 'Правильный ответ'
            : 'Неправильный ответ';

          params({
            [gameMetricsLabel]: {
              [questionLabel]: {
                Пройдено: {
                  [answerLabel]: {
                    [gameState.completeAmount]: MetricsParams.View
                  }
                }
              }
            }
          });
        } else {
          params({
            [gameMetricsLabel]: {
              [questionLabel]: MetricsParams.Click
            }
          });
        }
      }
    }
  }, [gameResult, gameState, params, questionResult, questionTitle]);

  useEffect(() => {
    if (!props.isOpen) {
      modalActionsRef.current = { ...modalActionsDefaults };
    }
  }, [props.isOpen]);

  const handleQuestionAction = useCallback(
    (action: string) => {
      if (modalActionsRef.current.question || !questionTitle) return;
      modalActionsRef.current.question = action;
      params({
        [gameMetricsLabel]: {
          [questionTitle]: {
            [action]: MetricsParams.Click
          }
        }
      });
    },
    [params, questionTitle]
  );

  const handleQuestionResultAction = useCallback(
    (action: string) => {
      if (modalActionsRef.current.questionResult || !questionTitle) return;
      modalActionsRef.current.questionResult = action;

      const answerLabel = !!questionResult?.answer?.isCorrectAnswer
        ? 'Правильный ответ'
        : 'Неправильный ответ';

      params({
        [gameMetricsLabel]: {
          [questionTitle]: {
            Пройдено: {
              [answerLabel]: {
                [action]: MetricsParams.Click
              }
            }
          }
        }
      });
    },
    [params, questionTitle, questionResult]
  );

  const handleGameResultAction = useCallback(
    (action: string) => {
      if (modalActionsRef.current.gameResult) return;
      modalActionsRef.current.gameResult = action;

      params({
        [gameMetricsLabel]: {
          Итоги: {
            [action]: MetricsParams.Click
          }
        }
      });
    },
    [params]
  );

  const handleQuestionLinkClick = useCallback(
    (e: MouseEvent) => {
      const linkLabel = (e.target as HTMLElement).innerText;
      if (!questionTitle || !linkLabel) return;

      params({
        [gameMetricsLabel]: {
          [questionTitle]: {
            Пройдено: {
              'Неправильный ответ': {
                [linkLabel]: MetricsParams.Click
              }
            }
          }
        }
      });
    },
    [params, questionTitle]
  );

  const handleFormSubmit = useCallback(
    (data, { resetForm }) => {
      handleQuestionAction('Ответить');
      onSubmit(+data.answer);
      resetForm();
    },
    [onSubmit, handleQuestionAction]
  );

  const questionImgSrc = createImageUrl(question?.image);
  const questionResultImgSrc = createImageUrl(questionResult?.answer?.image);
  const successImgSrc = createImageUrl(success_image);
  const failImgSrc = createImageUrl(fail_image);

  const gameSuccess =
    gameResult && gameResult.correctAmount === gameResult.totalAmount;
  const gameResultImgSrc = gameSuccess ? successImgSrc : failImgSrc;

  const gameResultSubmitButtonText = gameCompleted
    ? 'Перейти к результатам'
    : 'Играть ещё';
  const gameResultSubscribeButtonText = 'Подписаться на рассылку';
  const gameResultResetButtonText = 'Сыграть ещё раз';

  return (
    <Modal
      className={s.ModalGame}
      classes={{
        title: s.ModalGame__title,
        content: s.ModalGame__content
      }}
      title={title}
      onClose={() => {
        if (gameResult) {
          handleGameResultAction('Закрыть');
        }
        if (questionResult) {
          handleQuestionResultAction('Закрыть');
        } else {
          handleQuestionAction('Закрыть');
        }
        onClose?.();
      }}
      {...props}
    >
      {({ close }) =>
        gameResult || questionResult ? (
          <div className={s.ModalGameResult}>
            {gameResult && (
              <>
                <div className={s.ModalGameResult__content}>
                  {gameResultImgSrc && (
                    <div className={s.ModalGameResult__image}>
                      <img src={gameResultImgSrc} alt="" />
                    </div>
                  )}

                  <MDtoHTMLContainer
                    className={s.ModalGameResult__text}
                    mdString={
                      gameSuccess ? success_description : fail_description
                    }
                  />
                </div>

                <div className={s.ModalGameResult__actions}>
                  <Button
                    className={s.ModalGameResult__action}
                    size={isMobile ? ButtonSize.medium : ButtonSize.xlarge}
                    onClick={() => {
                      handleGameResultAction(gameResultSubscribeButtonText);
                      onSubscribe();
                    }}
                  >
                    {gameResultSubscribeButtonText}
                  </Button>
                  <Button
                    className={s.ModalGameResult__action}
                    size={isMobile ? ButtonSize.medium : ButtonSize.xlarge}
                    variant={ButtonVariant.ghost}
                    onClick={() => {
                      handleGameResultAction(gameResultResetButtonText);
                      onRestart();
                    }}
                  >
                    {gameResultResetButtonText}
                  </Button>
                </div>
              </>
            )}
            {questionResult && (
              <>
                <div className={s.ModalGameResult__content}>
                  {questionResultImgSrc && (
                    <div className={s.ModalGameResult__image}>
                      <img src={questionResultImgSrc} alt="" />
                    </div>
                  )}

                  {questionResult.answer?.note && (
                    <MDtoHTMLContainer
                      className={s.ModalGameResult__text}
                      mdString={questionResult.answer.note}
                      onLinkClick={(e) => {
                        handleQuestionLinkClick(e);
                      }}
                    />
                  )}
                </div>

                <div className={s.ModalGameResult__actions}>
                  <Button
                    className={s.ModalGameResult__action}
                    size={isMobile ? ButtonSize.medium : ButtonSize.xlarge}
                    onClick={() => {
                      handleQuestionResultAction(gameResultSubmitButtonText);
                      (gameCompleted ? onComplete : close)?.();
                    }}
                  >
                    {gameResultSubmitButtonText}
                  </Button>
                </div>
              </>
            )}
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={gameQuestionValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {() => (
              <Form className={s.Form}>
                <div className={s.Form__content}>
                  {questionImgSrc && (
                    <div className={clsx(s.Form__line, s.Form__image)}>
                      <img src={questionImgSrc} alt="" />
                    </div>
                  )}

                  <MDtoHTMLContainer
                    className={clsx(s.Form__line, s.Form__question)}
                    mdString={question?.question}
                  />

                  <div className={s.Form__line}>
                    <FormRadioGroup name={'answer'} items={answers} vertical />
                  </div>
                </div>

                <div className={s.Form__actions}>
                  <Button
                    className={s.Form__action}
                    size={isMobile ? ButtonSize.medium : ButtonSize.xlarge}
                    variant={ButtonVariant.secondary}
                    onClick={() => {
                      handleQuestionAction('Назад');
                      close?.();
                    }}
                  >
                    Назад
                  </Button>
                  <Button
                    className={clsx(s.Form__action, s.Form__action_submit)}
                    size={isMobile ? ButtonSize.medium : ButtonSize.xlarge}
                    type={'submit'}
                  >
                    Ответить
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )
      }
    </Modal>
  );
};
