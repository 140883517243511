import React from 'react';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';

export interface ErrorPageContentProps {
  title: string;
  description?: React.ReactNode;
  children?: React.ReactNode;
}

export function ErrorPageContent({
  title,
  description,
  children
}: ErrorPageContentProps) {
  return (
    <ErrorMessage title={title} description={description}>
      {children}
    </ErrorMessage>
  );
}
