import React from 'react';
import { LayoutContent } from '../../../../common/components/layout/LayoutContent/LayoutContent';
import { useCallCenterItems } from '../../../../api/hooks/useCallCenterItems';
import { useQueryLoading } from '../../../../store/api/hooks/useQueryLoading';
import { SECTION_IDS } from '../../../../constants';
import { useMetricsContext } from '../../../../store/metrics/hooks/useMetricsContext';
import { LayoutCard } from '../../../../common/components/layout/LayoutCard/LayoutCard';
import { useScammersSayQuery } from '../../../../api/hooks/useScammersSayQuery';
import { CallCenterSide } from './CallCenterSide/CallCenterSide';
import { CallCenterList } from './CallCenterList/CallCenterList';
import { ScammersSayList } from './ScammersSayList/ScammersSayList';
import s from './SectionCallCenter.module.scss';

export const SectionCallCenter: React.FC = () => {
  const { observeNode } = useMetricsContext();
  const callCenterItemsQuery = useCallCenterItems();
  const callCenterLoading = useQueryLoading(callCenterItemsQuery);
  const callCenterItems = callCenterItemsQuery.state.data;

  const scammersSayQuery = useScammersSayQuery();
  const scammersSayLoading = useQueryLoading(scammersSayQuery);
  const scammersSayMessages = scammersSayQuery.state.data;

  const loading = callCenterLoading || scammersSayLoading;

  if (!scammersSayMessages?.length && !scammersSayMessages?.length) {
    return null;
  }

  return (
    <LayoutContent
      ref={(r) => r && observeNode(SECTION_IDS.call_center, r)}
      className={s.SectionCallCenter}
      narrow
    >
      {loading || (
        <LayoutCard className={s.Card}>
          {!!scammersSayMessages?.length && (
            <CallCenterSide className={s.Side_scammersSay}>
              <h2 className={s.Side__title}>Что обычно говорят мошенники?</h2>
              <ScammersSayList messages={scammersSayMessages} />
            </CallCenterSide>
          )}
          {!!callCenterItems?.length && (
            <CallCenterSide className={s.Side_callCenter}>
              <h2 className={s.Side__title}>
                Куда звонить, если вы стали жертвой мошенников?
              </h2>
              {!!callCenterItems?.length && (
                <CallCenterList list={callCenterItems} />
              )}
            </CallCenterSide>
          )}
        </LayoutCard>
      )}
    </LayoutContent>
  );
};
