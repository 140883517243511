import { GameTargetEnum } from '../../api/types/GameType';
import { TestResultType } from '../../api/types/TestType';

export type GameFormValues = {
  answer: string | null;
};

export class GameUtils {
  static mapGameTargetsToRoomShapes: Record<GameTargetEnum, string> = {
    [GameTargetEnum.Car]: 'car',
    [GameTargetEnum.Person]: 'person',
    [GameTargetEnum.TV]: 'tv',
    [GameTargetEnum.Laptop]: 'laptop',
    [GameTargetEnum.Phone]: 'phone',
    [GameTargetEnum.Letters]: 'letters',
    [GameTargetEnum.Mobile]: 'mobile',
    [GameTargetEnum.Girl]: 'girl',
    [GameTargetEnum.Cat]: 'cat',
    [GameTargetEnum.Suitcase]: 'suitcase'
  };

  static mapRoomShapesToGameTargets = Object.fromEntries(
    Object.entries(GameUtils.mapGameTargetsToRoomShapes).map(
      ([target, shapeId]) => [shapeId, target]
    )
  ) as Record<string, GameTargetEnum>;

  static getShapeNodeId = (idSlug: string, node?: HTMLElement) => {
    if (!node) return null;
    return node?.id?.replace(idSlug, '') || null;
  };

  static getShapeNodeTargetId = (idSlug: string, node?: HTMLElement) => {
    if (!node) return null;
    return (
      GameUtils.mapRoomShapesToGameTargets[node?.id?.replace(idSlug, '')] ||
      null
    );
  };

  static getQuestionResultTitle(result: TestResultType): string {
    if (result.answer?.isCorrectAnswer) {
      return 'Вы правы!';
    }
    return 'Вы ошиблись! :(';
  }
}
