import React, { useCallback, useRef } from 'react';
import { useSet } from 'react-use';
import { ApiContext } from './ApiContext';

export interface ApiProviderProps {
  children?: React.ReactNode;
}

export const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
  const [loadingQueries, { add: addLoadingQuery, remove: removeLoadingQuery }] =
    useSet();
  const loadingQueriesRef = useRef(new Set());
  const hasQueriesEverLoaded = loadingQueriesRef.current.size > 0;

  const setLoadingQuery = useCallback(
    (queryName: string, loading: boolean) => {
      if (loading) {
        addLoadingQuery(queryName);
        loadingQueriesRef.current.add(queryName);
      } else {
        removeLoadingQuery(queryName);
      }
    },
    [addLoadingQuery, removeLoadingQuery]
  );

  return (
    <ApiContext.Provider
      value={{
        loading: loadingQueries.size > 0,
        allLoaded: hasQueriesEverLoaded && !loadingQueries.size,
        setLoading: setLoadingQuery
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
