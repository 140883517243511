import React from 'react';

export type ApiContextType = {
  loading: boolean;
  allLoaded: boolean;
  setLoading: (queryName: string, loading: boolean) => void;
};

export const ApiContext = React.createContext<ApiContextType>({
  loading: false,
  allLoaded: false,
  setLoading: () => {}
});
