import React, { useCallback, useRef, useState } from 'react';
import { useTimeoutRef } from '@proscom/ui-react';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../../../common/components/ui/Button';
import { useWindowSize } from '../../../../../common/hooks/useWindowSize';
import { ScammersSayType } from '../../../../../api/types/ScammersSayType';
import { ScammersSayMessage } from '../../../../../common/components/ui/ScammersSayMessage/ScammersSayMessage';
import { ModalScammersSay } from '../../../../../common/components/ui/Modal/ModalScammersSay/ModalScammersSay';
import { useMetricsContext } from '../../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../../store/metrics/types';
import s from './ScammersSayList.module.scss';

export interface ScammersSayListProps {
  messages: ScammersSayType[];
}

export const ScammersSayList = ({ messages }: ScammersSayListProps) => {
  const { params } = useMetricsContext();
  const { isDesktop } = useWindowSize();

  const randomMessage = () => {
    return messages[Math.floor(Math.random() * messages.length)];
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMessage, selectMessage] = useState<ScammersSayType | null>(
    randomMessage()
  );
  const prevSelectedMessageRef = useRef<ScammersSayType | null>(
    selectedMessage
  );
  const [updateLoading, setUpdateLoading] = useState(false);
  const updateMessageTO = useTimeoutRef();

  const updateMessage = () => {
    let newMessage = randomMessage();

    if (messages.length > 1) {
      while (
        newMessage &&
        selectedMessage &&
        (selectedMessage.id === newMessage.id ||
          (messages.length > 2 &&
            prevSelectedMessageRef.current &&
            prevSelectedMessageRef.current.id === newMessage.id))
      ) {
        newMessage = randomMessage();
      }
    }

    prevSelectedMessageRef.current = selectedMessage;

    setUpdateLoading(true);

    selectMessage(newMessage);

    updateMessageTO.set(() => {
      setUpdateLoading(false);
    }, 1500);
  };

  const handleButtonsClick = useCallback(
    (action: string) => {
      params({
        'что обычно говорят мошенники': {
          [action]: MetricsParams.Click
        }
      });
    },
    [params]
  );

  return (
    <>
      <div className={s.ScammersSayList}>
        <div className={s.MessageBox}>
          {selectedMessage && (
            <ScammersSayMessage
              className={s.ScammersSayMessage}
              {...selectedMessage}
              loading={updateLoading}
            />
          )}
        </div>
        <div className={s.Actions}>
          <Button
            size={!isDesktop ? ButtonSize.medium : ButtonSize.xlarge}
            variant={
              !isDesktop ? ButtonVariant.whiteSecondary : ButtonVariant.primary
            }
            onClick={() => {
              handleButtonsClick('Показать ещё');
              updateMessage();
            }}
            disabled={updateLoading}
          >
            Показать ещё
          </Button>
          <Button
            size={!isDesktop ? ButtonSize.medium : ButtonSize.xlarge}
            variant={
              !isDesktop ? ButtonVariant.whiteGhost : ButtonVariant.secondary
            }
            onClick={() => {
              handleButtonsClick('Смотреть всё');
              setModalOpen(true);
            }}
          >
            Смотреть всё
          </Button>
        </div>
      </div>

      <ModalScammersSay
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        messages={messages}
      />
    </>
  );
};
