import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import s from './WebinarsCalendar.module.scss';

export enum DateIndicatorVariant {
  Green = 'green',
  Orange = 'orange'
}

export interface DateIndicatorProps {
  className?: string;
  date: string;
  variant: DateIndicatorVariant;
  disabled?: boolean;
  onClick?: MouseEventHandler;
}

export const DateIndicator = ({
  className,
  date,
  variant,
  disabled,
  onClick
}: DateIndicatorProps) => {
  const day = new Date(date).getDate();

  return (
    <div
      className={clsx(
        s.DateIndicator,
        s[`DateIndicator_${variant}`],
        className,
        {
          [s.DateIndicator_clickable]: !!onClick,
          [s.DateIndicator_disabled]: disabled
        }
      )}
      onClick={onClick}
    >
      {day}
    </div>
  );
};
