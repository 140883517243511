import React, { useCallback, useEffect, useState } from 'react';
import { useLockBodyScroll } from 'react-use';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { LayoutContent } from '../LayoutContent/LayoutContent';
import logoSrc from '../../../../assets/img/logo.svg';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { Icon } from '../../ui/Icon/Icon';
import { ReactComponent as IconBurger } from '../../../../assets/img/icons/mobile/i-burger.svg';
import { ReactComponent as IconClose } from '../../../../assets/img/icons/mobile/i-close.svg';
import { BaseLink } from '../../ui/BaseLink/BaseLink';
import { ScrollLink } from '../../ui/reactScroll/ScrollLink';
import { ButtonIcon, ButtonIconSize, ButtonIconVariant } from '../../ui/Button';
import { useMetricsContext } from '../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../store/metrics/types';
import s from './Header.module.scss';

export type NavLinkType = {
  link: string;
  label: string;
  scrollLink?: boolean;
};

interface NavProps {
  navLinks: NavLinkType[];
  onLinkClick: (e: React.MouseEvent, label: string) => void;
}

const Nav: React.FC<NavProps> = ({ navLinks, onLinkClick }) => {
  return (
    <div className={s.Nav}>
      {navLinks.map(({ link, label, scrollLink }, iItem) =>
        scrollLink ? (
          <ScrollLink
            key={iItem}
            className={s.Nav__link}
            activeClass={s.Nav__link_active}
            to={link}
            onClick={(e) => onLinkClick(e, label)}
          >
            {label}
          </ScrollLink>
        ) : (
          <Link
            key={iItem}
            className={s.Nav__link}
            to={link}
            onClick={(e) => onLinkClick(e, label)}
          >
            {label}
          </Link>
        )
      )}
    </div>
  );
};

export interface HeaderProps extends Partial<Pick<NavProps, 'navLinks'>> {}

export const Header: React.FC<HeaderProps> = ({ navLinks }) => {
  const { params } = useMetricsContext();
  const { isDesktop } = useWindowSize();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isDesktop) {
      setOpen(false);
    }
  }, [isDesktop]);

  useLockBodyScroll(open);

  const handleLinkClick: NavProps['onLinkClick'] = useCallback(
    (e, label) => {
      if (label) {
        params({
          Меню: {
            'Разделы меню': {
              [label]: MetricsParams.Click
            }
          }
        });
      }
      setOpen(false);
    },
    [params]
  );

  return (
    <LayoutContent className={s.Header} innerClassName={s.Header__inner}>
      <BaseLink
        href={'https://www.mos.ru/'}
        className={s.Header__logo}
        onClick={(e) => handleLinkClick(e, 'mos.ru')}
      >
        <img src={logoSrc} alt="" />
      </BaseLink>

      {!!navLinks?.length && (
        <>
          {!isDesktop && (
            <div
              className={clsx(s.Header__navOverlay, {
                [s.Header__navOverlay_open]: open
              })}
              onClick={() => setOpen(false)}
            />
          )}
          <div
            className={clsx(s.Header__nav, {
              [s.Header__nav_open]: open
            })}
          >
            {!isDesktop && (
              <ButtonIcon
                classes={{
                  root: s.Nav__closeButton,
                  icon: s.Nav__closeButtonIcon
                }}
                icon={<IconClose />}
                size={ButtonIconSize.xlarge}
                variant={ButtonIconVariant.inverted}
                onClick={() => setOpen(false)}
              />
            )}
            <Nav navLinks={navLinks} onLinkClick={handleLinkClick} />
          </div>

          {!isDesktop && (
            <Icon
              className={clsx(s.Header__mobileButton, {
                [s.Header__mobileButton_open]: open
              })}
              iconClassName={s.Header__mobileButtonIcon}
              icon={<IconBurger />}
              boxSize={'auto'}
              onClick={() => setOpen(!open)}
            />
          )}
        </>
      )}
    </LayoutContent>
  );
};
