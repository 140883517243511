import React from 'react';
import clsx from 'clsx';
import avaSrc1 from '../../../../assets/img/content/scammers-say/ava-1.svg';
import avaSrc2 from '../../../../assets/img/content/scammers-say/ava-2.svg';
import { ScammersSayType } from '../../../../api/types/ScammersSayType';
import { MDtoHTMLContainer } from '../MDtoHTMLContainer/MDtoHTMLContainer';
import s from './ScammersSayMessage.module.scss';

const avatars = [avaSrc1, avaSrc2];

const ScammersSayLoader = () => {
  return (
    <div className={s.ScammersSayLoader}>
      <span />
      <span />
      <span />
    </div>
  );
};

export interface ScammersSayMessageProps
  extends Pick<ScammersSayType, 'id' | 'message'> {
  className?: string;
  loading?: boolean;
}

export const ScammersSayMessage = ({
  className,
  id,
  message,
  loading
}: ScammersSayMessageProps) => {
  const avaSrc = avatars[id % avatars.length];

  return (
    <div className={clsx(s.ScammersSayMessage, className)}>
      <div
        className={s.ScammersSayMessage__ava}
        style={{ backgroundImage: `url(${avaSrc})` }}
      />
      <div className={s.ScammersSayMessage__message}>
        {loading ? (
          <ScammersSayLoader />
        ) : (
          <MDtoHTMLContainer mdString={message} />
        )}
      </div>
    </div>
  );
};
