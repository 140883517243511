import React, { useState } from 'react';
import { UIContext } from './UIContext';

export interface UIProviderProps {
  children?: React.ReactNode;
}

export const UIProvider: React.FC<UIProviderProps> = ({ children }) => {
  const [wasAutoScrolled, setWasAutoScrolled] = useState(false);

  return (
    <UIContext.Provider
      value={{
        wasAutoScrolled,
        setWasAutoScrolled: () => setWasAutoScrolled(true)
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
