import React, { useCallback, useState } from 'react';
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars-2';
import clsx from 'clsx';
import s from './ScrollBox.module.scss';

export interface ScrollBoxClasses {
  root?: string;
  content?: string;
  trackHorizontal?: string;
  trackVertical?: string;
  view?: string;
}

export interface ScrollBoxProps extends Omit<ScrollbarProps, 'ref'> {
  className?: string;
  classes?: ScrollBoxClasses;
  isFading?: boolean;
  children?: React.ReactNode;
}

function TrackHorizontal(className, props) {
  return <div className={clsx(s.TrackHorizontal, className)} {...props} />;
}

function TrackVertical(className, props) {
  return <div className={clsx(s.TrackVertical, className)} {...props} />;
}

function ScrollView(className, props) {
  return <div className={clsx(s.ScrollView, className)} {...props} />;
}

export const ScrollBox = React.forwardRef<
  ScrollbarProps['ref'],
  ScrollBoxProps
>((props, ref) => {
  const { className, classes, isFading, children, ...scrollProps } = props;

  const [onTop, setOnTop] = useState(true);
  const [onBottom, setOnBottom] = useState(false);
  const [scrollable, setScrollable] = useState(false);

  const handleScroll = useCallback((values) => {
    setOnTop(values.top === 0);
    setOnBottom(values.top === 1);
    setScrollable(values.scrollHeight > values.clientHeight);
  }, []);

  return (
    <Scrollbars
      ref={ref as ScrollbarProps['ref']}
      className={clsx(s.ScrollBox, className, classes?.root, {
        [s.ScrollBox_fading]: isFading,
        [s.ScrollBox_onTop]: onTop,
        [s.ScrollBox_onBottom]: onBottom,
        [s.ScrollBox_noScroll]: !scrollable
      })}
      // onScroll={handleScroll}
      // onScrollFrame={this.handleScrollFrame}
      // onScrollStart={this.handleScrollStart}
      // onScrollStop={this.handleScrollStop}
      onUpdate={handleScroll}
      renderView={(props) => ScrollView(classes?.view, props)}
      renderTrackHorizontal={(props) =>
        TrackHorizontal(classes?.trackHorizontal, props)
      }
      renderTrackVertical={(props) =>
        TrackVertical(classes?.trackVertical, props)
      }
      // renderThumbHorizontal={this.renderThumbHorizontal}
      // renderThumbVertical={this.renderThumbVertical}
      autoHide={false}
      autoHideTimeout={200}
      autoHideDuration={200}
      autoHeightMax={'100%'}
      hideTracksWhenNotNeeded
      {...scrollProps}
    >
      <div className={clsx(s.ScrollBox__content, classes?.content)}>
        {children}
      </div>
    </Scrollbars>
  );
});
