import React from 'react';
import { useField } from 'formik';
import { TextField, TextFieldProps } from '../../TextField/TextField';

export interface FormTextFieldProps extends TextFieldProps {
  name: string;
  touched?: boolean;
  error?: string;
}

export const FormTextField: React.FC<FormTextFieldProps> = ({
  name,
  touched: touchedProp,
  error: errorProp,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(name);
  const { onChange, ...fieldProps } = field;

  const { error, touched } = meta;
  const currError = error || errorProp;
  const currTouched = touched || touchedProp;

  return (
    <TextField
      onChange={(value, e) => {
        setValue(value);
      }}
      {...props}
      {...fieldProps}
      // Чтобы избегать неверные начальные значения типа null и инпут всегда был контролируемым
      value={field.value || ''}
      errorMessage={currTouched && currError ? currError : undefined}
    />
  );
};
