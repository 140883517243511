import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import { LayoutContent } from '../../../../common/components/layout/LayoutContent/LayoutContent';
import {
  LayoutCard,
  LayoutCardVariant
} from '../../../../common/components/layout/LayoutCard/LayoutCard';
import { useGameContext } from '../../../../store/game/hooks/useGameContext';
import { GameProvider } from '../../../../store/game/GameProvider';
import { GameUtils } from '../../../../store/game/GameUtils';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import {
  GameContextType,
  gameMetricsLabel
} from '../../../../store/game/GameContext';
import { Button, ButtonSize } from '../../../../common/components/ui/Button';
import { useMetricsContext } from '../../../../store/metrics/hooks/useMetricsContext';
import { SECTION_IDS } from '../../../../constants';
import { MetricsParams } from '../../../../store/metrics/types';
import { ReactComponent as GameRoomShapes } from './assets/game-room-shapes.svg';
import { ReactComponent as GameRoomBg } from './assets/game-room-bg.svg';
import s from './SectionGame.module.scss';

const gameRoomShapeIdSlug = 'room-shape-pointer-';
const gameRoomShapeOverlayIdSlug = 'room-bg-answer-overlay-';
const gameRoomShapeTargetIdSlug = 'room-shape-target-';

interface GameRoomProps
  extends Pick<
    GameContextType,
    'availableTargets' | 'answers' | 'selectedTarget' | 'selectTarget'
  > {}

const GameRoom = ({
  availableTargets,
  answers,
  selectedTarget,
  selectTarget
}: GameRoomProps) => {
  const gameRoomShapesRef = useRef<SVGSVGElement | null>(null);
  const gameRoomBgRef = useRef<SVGSVGElement | null>(null);
  const [hoverShapeId, setHoverShapeId] = useState<string | null>(null);

  const selectedShapeId = selectedTarget
    ? GameUtils.mapGameTargetsToRoomShapes[selectedTarget]
    : null;

  const handleShapeClick = useCallback(
    (e: MouseEvent) => {
      const target = GameUtils.getShapeNodeTargetId(
        gameRoomShapeIdSlug,
        e.target as HTMLElement
      );

      if (target) {
        selectTarget(target === selectedTarget ? null : target);
      }
    },
    [selectTarget, selectedTarget]
  );

  const filteredTargets = useMemo(() => {
    return availableTargets.filter((t) => !answers.has(t));
  }, [availableTargets, answers]);

  const handleShapeHover = useCallback((e: MouseEvent) => {
    const shapeId = GameUtils.getShapeNodeId(
      gameRoomShapeIdSlug,
      e.target as HTMLElement
    );

    if (shapeId) {
      setHoverShapeId(shapeId);
    }
  }, []);

  const handleShapeLeave = useCallback((e: MouseEvent) => {
    setHoverShapeId(null);
  }, []);

  useEffect(() => {
    const gameRoomShapesNode = gameRoomShapesRef.current;
    const roomShapeNodes = gameRoomShapesNode?.querySelectorAll(
      `[id^=${gameRoomShapeIdSlug}]`
    );

    if (roomShapeNodes?.length) {
      Array.from(roomShapeNodes).forEach((node) => {
        if (
          !filteredTargets.includes(
            GameUtils.mapRoomShapesToGameTargets[
              node.id.replace(gameRoomShapeIdSlug, '')
            ]
          )
        ) {
          node.classList.add(s.GameRoomShape_disabled);
        } else {
          (node as HTMLElement).addEventListener('mousemove', handleShapeHover);
          (node as HTMLElement).addEventListener(
            'mouseleave',
            handleShapeLeave
          );
          (node as HTMLElement).addEventListener('click', handleShapeClick);
        }
      });
    }

    return () => {
      if (roomShapeNodes?.length) {
        Array.from(roomShapeNodes).forEach((node) => {
          (node as HTMLElement).removeEventListener(
            'mousemove',
            handleShapeHover
          );
          (node as HTMLElement).removeEventListener(
            'mouseleave',
            handleShapeLeave
          );
          (node as HTMLElement).removeEventListener('click', handleShapeClick);
        });
      }
    };
  }, [filteredTargets, handleShapeHover, handleShapeLeave, handleShapeClick]);

  useEffect(() => {
    const roomShapeTargetNodes = gameRoomShapesRef.current?.querySelectorAll(
      `[id^=${gameRoomShapeTargetIdSlug}]`
    );

    if (roomShapeTargetNodes?.length) {
      Array.from(roomShapeTargetNodes).forEach((n) => {
        n.classList.toggle(
          s.GameRoomTarget_active,
          n.id === `${gameRoomShapeTargetIdSlug}${selectedShapeId}` ||
            n.id === `${gameRoomShapeTargetIdSlug}${hoverShapeId}`
        );
      });
    }
  }, [selectedShapeId, hoverShapeId]);

  useEffect(() => {
    const gameRoomBgNode = gameRoomBgRef.current;
    const roomOverlayNodes = gameRoomBgNode?.querySelectorAll(
      `[id^=${gameRoomShapeOverlayIdSlug}]`
    );

    if (roomOverlayNodes?.length) {
      Array.from(roomOverlayNodes).forEach((n) => {
        const nodeTarget =
          GameUtils.mapRoomShapesToGameTargets[
            n.id.replace(gameRoomShapeOverlayIdSlug, '')
          ];
        const answer = answers.get(nodeTarget);
        if (answer) {
          n.classList.add(s.GameRoomOverlay_active);
          n.classList.add(
            s[
              `GameRoomOverlay_${
                answer.isCorrectAnswer ? 'correct' : 'incorrect'
              }`
            ]
          );
        } else {
          n.classList.remove(
            s.GameRoomOverlay_active,
            s.GameRoomOverlay_correct,
            s.GameRoomOverlay_incorrect
          );
        }
      });
    }
  }, [answers]);

  return (
    <>
      <GameRoomBg
        className={clsx(
          s.GameRoomBg,
          (!!selectedShapeId || !!hoverShapeId) && s.GameRoomBg_active,
          (!!selectedShapeId || !!hoverShapeId) &&
            s[`GameRoomBg_active_${selectedShapeId || hoverShapeId}`],
          ...Array.from(answers.keys()).map(
            (t) =>
              s[
                `GameRoomBg_completed_${GameUtils.mapGameTargetsToRoomShapes[t]}`
              ]
          )
        )}
        ref={gameRoomBgRef}
      />
      <GameRoomShapes className={s.GameRoomShapes} ref={gameRoomShapesRef} />
    </>
  );
};

const Game = () => {
  const { observeNode, params } = useMetricsContext();
  const { isDesktop, isMobile } = useWindowSize();
  const {
    availableTargets,
    answers,
    selectedTarget,
    selectTarget,
    restart,
    gameState,
    gameCompleted
  } = useGameContext();

  const handlePlayAgainClick = useCallback(() => {
    const stateLabel =
      gameState.correctAmount === gameState.totalAmount
        ? 'Все правильно'
        : 'Есть ошибки';

    params({
      [gameMetricsLabel]: {
        'Играть еще': {
          [stateLabel]: MetricsParams.Click
        }
      }
    });
  }, [params, gameState]);

  if (!availableTargets.length) return null;

  const counter = (
    <div
      className={clsx(s.GameBanner__counter, {
        [s.GameBanner__counter_success]:
          gameCompleted && gameState.correctAmount === gameState.totalAmount,
        [s.GameBanner__counter_error]:
          gameCompleted && gameState.correctAmount !== gameState.totalAmount
      })}
    >
      {gameState.completeAmount}/{gameState.totalAmount}
    </div>
  );

  return (
    <LayoutContent
      ref={(r) => r && observeNode(SECTION_IDS.safe_click, r)}
      className={s.SectionGame}
    >
      <LayoutCard className={s.GameBanner} variant={LayoutCardVariant.gray}>
        {isDesktop && counter}

        <div className={s.GameBanner__grid}>
          <div className={s.GameBanner__content}>
            <div className={s.GameBanner__title}>Безопасный клик</div>
            <div className={s.GameBanner__text}>
              Проверьте свою бдительность и разоблачите мошенников
            </div>

            {gameCompleted && (
              <div className={s.GameBanner__actions}>
                <Button
                  className={s.GameBanner__action}
                  size={isMobile ? ButtonSize.medium : ButtonSize.xlarge}
                  onClick={() => {
                    handlePlayAgainClick();
                    restart();
                  }}
                >
                  Играть ещё
                </Button>
              </div>
            )}
          </div>

          {!isDesktop && counter}

          <div className={s.GameBanner__room}>
            <div className={s.GameBanner__roomPic}>
              <GameRoom
                availableTargets={availableTargets}
                answers={answers}
                selectedTarget={selectedTarget}
                selectTarget={selectTarget}
              />
            </div>
          </div>
        </div>
      </LayoutCard>
    </LayoutContent>
  );
};

export const SectionGame = () => {
  return (
    <GameProvider>
      <Game />
    </GameProvider>
  );
};
