import React from 'react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ErrorBoundary } from '../../../utils/ErrorBoundary';
import { ErrorPage } from '../../ui/ErrorPage/ErrorPage';
import { Header, HeaderProps } from '../Header/Header';
import { useApi } from '../../../../store/api/hooks/useApi';
import { Loader } from '../../ui/Loader/Loader';
import s from './DefaultLayout.module.scss';

export interface DefaultLayoutProps extends Pick<HeaderProps, 'navLinks'> {}

export const DefaultLayout = ({ navLinks }: DefaultLayoutProps) => {
  const { search } = useLocation();
  const { loading, allLoaded } = useApi();

  return (
    <div className={s.DefaultLayout}>
      <Helmet>
        {search && <link rel="canonical" href={window.location.origin} />}
      </Helmet>
      <ErrorBoundary component={ErrorPage}>
        <Header navLinks={navLinks} />
        <Outlet />
        {(loading || !allLoaded) && (
          <div className={s.DefaultLayout__loader}>
            <Loader />
          </div>
        )}
      </ErrorBoundary>
      <ScrollRestoration />
    </div>
  );
};
