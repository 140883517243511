import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { AxiosClientsContext } from '@proscom/prostore-axios-react';
import { EMDASH } from '@proscom/ui-utils';
import { RouterProvider } from 'react-router-dom';
import { InDevPage } from './routes';
import { WebinarProvider } from './store/webinar/WebinarsProvider';
import { FlyActions } from './common/components/ui/FlyActions/FlyActions';
import { MetricsProvider } from './store/metrics/MetricsProvider';
import { ApiProvider } from './store/api/ApiProvider';
import { UIProvider } from './store/ui/UIProvider';
import { inDev } from './config';
import { BannersProvider } from './store/banners/BannersProvider';
import { router } from './routes/router';
import { axiosContext } from './appStores';

const siteTitle =
  'Горячая линия по борьбе с мошенниками в Москве — Перезвони сам';

export function App() {
  return (
    <HelmetProvider>
      <Helmet
        defaultTitle={siteTitle}
        titleTemplate={`%s ${EMDASH} ${siteTitle}`}
      />
      {inDev ? (
        <InDevPage />
      ) : (
        <AxiosClientsContext.Provider value={axiosContext}>
          <ApiProvider>
            <MetricsProvider>
              <UIProvider>
                <BannersProvider>
                  <WebinarProvider>
                    <RouterProvider
                      router={router}
                      future={{ v7_startTransition: true }}
                    />
                  </WebinarProvider>
                </BannersProvider>
              </UIProvider>
            </MetricsProvider>
          </ApiProvider>
          <FlyActions />
        </AxiosClientsContext.Provider>
      )}
    </HelmetProvider>
  );
}
