import React from 'react';
import { WebinarType } from '../../../../../api/types/WebinarType';
import {
  ButtonAnchor,
  ButtonVariant
} from '../../../../../common/components/ui/Button';
import { ReactComponent as IconEye } from '../../../../../assets/img/icons/i-eye.svg';
import { ReactComponent as IconPlay } from '../../../../../assets/img/icons/i-play.svg';
import { createImageUrl } from '../../../../../api/hooks/useImageUrl';
import { useFormatDate } from '../../../../../common/hooks/useFormatDate';
import { DATE_FORMAT_SHORT_FULL_YEAR } from '../../../../../utils/date/date';
import { BaseLink } from '../../../../../common/components/ui/BaseLink/BaseLink';
import { Icon } from '../../../../../common/components/ui/Icon/Icon';
import webinarPicSrc from '../../../../../assets/img/content/webinars/pic.jpg';
import { LayoutCard } from '../../../../../common/components/layout/LayoutCard/LayoutCard';
import { useMetricsContext } from '../../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../../store/metrics/types';
import { sectionLabelsById } from '../../../../../constants';
import { createFileUrl } from '../../../../../api/hooks/useFileUrl';
import { externalLinkHtmlProps } from '../../../../../common/constants';
import s from './WebinarArchiveCard.module.scss';

export interface WebinarArchiveCardProps extends WebinarType {}

export const WebinarArchiveCard = ({
  name,
  description_short,
  date_start,
  preview_image,
  link,
  presentation_file
}: WebinarArchiveCardProps) => {
  const { params } = useMetricsContext();
  const imageSrc = createImageUrl(preview_image);
  const fileSrc = createFileUrl(presentation_file);
  const dateStr = useFormatDate(date_start, DATE_FORMAT_SHORT_FULL_YEAR);

  const handleActionClick = (label: string) => {
    if (name && label) {
      params({
        [sectionLabelsById.webinars_past]: {
          Вебинар: {
            [name]: {
              [label]: MetricsParams.Click
            }
          }
        }
      });
    }
  };

  return (
    <LayoutCard className={s.WebinarArchiveCard}>
      <BaseLink
        href={link}
        isClickable={!!link}
        className={s.WebinarArchiveCard__picLink}
      >
        <div
          className={s.WebinarArchiveCard__pic}
          style={{ backgroundImage: `url(${imageSrc || webinarPicSrc})` }}
        >
          {link && (
            <div className={s.WebinarArchiveCard__playButton}>
              <Icon
                className={s.WebinarArchiveCard__playButtonIcon}
                icon={<IconPlay />}
                boxSize={'auto'}
              />
            </div>
          )}
        </div>
      </BaseLink>
      <div className={s.WebinarArchiveCard__content}>
        <div className={s.WebinarArchiveCard__info}>
          <span className={s.WebinarArchiveCard__date}>{dateStr}</span>
          <p className={s.WebinarArchiveCard__name}>{name}</p>
          <p className={s.WebinarArchiveCard__description}>
            {description_short}
          </p>
        </div>
        {(link || fileSrc) && (
          <div className={s.WebinarArchiveCard__actions}>
            {link && (
              <ButtonAnchor
                href={link}
                {...externalLinkHtmlProps}
                onClick={() => handleActionClick('Смотреть в VK')}
              >
                Смотреть в VK
              </ButtonAnchor>
            )}
            {fileSrc && (
              <ButtonAnchor
                href={fileSrc}
                {...externalLinkHtmlProps}
                iconLeft={<IconEye />}
                variant={ButtonVariant.secondary}
                onClick={() => handleActionClick('Презентация')}
              >
                Презентация
              </ButtonAnchor>
            )}
          </div>
        )}
      </div>
    </LayoutCard>
  );
};
