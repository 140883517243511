import React, { MouseEventHandler } from 'react';
import { Link } from 'react-scroll';
import { reactScrollLinkDefaults } from '../../../constants';

type OptionsType = typeof reactScrollLinkDefaults;

export interface ScrollLinkProps extends Partial<OptionsType> {
  className?: string;
  activeClass?: string;
  to?: string;
  onClick?: MouseEventHandler;
  children?: React.ReactNode;
}

export const ScrollLink = ({ children, ...props }: ScrollLinkProps) => {
  return (
    <Link {...reactScrollLinkDefaults} {...props}>
      {children}
    </Link>
  );
};
