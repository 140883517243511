import React, { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { LayoutContent } from '../../../../common/components/layout/LayoutContent/LayoutContent';
import { useDocuments } from '../../../../api/hooks/useDocuments';
import { useQueryLoading } from '../../../../store/api/hooks/useQueryLoading';
import {
  LayoutCard,
  LayoutCardVariant
} from '../../../../common/components/layout/LayoutCard/LayoutCard';
import { Button, ButtonVariant } from '../../../../common/components/ui/Button';
import { useMetricsContext } from '../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../store/metrics/types';
import { SECTION_IDS, sectionLabelsById } from '../../../../constants';
import { ROUTE_MATERIAL } from '../../../routes';
import { Document } from '../../../../common/components/ui/Document/Document';
import s from './SectionMaterials.module.scss';

const showItemsAmount = 6;

export const SectionMaterials: React.FC = () => {
  const { params, observeNode } = useMetricsContext();
  const [showMore, setShowMore] = useState(false);

  const docsQuery = useDocuments();
  const loading = useQueryLoading(docsQuery);
  const docs = docsQuery.state.data;

  const handleShowMore = () => {
    const more = !showMore;
    setShowMore(more);

    const moreLabel = more ? 'Показать еще' : 'Свернуть';
    params({
      [sectionLabelsById.materials]: {
        Кнопки: {
          [moreLabel]: MetricsParams.Click
        }
      }
    });
  };

  const handleCardClick = (name: string) => {
    if (name) {
      params({
        [sectionLabelsById.materials]: {
          Карточки: {
            [name]: MetricsParams.Click
          }
        }
      });
    }
  };

  const needShowMore = (docs?.length || 0) > showItemsAmount;
  const showAll = showMore || !needShowMore;
  const itemsToShow = showAll ? docs : docs?.slice(0, showItemsAmount);

  return (
    <LayoutContent
      ref={(r) => r && observeNode(SECTION_IDS.materials, r)}
      className={s.SectionMaterials}
      adaptive
    >
      <LayoutCard className={s.Card} variant={LayoutCardVariant.brown} adaptive>
        <div className={s.Card__content}>
          <h2 className={s.SectionMaterials__title}>Полезная информация</h2>

          {loading || (
            <div className={s.SectionMaterials__list}>
              {itemsToShow?.map(({ slug, ...item }) => (
                <Document
                  key={item.id}
                  {...item}
                  link={slug ? generatePath(ROUTE_MATERIAL, { slug }) : ''}
                  onClick={() => handleCardClick(item.name)}
                />
              ))}
            </div>
          )}

          {needShowMore && (
            <div className={s.SectionMaterials__more}>
              <Button variant={ButtonVariant.ghost} onClick={handleShowMore}>
                {showMore ? 'Свернуть' : 'Показать ещё'}
              </Button>
            </div>
          )}
        </div>
      </LayoutCard>
    </LayoutContent>
  );
};
