import React from 'react';
import clsx from 'clsx';
import { ErrorBoundary } from '../../../utils/ErrorBoundary';
import { ErrorPageContent } from '../../ui/ErrorPage/ErrorPageContent';
import s from './PageLayout.module.scss';

export interface PageLayoutProps {
  className?: string;
  centeredContent?: boolean;
  children?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  className,
  centeredContent,
  children
}) => {
  return (
    <div
      className={clsx(s.PageLayout, className, {
        [s.PageLayout__centered]: centeredContent
      })}
    >
      <ErrorBoundary component={ErrorPageContent}>{children}</ErrorBoundary>
    </div>
  );
};
