import React, { useCallback } from 'react';
import clsx from 'clsx';
import {
  Checkbox,
  CheckboxNameType,
  CheckboxProps,
  CheckboxVariant
} from '../Checkbox/Checkbox';
import { HelpText, HelpTextType } from '../HelpText/HelpText';
import s from './RadioGroup.module.scss';

export type RadioGroupValueType = CheckboxNameType;

export interface RadioItemProps
  extends Pick<CheckboxProps, 'label' | 'disabled'> {
  name: CheckboxNameType;
}

export interface RadioGroupClasses {
  root?: string;
  helperText?: string;
}

export interface RadioGroupProps {
  className?: string;
  classes?: RadioGroupClasses;
  items: RadioItemProps[];
  value?: RadioGroupValueType | null;
  vertical?: boolean;
  errorMessage?: string;
  hintMessage?: string;
  onChange: (value: RadioGroupValueType) => void;
}

export const RadioGroup = ({
  className,
  classes,
  items,
  value,
  vertical,
  errorMessage,
  hintMessage,
  onChange
}: RadioGroupProps) => {
  const handleChange = useCallback(
    (value: boolean, name: RadioGroupValueType) => {
      if (value) {
        onChange(name);
      }
    },
    [onChange]
  );

  return (
    <div
      className={clsx(s.RadioGroup, className, classes?.root, {
        [s.RadioGroup_vertical]: vertical
      })}
    >
      {items.map(({ name, label, disabled }) => (
        <Checkbox
          key={name}
          name={name}
          variant={CheckboxVariant.Radio}
          label={label}
          value={name === value}
          disabled={disabled}
          onChange={(v) => handleChange(v, name)}
        />
      ))}

      <HelpText
        className={classes?.helperText}
        text={errorMessage || hintMessage}
        type={errorMessage ? HelpTextType.error : HelpTextType.hint}
      />
    </div>
  );
};
