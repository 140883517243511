import { isNil } from 'lodash-es';
import { AnyType } from '../commonTypes';

/**
 * isNumberLike - позволяет понять, является ли переданный аргумент подобным числовому значению (Int, Float)
 * и возвращает boolean
 *
 * i.e.
 * isNumberLike(123): true
 * isNumberLike("123.2"): true
 *
 * isNumberLike("abc"): false
 * isNumberLike("123abc"): false
 * isNumberLike(""): false
 * isNumberLike(null): false
 * isNumberLike({ a: 1 }): false
 *
 * @param value: any
 * @return boolean
 */
export function isNumberLike(value: AnyType): boolean {
  if (isNil(value)) return false;
  if (isNaN(parseFloat(value as string))) return false;
  return isFinite(+value);
}

/**
 * tryNumberLike:
 * - возвращает переданный аргумент value, если value проходит проверку isNumberLike(value)
 * - возвращает defaultValue, если value не проходит проверку isNumberLike(value)
 * - конвертирует value в number, если value проходит проверку isNumberLike(value) и convertToNumber === true
 *
 * перегрузка метода нужна, т.к. присутствует неявное приведение типов [string | number]
 *
 * i.e.
 * tryNumberLike(123, null): 123
 * tryNumberLike("123.1", null): "123.1"
 * tryNumberLike("123.1", null, true): 123.1
 *
 * tryNumberLike("abc", null): null
 * tryNumberLike("123abc", null): null
 * tryNumberLike("", null): null
 * tryNumberLike({ a: 1 }, null): null
 * tryNumberLike({ a: 1 }, 123): 123
 *
 * @param value: any
 * @param defaultValue: any | undefined
 * @param convertToNumber: boolean | undefined
 * @return string | number | typeof defaultValue
 */
export function tryNumberLike<V extends AnyType, DV extends AnyType>(
  value: V,
  defaultValue: DV,
  convertToNumber?: true
): number | DV;
export function tryNumberLike<V extends AnyType, DV extends AnyType>(
  value: V,
  defaultValue: DV,
  convertToNumber?: false
): string | DV;
export function tryNumberLike<V extends AnyType, DV extends AnyType>(
  value: V,
  defaultValue?: DV,
  convertToNumber: boolean = false
): string | number | DV {
  if (isNumberLike(value)) {
    const val = value as string | number;
    return convertToNumber ? +val : val;
  }
  return defaultValue as DV;
}
