import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTestQuestions } from '../../api/hooks/useTestQuestions';
import { TestQuestionType, TestResultType } from '../../api/types/TestType';
import { ModalTest } from '../../common/components/ui/Modal/ModalTest/ModalTest';
import { useWebinarsContext } from '../webinar/hooks/useWebinarsContext';
import { useQueryLoading } from '../api/hooks/useQueryLoading';
import { TestContext } from './TestContext';
import { TestUtils } from './TestUtils';

export interface TestProviderProps {
  children?: React.ReactNode;
}

export const TestProvider: React.FC<TestProviderProps> = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [questions, setQuestions] = useState<TestQuestionType[]>([]);
  const [answers, setAnswers] = useState<number[]>([]);
  const answersAmount = answers.length;

  const { onWebinarsSubscription } = useWebinarsContext();

  const testQuery = useTestQuestions();
  const queryQuestions = testQuery.state.data;

  useQueryLoading(testQuery);

  const pickQuestions = useCallback(() => {
    setQuestions(TestUtils.pickTestQuestions(queryQuestions));
  }, [queryQuestions]);

  useEffect(() => {
    pickQuestions();
  }, [pickQuestions]);

  const questionsAmount = questions.length;

  const currentQuestion = useMemo(() => {
    if (!questions?.length) return null;
    return questions[stepIndex];
  }, [stepIndex, questions]);

  const currentAnswer = answers[stepIndex];

  const testCompleted = questionsAmount === answersAmount;

  const testResults: TestResultType[] = useMemo(() => {
    if (!questions?.length || !answers.length) return [];
    return answers.map((answerId, index) => {
      const q = questions[index];
      const currAnswer = q.answers.find((a) => a.id === answerId);
      // const qCorrectAnswer = q.answers.find(
      //   (qAnswer) => qAnswer.isCorrectAnswer
      // );
      return {
        question: q.question,
        answer: currAnswer
        // note: currAnswer?.note,
        // isCorrectAnswer: Boolean(qCorrectAnswer && qCorrectAnswer.id === answer)
        // isCorrectAnswer: !!currAnswer?.isCorrectAnswer
      };
    });
  }, [questions, answers]);

  const handleGoNext = useCallback(
    (id: number) => {
      setAnswers((prevState) => {
        const answs = prevState.slice();
        answs[stepIndex] = id;
        return answs;
      });
      setStepIndex(stepIndex + 1);
    },
    [stepIndex]
  );

  const handleGoPrev = useCallback(() => {
    setStepIndex(stepIndex - 1);
  }, [stepIndex]);

  const handleTestStart = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleSubscribe = useCallback(() => {
    setModalOpen(false);
    onWebinarsSubscription();
  }, [onWebinarsSubscription]);

  const handleRestart = useCallback(() => {
    setStepIndex(0);
    setAnswers([]);
    pickQuestions();
  }, [pickQuestions]);

  const handleModalClosed = useCallback(() => {
    if (!testCompleted) {
      setStepIndex(0);
      setAnswers([]);
    }
  }, [testCompleted]);

  return (
    <TestContext.Provider
      value={{
        testCompleted,
        startTest: handleTestStart
      }}
    >
      {children}
      <ModalTest
        stepIndex={stepIndex}
        question={currentQuestion}
        questionsAmount={questions.length}
        answer={currentAnswer}
        testCompleted={testCompleted}
        testResults={testResults}
        onGoNext={handleGoNext}
        onGoPrev={handleGoPrev}
        onSubscribe={handleSubscribe}
        onRestart={handleRestart}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onClosed={handleModalClosed}
      />
    </TestContext.Provider>
  );
};
