import React from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout } from '../../common/components/layout/PageLayout/PageLayout';
import { useDocuments } from '../../api/hooks/useDocuments';
import { useQueryLoading } from '../../store/api/hooks/useQueryLoading';
import { ErrorPage } from '../../common/components/ui/ErrorPage/ErrorPage';
import { MDtoHTMLContainer } from '../../common/components/ui/MDtoHTMLContainer/MDtoHTMLContainer';
import { DocType } from '../../api/types/DocumentType';
import { LayoutContent } from '../../common/components/layout/LayoutContent/LayoutContent';
import { Document } from '../../common/components/ui/Document/Document';
import s from './MaterialPage.module.scss';

function MaterialPageComponent({
  name,
  image,
  file,
  content,
  updatedAt
}: DocType) {
  return (
    <PageLayout className={s.MaterialPage}>
      <LayoutContent>
        <div className={s.Material}>
          <div className={s.Material__head}>
            <Document
              className={s.Material__card}
              name={name}
              image={image}
              file={file}
              updatedAt={updatedAt}
            />
          </div>

          <MDtoHTMLContainer
            className={s.Material__content}
            mdString={content}
          />
        </div>
      </LayoutContent>
    </PageLayout>
  );
}

export function MaterialPage() {
  const { slug } = useParams<{ slug: string }>();

  const docsQuery = useDocuments(slug);
  const loading = useQueryLoading(docsQuery);
  const doc = docsQuery.state.data?.[0];
  const loaded = docsQuery.state.loaded;

  if (loading || !doc) return null;

  if (loaded && !doc) {
    return <ErrorPage title={'Материал не найден'} />;
  }

  return <MaterialPageComponent {...doc} />;
}
