import showdown from 'showdown';

const converter = new showdown.Converter({
  openLinksInNewWindow: true
});

export class MDConverter {
  static convertToHtml(mdString?: string | null): string {
    return converter.makeHtml(mdString || '');
  }
}
