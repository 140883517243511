import { useAsyncOperation } from '@proscom/prostore-react';
import { useCallback } from 'react';
import { useWebinar } from '../../../api/hooks/useWebinar';
import { WebinarUtils } from '../WebinarUtils';

export function useGetWebinar(id?: number) {
  const webinarQuery = useWebinar(id);
  const webinar = webinarQuery.state.data;
  const loading = webinarQuery.check.spinner;
  const { run: checkAvailablePlacesRun, loading: checkAvailablePlacesLoading } =
    useAsyncOperation(() => {
      return webinarQuery.load();
    });

  const checkAvailablePlaces = useCallback(async () => {
    const result = await checkAvailablePlacesRun();
    return WebinarUtils.countAvailablePlaces(result.data);
  }, [checkAvailablePlacesRun]);

  return {
    webinarQuery,
    webinar,
    loading: !webinarQuery.state.loaded && loading,
    checkAvailablePlaces,
    checkAvailablePlacesLoading
  };
}
