import React from 'react';
import { PageLayout } from '../../layout/PageLayout/PageLayout';
import { ErrorPageContent, ErrorPageContentProps } from './ErrorPageContent';
import s from './ErrorPage.module.scss';

export interface ErrorPageProps extends ErrorPageContentProps {}

export function ErrorPage(props: ErrorPageProps) {
  return (
    <PageLayout className={s.ErrorPage} centeredContent>
      <ErrorPageContent {...props} />
    </PageLayout>
  );
}
