import React from 'react';
import {
  AnyProps,
  getComponentName,
  PropsSplitter,
  StringKeys
} from '../types';

export type AnyBaseComponentProps<ComponentBaseProps extends AnyProps> =
  AnyProps & {
    renderBase: (baseProps: ComponentBaseProps) => JSX.Element;
  };

export type GenericComponentType<
  ComponentBaseProps extends AnyProps,
  BaseComponentProps extends AnyBaseComponentProps<ComponentBaseProps>
> = React.ComponentType<BaseComponentProps> & {
  splitProps: PropsSplitter<StringKeys<BaseComponentProps>>;
};

export type ConcreteComponentProps<
  BaseComponentProps extends AnyProps,
  Props extends AnyProps
> = Omit<BaseComponentProps & Props, 'renderBase'>;

export function GenericComponent<
  ComponentBaseProps extends AnyProps,
  BaseComponentProps extends AnyBaseComponentProps<ComponentBaseProps>
>(component: GenericComponentType<ComponentBaseProps, BaseComponentProps>) {
  return function createGeneric<Props extends AnyProps>(
    base: React.ComponentType<Props>,
    transformProps: (
      baseProps: ComponentBaseProps,
      restProps: Omit<
        ConcreteComponentProps<BaseComponentProps, Props>,
        StringKeys<BaseComponentProps>
      >
    ) => Props = (a, b) => ({ ...a, ...b } as any)
  ) {
    function ConcreteComponent(
      props: ConcreteComponentProps<BaseComponentProps, Props>
    ) {
      const [baseProps, restProps] = component.splitProps(props);

      return React.createElement(component, {
        ...baseProps,
        renderBase: (renderProps: ComponentBaseProps) => {
          return React.createElement(
            base,
            transformProps(renderProps, restProps)
          );
        }
      } as any);
    }

    ConcreteComponent.displayName =
      getComponentName(component) + getComponentName(base);

    return ConcreteComponent;
  };
}
