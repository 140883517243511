import { useAxiosQuery } from '@proscom/prostore-axios-react';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { QueryResultType } from '../types/QueryResultType';
import { SaveClickType } from '../types/SaveClickType';

const queryOptions = {
  query: {
    url: '/save-click'
  },
  mapVariables: AxiosQueryStore.mapVariablesParams
};

export function useSaveClickQuery(): QueryResultType<SaveClickType> {
  return useAxiosQuery({
    queryOptions,
    variables: {
      populate: [
        'success_image',
        'fail_image',
        'questions.image',
        'questions.answers.image'
      ]
    }
  });
}
