import { ForwardedRef, MutableRefObject } from 'react';

export function mergeRefs<T>(
  ...refs: (MutableRefObject<T> | ForwardedRef<T>)[]
) {
  return (refNode: T | null) => {
    for (const r of refs) {
      if (r) {
        if (typeof r === 'function') {
          r(refNode);
        } else {
          r.current = refNode;
        }
      }
    }
  };
}
