import React, { useCallback, useMemo, useState } from 'react';
import { useQueryLoading } from '../api/hooks/useQueryLoading';
import { GameQuestionType, GameTargetEnum } from '../../api/types/GameType';
import { ModalGame } from '../../common/components/ui/Modal/ModalGame/ModalGame';
import { useWebinarsContext } from '../webinar/hooks/useWebinarsContext';
import { useSaveClickQuery } from '../../api/hooks/useSaveClickQuery';
import { PopulatedArrayRequiredDataType } from '../../api/types/strapi.types';
import { GameContext, GameContextType } from './GameContext';

export interface GameProviderProps {
  children?: React.ReactNode;
}

export const GameProvider: React.FC<GameProviderProps> = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTarget, selectTarget] =
    useState<GameContextType['selectedTarget']>(null);
  const [answers, setAnswers] = useState<GameContextType['answers']>(new Map());
  const [gameResult, setGameResult] = useState<
    GameContextType['gameState'] | null
  >(null);

  const { onWebinarsSubscription } = useWebinarsContext();

  const saveClickQuery = useSaveClickQuery();

  useQueryLoading(saveClickQuery);

  const { questions: queryQuestions, ...data } =
    saveClickQuery.state.data || {};

  const questions = useMemo(() => {
    return (queryQuestions?.data?.filter((q) => !!q.attributes) ||
      []) as PopulatedArrayRequiredDataType<GameQuestionType>;
  }, [queryQuestions]);

  const availableTargets = useMemo(() => {
    if (!questions?.length) return [];
    const questionsTargets = new Set(questions.map((q) => q.attributes.target));
    return Object.values(GameTargetEnum).filter((t) => questionsTargets.has(t));
  }, [questions]);

  const currentQuestion = useMemo(() => {
    if (!selectedTarget || !questions?.length) return undefined;

    const qArray = questions.filter(
      (q) => q.attributes.target === selectedTarget
    );
    return qArray[Math.floor(Math.random() * qArray.length)];
  }, [questions, selectedTarget]);

  const questionResult = useMemo(() => {
    if (!currentQuestion || !answers.has(currentQuestion.attributes.target)) {
      return null;
    }

    const currAnswer = currentQuestion.attributes.answers.find(
      (answer) =>
        answer.id === answers.get(currentQuestion.attributes.target)?.answerId
    );

    if (!currAnswer) {
      return null;
    }

    return {
      question: currentQuestion.attributes.question,
      answer: currAnswer
    };
  }, [answers, currentQuestion]);

  const questionsAmount = availableTargets.length;

  const gameState: GameContextType['gameState'] = useMemo(() => {
    return {
      completeAmount: answers.size,
      correctAmount: questionsAmount
        ? Array.from(answers.values()).filter((a) => a.isCorrectAnswer).length
        : 0,
      totalAmount: questionsAmount
    };
  }, [answers, questionsAmount]);

  const handleQuestionSubmit = useCallback(
    (answerId: number) => {
      if (!currentQuestion) return;

      const answer = currentQuestion.attributes.answers.find(
        (a) => a.id === answerId
      );

      if (!answer) return;

      setAnswers((prevState) => {
        const map = new Map(prevState);
        map.set(currentQuestion.attributes.target, {
          questionId: currentQuestion.id,
          answerId: answer.id,
          isCorrectAnswer: answer.isCorrectAnswer
        });
        return map;
      });
    },
    [currentQuestion]
  );

  const handleTargetSelect = useCallback((target: GameTargetEnum | null) => {
    selectTarget(target);
    setModalOpen(!!target);
  }, []);

  const handleModalClosed = useCallback(() => {
    selectTarget(null);
  }, []);

  const handleComplete = useCallback(() => {
    selectTarget(null);
    setGameResult(gameState);
  }, [gameState]);

  const handleRestart = useCallback(() => {
    setModalOpen(false);
    selectTarget(null);
    setGameResult(null);
    setAnswers(new Map());
  }, []);

  const handleSubscribe = useCallback(() => {
    setModalOpen(false);
    onWebinarsSubscription();
  }, [onWebinarsSubscription]);

  const gameCompleted = !!(
    answers.size &&
    availableTargets.length &&
    answers.size === availableTargets.length
  );

  return (
    <GameContext.Provider
      value={{
        availableTargets,
        selectedTarget,
        selectTarget: handleTargetSelect,
        restart: handleRestart,
        answers,
        gameState,
        gameCompleted
      }}
    >
      {children}

      <ModalGame
        isOpen={modalOpen}
        question={currentQuestion?.attributes}
        questionResult={questionResult}
        gameResult={gameResult}
        gameState={gameState}
        gameCompleted={gameCompleted}
        onSubmit={handleQuestionSubmit}
        onComplete={handleComplete}
        onSubscribe={handleSubscribe}
        onRestart={handleRestart}
        onClose={() => setModalOpen(false)}
        onClosed={handleModalClosed}
        {...data}
      />
    </GameContext.Provider>
  );
};
