import React from 'react';
import { useQueryLoading } from '../api/hooks/useQueryLoading';
import { useBannersQuery } from '../../api/hooks/useBannersQuery';
import { BannersContext } from './BannersContext';

export interface BannersProviderProps {
  children?: React.ReactNode;
}

export const BannersProvider: React.FC<BannersProviderProps> = ({
  children
}) => {
  const bannersQuery = useBannersQuery();
  const queryBanners = bannersQuery.state.data;

  useQueryLoading(bannersQuery);

  const eventBanners = queryBanners?.event_banners || [];

  return (
    <BannersContext.Provider
      value={{
        eventBanner: eventBanners[0]
      }}
    >
      {children}
    </BannersContext.Provider>
  );
};
