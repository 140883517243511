import React, { useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import { useContextAxiosClient } from '@proscom/prostore-axios-react';
import { useAsyncOperation } from '@proscom/prostore-react';
import { Modal, ModalProps } from '../Modal';
import { Button, ButtonSize } from '../../Button';
import { FormTextField } from '../../form/FormTextField/FormTextField';
import { TextFieldSize } from '../../TextField/TextField';
import { FormCheckbox } from '../../form/FormCheckbox/FormCheckbox';
import { TextLink } from '../../TextLink/TextLink';
import { shareStoryValidationSchema } from '../../../../../store/validationSchemas';
import { ResultAlert } from '../../ResultAlert/ResultAlert';
import { FormTextArea } from '../../form/FormTextArea/FormTextArea';
import { TextAreaSize } from '../../TextField/TextArea';
import {
  ShareStoryFormValues,
  StoryUtils
} from '../../../../../store/story/StoryUtils';
import { agreementLinks, sectionLabelsById } from '../../../../../constants';
import { FileUploadSize } from '../../FileUpload/FileUpload';
import { FormFileUpload } from '../../form/FormFileUpload/FormFileUpload';
import { objectToFormData } from '../../../../../utils/form/objectToFormData';
import { parseApiError } from '../../../../../api/utils/errors';
import { useMetricsContext } from '../../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../../store/metrics/types';
import s from './ModalShareStory.module.scss';

export interface ModalShareStoryProps
  extends Pick<ModalProps, 'isOpen' | 'onClose' | 'onClosed'> {}

const initialValues: ShareStoryFormValues = {
  name: '',
  surname: '',
  patronymic: '',
  email: '',
  phone: '',
  message: '',
  attachments: [],
  noPatronymic: false,
  terms: false
};

export const ModalShareStory: React.FC<ModalShareStoryProps> = ({
  onClose,
  onClosed,
  ...props
}) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const { params } = useMetricsContext();

  const handleActionClick = useCallback(
    (label: string) => {
      if (label) {
        params({
          [sectionLabelsById.stories]: {
            'Поделиться историей': {
              Модалка: {
                [label]: MetricsParams.Click
              }
            }
          }
        });
      }
    },
    [params]
  );

  const client = useContextAxiosClient();
  const storyShareMutation = useAsyncOperation((data) => {
    return client.post('/story/sendStory', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      transformRequest: (d) => d.data
    });
  });
  const { run, loading } = storyShareMutation;

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const input = StoryUtils.fromForm(data);

        const result = await run({
          data: objectToFormData(input)
        });

        if (result.status !== 200) {
          throw new Error(`Error: ${result.status} - story sharing`);
        }

        setSuccess(true);
        setError('');
        // reachGoal(MetricsGoals.OfflineWebinar);
        handleActionClick('Отправить');
      } catch (err) {
        setError(
          parseApiError(err, 'Ошибка отправки формы. Попробуйте еще раз.')
        );
        console.error(err);
      }
    },
    [run, handleActionClick]
  );

  const handleClose = useCallback(() => {
    onClose?.();
    if (!success) {
      handleActionClick('Закрыть');
    }
  }, [success, handleActionClick, onClose]);

  const handleClosed = useCallback(() => {
    onClosed?.();
    setError('');
    setSuccess(false);
  }, [onClosed]);

  return (
    <Modal
      className={s.ModalShareStory}
      title={'Поделитесь историей'}
      onClose={handleClose}
      onClosed={handleClosed}
      {...props}
    >
      {({ close }) => (
        <>
          {success ? (
            <ResultAlert className={s.SuccessAlert}>
              Ваше сообщение успешно отправлено. Мы обязательно свяжемся
              с&nbsp;вами в&nbsp;ближайшее время
            </ResultAlert>
          ) : (
            <div className={s.ModalShareStory__hint}>
              <span>Важно!</span> Наш проект – информационный. Мы не сможем
              решить вашу проблему, но уверены, что ваш рассказ сможет
              предостеречь других читателей.
            </div>
          )}

          <div className={s.ModalShareStory__content}>
            {success ? (
              <Button
                className={s.Success__submitBtn}
                size={ButtonSize.xlarge}
                onClick={close}
              >
                Хорошо
              </Button>
            ) : (
              <Formik
                initialValues={initialValues}
                validationSchema={shareStoryValidationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
              >
                {({ values }) => (
                  <Form className={s.Form}>
                    <div className={s.Form__line}>
                      <FormTextField
                        name={'surname'}
                        label={'Фамилия *'}
                        placeholder={'Введите фамилию'}
                        size={TextFieldSize.xlarge}
                        disabled={loading}
                      />
                    </div>
                    <div className={s.Form__line}>
                      <FormTextField
                        name={'name'}
                        label={'Имя *'}
                        placeholder={'Введите имя'}
                        size={TextFieldSize.xlarge}
                        disabled={loading}
                      />
                    </div>
                    <div className={s.Form__line}>
                      <FormTextField
                        name={'patronymic'}
                        label={'Отчество *'}
                        placeholder={'Введите отчество'}
                        size={TextFieldSize.xlarge}
                        disabled={loading || values.noPatronymic}
                      />
                      <FormCheckbox
                        classes={{
                          root: s.Form__NoPatronymicCheckbox,
                          label: s.Form__NoPatronymicCheckboxLabel
                        }}
                        name={'noPatronymic'}
                        label={'У меня нет отчества'}
                      />
                    </div>
                    <div className={s.Form__line}>
                      <FormTextField
                        name={'email'}
                        label={'E-mail *'}
                        placeholder={'Введите E-mail'}
                        size={TextFieldSize.xlarge}
                        disabled={loading}
                      />
                    </div>
                    <div className={s.Form__line}>
                      <FormTextField
                        name={'phone'}
                        label={'Номер телефона'}
                        placeholder={'Введите номер телефона'}
                        size={TextFieldSize.xlarge}
                        maskProps={{
                          mask: '+7 (999) 999-99-99'
                        }}
                        disabled={loading}
                      />
                    </div>
                    <div className={s.Form__line}>
                      <FormTextArea
                        name={'message'}
                        label={'Ваше сообщение *'}
                        placeholder={'Введите текст вашего сообщения'}
                        size={TextAreaSize.xlarge}
                        disabled={loading}
                        minRows={3}
                        maxRows={6}
                        maxLength={StoryUtils.messageMaxLength}
                        autoSize
                      />
                    </div>
                    <div className={s.Form__line}>
                      <FormFileUpload
                        name={'attachments'}
                        label={'Прикрепите скриншоты'}
                        size={FileUploadSize.xlarge}
                        accept={{
                          'image/jpeg': [],
                          'image/png': []
                        }}
                        maxFiles={StoryUtils.attachmentsMaxAmount}
                        maxSize={StoryUtils.attachmentsMaxSize}
                        hintMessage={`Максимум файлов: ${StoryUtils.attachmentsMaxAmount}`}
                      />
                    </div>
                    <div className={s.Form__line}>
                      <div className={s.Form__termsCheckbox}>
                        <FormCheckbox
                          className={s.Form__termsCheckboxInput}
                          name={'terms'}
                        />
                        <div className={s.Form__termsCheckboxText}>
                          Нажимая на кнопку, я соглашаюсь на{' '}
                          <TextLink
                            className={s.Form__termsCheckboxLink}
                            href={agreementLinks.personalDataTerms.href}
                          >
                            обработку персональных данных
                          </TextLink>{' '}
                          и с{' '}
                          <TextLink
                            className={s.Form__termsCheckboxLink}
                            href={agreementLinks.userTerms.href}
                          >
                            правилами пользования Платформой
                          </TextLink>
                        </div>
                      </div>
                    </div>

                    {error && <div className={s.Form__error}>{error}</div>}

                    <div className={s.Form__actions}>
                      <Button
                        className={s.Form__submitBtn}
                        size={ButtonSize.xlarge}
                        type={'submit'}
                        disabled={loading}
                      >
                        Отправить
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};
