import React from 'react';
import { useField } from 'formik';
import { FileUpload, FileUploadProps } from '../../FileUpload/FileUpload';

export interface FormFileUploadProps
  extends Omit<FileUploadProps, 'value' | 'onChange' | 'onError'> {
  name: string;
  touched?: boolean;
  error?: string;
}

export const FormFileUpload = ({
  name,
  touched: touchedProp,
  error: errorProp,
  ...props
}: FormFileUploadProps) => {
  const [field, meta, { setValue, setError, setTouched }] = useField(name);

  const { error, touched } = meta;
  const currError = error || errorProp;
  const currTouched = touched || touchedProp;

  return (
    <FileUpload
      value={field.value}
      onChange={(v) => {
        setValue(v);
        setTouched(true, !!error);
      }}
      onError={(err) => {
        setError(err);
        setTouched(true, false);
      }}
      {...props}
      errorMessage={currTouched && currError ? currError : undefined}
    />
  );
};
