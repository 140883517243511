import React, { Fragment } from 'react';
import { orderBy } from 'lodash-es';
import { ScrollBox } from '../../../../../common/components/ui/ScrollBox/ScrollBox';
import { CallCenterItemType } from '../../../../../api/types/CallCenterItemType';
import { CallCenterItem } from '../CallCenterItem/CallCenterItem';
import { createPhoneNumber } from '../../../../../api/utils/createPhoneNumber';
import { useWindowSize } from '../../../../../common/hooks/useWindowSize';
import s from './CallCenterList.module.scss';

export interface CallCenterListProps {
  list: CallCenterItemType[];
}

export const CallCenterList: React.FC<CallCenterListProps> = ({ list }) => {
  const { isTablet } = useWindowSize();

  return (
    <div className={s.CallCenterList}>
      {isTablet && (
        <div className={s.CallCenterList__title}>
          Справочник проверенных организаций
        </div>
      )}

      <ScrollBox
        classes={{
          content: s.CallCenterList__scrollContent
        }}
        isFading
      >
        {orderBy(
          list,
          [(item) => Boolean(item.fixed_on_top), 'name'],
          ['desc', 'asc']
        ).map((item, iItem, array) => (
          <Fragment key={iItem}>
            <CallCenterItem
              {...item}
              phone={createPhoneNumber(item.phoneNumber)}
              // cardStyled={item.fixed_on_top}
            />

            {iItem < array.length - 1 && (
              <div className={s.CallCenterList__divider} />
            )}
          </Fragment>
        ))}
      </ScrollBox>
    </div>
  );
};
