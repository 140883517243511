import React from 'react';
import { useField } from 'formik';
import { RadioGroup, RadioGroupProps } from '../../RadioGroup/RadioGroup';

export interface FormRadioGroupProps extends Omit<RadioGroupProps, 'onChange'> {
  name: string;
}

export function FormRadioGroup({ name, ...props }: FormRadioGroupProps) {
  const [field, meta, { setValue }] = useField(name);

  const { error, touched } = meta;

  return (
    <RadioGroup
      {...field}
      onChange={(value) => setValue(value)}
      {...props}
      errorMessage={touched && error ? error : undefined}
    />
  );
}
