import React from 'react';
import { LayoutContent } from '../../../../common/components/layout/LayoutContent/LayoutContent';
import { LayoutCard } from '../../../../common/components/layout/LayoutCard/LayoutCard';
import picSrc from '../../../../assets/img/content/subscription/pic.svg';
import { Button, ButtonSize } from '../../../../common/components/ui/Button';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import { useWebinarsContext } from '../../../../store/webinar/hooks/useWebinarsContext';
import { useMetricsContext } from '../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../store/metrics/types';
import { SECTION_IDS, sectionLabelsById } from '../../../../constants';
import { ScrollLink } from '../../../../common/components/ui/reactScroll/ScrollLink';
import s from './SectionSubscription.module.scss';

export const SectionSubscription = () => {
  const { isMobile } = useWindowSize();
  const { params, observeNode } = useMetricsContext();
  const { onWebinarsSubscription } = useWebinarsContext();

  return (
    <LayoutContent
      ref={(r) => r && observeNode(SECTION_IDS.subscription, r)}
      className={s.SectionSubscription}
    >
      <LayoutCard className={s.Card}>
        <div className={s.Card__grid}>
          <div className={s.Card__content}>
            <div className={s.Card__title}>Подпишитесь на&nbsp;рассылку</div>
            <div className={s.Card__text}>
              Будьте первым, кто узнает о наших новых мероприятиях
            </div>
            <div className={s.Card__actions}>
              <ScrollLink to={SECTION_IDS.subscription}>
                <Button
                  className={s.Card__subButton}
                  size={isMobile ? ButtonSize.medium : ButtonSize.xlarge}
                  onClick={() => {
                    onWebinarsSubscription();
                    params({
                      [sectionLabelsById.subscription]: {
                        Подписаться: MetricsParams.Click
                      }
                    });
                  }}
                >
                  Подписаться
                </Button>
              </ScrollLink>
            </div>
          </div>
          <div className={s.Card__pic}>
            <img
              className={s.Card__picImg}
              src={picSrc}
              alt="Полезная информация о борьбе с мошенниками"
            />
          </div>
        </div>
      </LayoutCard>
    </LayoutContent>
  );
};
