import { define } from '@proscom/ui-utils';
import { getCssVariable } from './utils/helpers/getCssVariable';
import { personalDataTermsUrl, userTermsUrl } from './config';
import { ROUTE_HOME, ROUTE_MATERIAL } from './routes/routes';

export enum Breakpoints {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop'
}

export const BREAKPOINT_VALUES = {
  [Breakpoints.mobile]: parseFloat(getCssVariable('--bp-mobile-min-width', 0)),
  [Breakpoints.tablet]: parseFloat(getCssVariable('--bp-tablet-min-width', 0)),
  [Breakpoints.desktop]: parseFloat(getCssVariable('--bp-desktop-min-width', 0))
};

export enum SECTION_IDS {
  main = 'main',
  about_project = 'about_project',
  timer = 'timer',
  webinar_cancelled = 'webinar_cancelled',
  stories = 'stories',
  webinars = 'webinars',
  subscription = 'subscription',
  webinars_past = 'webinars_past',
  tests = 'tests',
  safe_click = 'safe_click',
  materials = 'materials',
  call_center = 'call_center',
  partners = 'partners',
  agreements = 'agreements'
}

export const sectionLabelsById = define()({
  [SECTION_IDS.main]: 'Главная',
  [SECTION_IDS.about_project]: 'О проекте',
  [SECTION_IDS.timer]: '',
  [SECTION_IDS.webinar_cancelled]: '',
  [SECTION_IDS.stories]: 'Реальные истории',
  [SECTION_IDS.webinars]: 'Наши мероприятия',
  [SECTION_IDS.subscription]: 'Подпишитесь на рассылку',
  [SECTION_IDS.webinars_past]: 'Архив вебинаров',
  [SECTION_IDS.tests]: 'Проверьте себя',
  [SECTION_IDS.safe_click]: 'Безопасный клик',
  [SECTION_IDS.materials]: 'Полезная информация',
  [SECTION_IDS.call_center]: 'Полезные телефоны',
  [SECTION_IDS.partners]: '',
  [SECTION_IDS.agreements]: 'Документы'
});

export const agreementLinks = {
  userTerms: {
    href: userTermsUrl,
    label:
      'Соглашение о пользовании информационными системами и ресурсами города Москвы'
  },
  personalDataTerms: {
    href: personalDataTermsUrl,
    label: 'Политика в отношении обработки персональных данных'
  }
};

export const navLinks = {
  [ROUTE_HOME]: [
    SECTION_IDS.about_project,
    SECTION_IDS.webinars_past,
    SECTION_IDS.stories,
    SECTION_IDS.tests,
    SECTION_IDS.safe_click,
    SECTION_IDS.materials
  ].map((id) => ({
    link: id,
    label: sectionLabelsById[id] || '',
    scrollLink: true
  })),
  [ROUTE_MATERIAL]: [
    {
      link: ROUTE_HOME,
      label: 'На главную'
    }
  ]
};
