import React from 'react';
import { LayoutContent } from '../../../../common/components/layout/LayoutContent/LayoutContent';
import { useQueryLoading } from '../../../../store/api/hooks/useQueryLoading';
import { useAboutQuery } from '../../../../api/hooks/useAboutQuery';
import {
  LayoutCard,
  LayoutCardVariant
} from '../../../../common/components/layout/LayoutCard/LayoutCard';
import { MDtoHTMLContainer } from '../../../../common/components/ui/MDtoHTMLContainer/MDtoHTMLContainer';
import { formatNumberIntl } from '../../../../utils/number/intl';
import { useMetricsContext } from '../../../../store/metrics/hooks/useMetricsContext';
import { SECTION_IDS } from '../../../../constants';
import s from './SectionAbout.module.scss';

export const SectionAbout: React.FC = () => {
  const { observeNode } = useMetricsContext();
  const aboutQuery = useAboutQuery();
  const loading = useQueryLoading(aboutQuery);
  const about = aboutQuery.state.data;

  return (
    <LayoutContent
      ref={(r) => r && observeNode(SECTION_IDS.about_project, r)}
      adaptive
    >
      {loading || (
        <LayoutCard variant={LayoutCardVariant.gray} adaptive>
          <div className={s.AboutCard__inner}>
            <h2 className={s.AboutCard__title}>О проекте</h2>

            <div className={s.AboutCard__content}>
              {about?.description && (
                <MDtoHTMLContainer
                  className={s.AboutCard__description}
                  mdString={about.description}
                />
              )}
              {!!about?.stats.length && (
                <div className={s.AboutCard__stats}>
                  {about.stats.map((item, iItem) => (
                    <div key={iItem} className={s.StatItem}>
                      <h2 className={s.StatItem__value}>
                        {formatNumberIntl(+item.value) || item.value}
                      </h2>
                      <div
                        className={s.StatItem__title}
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </LayoutCard>
      )}
    </LayoutContent>
  );
};
