import React from 'react';
import clsx from 'clsx';
import { addStaticProperties, propsSplitter } from '../../../types';
import { BaseButtonProps, ButtonSize, ButtonVariant } from './types';
import s from './Button.module.scss';

const baseKeys = [
  'renderBase',
  'className',
  'classes',
  'iconLeft',
  'iconRight',
  'icon',
  'isIconButton',
  'variant',
  'size',
  'children'
] as const;

const splitProps = propsSplitter(baseKeys);

export const BaseButton = addStaticProperties(
  function BaseButton({
    renderBase,
    className,
    classes,
    variant = ButtonVariant.primary,
    size = ButtonSize.medium,
    iconLeft,
    iconRight,
    icon,
    isIconButton,
    children
  }: BaseButtonProps) {
    return renderBase({
      className: clsx(
        s.Button,
        s[`Button_${variant}`],
        s[`Button_${size}`],
        className,
        classes?.root,
        {
          [s.Button_iconButton]: isIconButton
        }
      ),
      children: (
        <>
          {iconLeft && (
            <div
              className={clsx(
                s.Button__icon,
                s.Button__iconLeft,
                s[`Button__icon_${size}`],
                classes?.iconLeft
              )}
            >
              {iconLeft}
            </div>
          )}
          <div className={clsx(classes?.content)}>
            {icon && (
              <div className={clsx(s.Button__icon, classes?.icon)}>{icon}</div>
            )}
            {children}
          </div>
          {iconRight && (
            <div
              className={clsx(
                s.Button__icon,
                s.Button__iconRight,
                s[`Button__icon_${size}`],
                classes?.iconRight
              )}
            >
              {iconRight}
            </div>
          )}
        </>
      )
    });
  },
  {
    splitProps,
    baseKeys
  }
);
