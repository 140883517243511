import React from 'react';
import { LayoutContent } from '../../../../common/components/layout/LayoutContent/LayoutContent';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../../common/components/ui/Button';
import bgSrc from '../../../../assets/img/content/tests/1.svg';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import { TestProvider } from '../../../../store/tests/TestProvider';
import { useTestContext } from '../../../../store/tests/hooks/useTestContext';
import {
  LayoutCard,
  LayoutCardVariant
} from '../../../../common/components/layout/LayoutCard/LayoutCard';
import { useMetricsContext } from '../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../store/metrics/types';
import { SECTION_IDS, sectionLabelsById } from '../../../../constants';
import s from './SectionTests.module.scss';

const Tests = () => {
  const { isMobile } = useWindowSize();

  const { params } = useMetricsContext();
  const { testCompleted, startTest } = useTestContext();

  const handleActionClick = (label: string) => {
    if (label) {
      params({
        [sectionLabelsById.tests]: {
          [label]: MetricsParams.Click
        }
      });
    }
  };

  return (
    <LayoutCard className={s.TestsBanner} variant={LayoutCardVariant.gray}>
      <div className={s.TestsBanner__grid}>
        <div className={s.TestsBanner__content}>
          <div className={s.TestsBanner__title}>Проверьте себя</div>
          <div className={s.TestsBanner__text}>
            Оцените, насколько вы готовы противостоять мошенничеству
          </div>
          <div className={s.TestsBanner__actions}>
            <Button
              className={s.TestsBanner__button}
              size={isMobile ? ButtonSize.medium : ButtonSize.xlarge}
              variant={
                testCompleted ? ButtonVariant.secondary : ButtonVariant.primary
              }
              onClick={() => {
                startTest();
                handleActionClick(
                  testCompleted ? 'Тест пройден' : 'Пройти тест'
                );
              }}
            >
              {testCompleted ? 'Тест пройден' : 'Пройти тест'}
            </Button>
          </div>
        </div>

        <div className={s.TestsBanner__pic}>
          <img
            className={s.TestsBanner__picImg}
            src={bgSrc}
            alt="Как можно противостоять мошенничеству"
          />
        </div>
      </div>
    </LayoutCard>
  );
};

export interface SectionTestsProps {}

export const SectionTests: React.FC<SectionTestsProps> = () => {
  const { observeNode } = useMetricsContext();

  return (
    <LayoutContent
      ref={(r) => r && observeNode(SECTION_IDS.tests, r)}
      className={s.SectionTests}
    >
      <TestProvider>
        <Tests />
      </TestProvider>
    </LayoutContent>
  );
};
