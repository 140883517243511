import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { DivPropsWithoutRef } from '../../../types';
import s from './LayoutCard.module.scss';

export enum LayoutCardVariant {
  default = 'default',
  gray = 'gray',
  brown = 'brown'
}

export interface LayoutCardProps extends DivPropsWithoutRef {
  className?: string;
  children?: React.ReactNode;
  variant?: LayoutCardVariant;
  showShadow?: boolean;
  adaptive?: boolean;
  style?: CSSProperties;
}

export const LayoutCard = React.forwardRef<HTMLDivElement, LayoutCardProps>(
  (
    {
      className,
      variant = LayoutCardVariant.default,
      showShadow,
      adaptive,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(s.LayoutCard, s[`LayoutCard_${variant}`], className, {
          [s.LayoutCard_withShadow]: showShadow,
          [s.LayoutCard_adaptive]: adaptive
        })}
        {...props}
      >
        {children}
      </div>
    );
  }
);
