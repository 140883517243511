import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAsyncOperation } from '@proscom/prostore-react';
import { isNil } from 'lodash-es';
import { joinNonEmpty } from '@proscom/ui-utils';
import { useSearchParam } from 'react-use';
import { ModalWebinarsSubscription } from '../../common/components/ui/Modal/ModalWebinarsSubscription/ModalWebinarsSubscription';
import { ModalWebinarLiveSignUp } from '../../common/components/ui/Modal/ModalWebinarLiveSignUp/ModalWebinarLiveSignUp';
import { useQueryLoading } from '../api/hooks/useQueryLoading';
import { useFutureWebinars } from '../../api/hooks/useFutureWebinars';
import { usePastWebinars } from '../../api/hooks/usePastWebinars';
import { ModalWebinarRemind } from '../../common/components/ui/Modal/ModalWebinarRemind/ModalWebinarRemind';
import { ModalUnsub } from '../../common/components/ui/Modal/ModalUnsub/ModalUnsub';
import { tryNumberLike } from '../../utils/number/numberLike';
import { WebinarsContext } from './WebinarsContext';
import { useGetWebinar } from './hooks/useGetWebinar';

export interface WebinarProviderProps {
  children?: React.ReactNode;
}

export const WebinarProvider: React.FC<WebinarProviderProps> = ({
  children
}) => {
  const [webinarLiveSignUpModalOpen, setWebinarLiveSignUpModalOpen] =
    useState(false);
  const [webinarsSubscriptionModalOpen, setWebinarsSubscriptionModalOpen] =
    useState(false);
  const [webinarRemindModalOpen, setWebinarRemindModalOpen] = useState(false);
  const [selectedWebinarId, setSelectedWebinarId] = useState<number>();
  const [remindWebinarId, setRemindWebinarId] = useState<number>();
  const [liveSignUpWebinarId, setLiveSignUpWebinarId] = useState<number>();
  const [closestWebinarId, setClosestWebinarId] = useState<number>();

  const webSubscriptionUnsubSuccess =
    useSearchParam('subscription_unsub') === 'success';
  const webRemindUnsubSuccess = useSearchParam('remind_unsub') === 'success';
  const webLiveSignUpUnsubSuccess = useSearchParam('live_unsub') === 'success';
  const unsubWebinarId = useSearchParam('webinarid');
  const [unsubModalOpen, setUnsubModalOpen] = useState(
    webSubscriptionUnsubSuccess ||
      webRemindUnsubSuccess ||
      webLiveSignUpUnsubSuccess
  );

  const futureWebinarsQuery = useFutureWebinars();
  const futureWebinars = futureWebinarsQuery.state.data;
  const futureWebinarsLoading = useQueryLoading(futureWebinarsQuery);
  const { run: refetchFutureWebinars } = useAsyncOperation(() => {
    return futureWebinarsQuery.load();
  });

  const pastWebinarsQuery = usePastWebinars();
  const pastWebinars = useMemo(() => {
    if (!pastWebinarsQuery.state.data) return null;
    return pastWebinarsQuery.state.data.map((w) => ({ ...w, isPast: true }));
  }, [pastWebinarsQuery.state.data]);
  const pastWebinarsLoading = useQueryLoading(pastWebinarsQuery);

  const {
    webinar: closestWebinar,
    loading: closestWebinarLoading,
    webinarQuery: closestWebinarQuery
  } = useGetWebinar(closestWebinarId);

  useQueryLoading(closestWebinarQuery);

  const onWebinarLiveSignUp = useCallback(
    (id?: number) => {
      setLiveSignUpWebinarId(id || closestWebinarId);
      setWebinarLiveSignUpModalOpen(true);
    },
    [closestWebinarId]
  );
  const onWebinarsSubscription = useCallback(() => {
    setWebinarsSubscriptionModalOpen(true);
  }, []);
  const onWebinarRemind = useCallback((id: number) => {
    setRemindWebinarId(id);
    setWebinarRemindModalOpen(true);
  }, []);

  useEffect(() => {
    if (futureWebinars?.length) {
      setClosestWebinarId(futureWebinars[0]?.id);
    }
  }, [futureWebinars]);

  const unsubModalInfo = useMemo(() => {
    const unsubWebId = tryNumberLike(unsubWebinarId, null, true);
    const unsubWebinar = (() => {
      if (
        isNil(unsubWebId) ||
        (!futureWebinars?.length && !pastWebinars?.length)
      ) {
        return undefined;
      }
      return [...(futureWebinars || []), ...(pastWebinars || [])].find(
        (w) => w.id === unsubWebId
      );
    })();

    if (webSubscriptionUnsubSuccess) {
      return {
        title: 'Нам очень жаль, что вы решили отписаться от нашей рассылки',
        description:
          'Вы всегда можете возобновить подписку на нашем сайте в разделе «Информационная рассылка»'
      };
    }
    if (webRemindUnsubSuccess) {
      return {
        title: 'Отписка от события',
        description: joinNonEmpty(
          [
            'Вы успешно отписались от события',
            unsubWebinar && `"${unsubWebinar.name}"`
          ],
          ' '
        )
      };
    }
    if (webLiveSignUpUnsubSuccess) {
      return {
        title: 'Отмена регистрации на семинар',
        description: joinNonEmpty(
          [
            'Регистрация на семинар',
            unsubWebinar && `"${unsubWebinar.name}"`,
            'успешно отменена'
          ],
          ' '
        )
      };
    }
  }, [
    webSubscriptionUnsubSuccess,
    webRemindUnsubSuccess,
    webLiveSignUpUnsubSuccess,
    unsubWebinarId,
    futureWebinars,
    pastWebinars
  ]);

  const webinarsLoading =
    futureWebinarsLoading || pastWebinarsLoading || closestWebinarLoading;

  return (
    <WebinarsContext.Provider
      value={{
        closestWebinar,
        futureWebinars,
        pastWebinars,
        selectedWebinarId,
        setSelectedWebinarId,
        webinarsLoading,
        onWebinarLiveSignUp,
        onWebinarsSubscription,
        onWebinarRemind
      }}
    >
      {children}
      <ModalWebinarsSubscription
        isOpen={webinarsSubscriptionModalOpen}
        onClose={() => setWebinarsSubscriptionModalOpen(false)}
      />
      <ModalWebinarLiveSignUp
        isOpen={webinarLiveSignUpModalOpen}
        webinarId={liveSignUpWebinarId}
        onSignedUp={refetchFutureWebinars}
        onSignUpFailed={refetchFutureWebinars}
        onClose={() => setWebinarLiveSignUpModalOpen(false)}
        onClosed={() => setLiveSignUpWebinarId(undefined)}
      />
      <ModalWebinarRemind
        webinarId={remindWebinarId}
        isOpen={webinarRemindModalOpen}
        onClose={() => setWebinarRemindModalOpen(false)}
        onClosed={() => setRemindWebinarId(undefined)}
      />
      <ModalUnsub
        isOpen={unsubModalOpen}
        onClose={() => setUnsubModalOpen(false)}
        {...unsubModalInfo}
      />
    </WebinarsContext.Provider>
  );
};
