import React, { useCallback, useEffect, useMemo } from 'react';
import { Form, Formik } from 'formik';
import clsx from 'clsx';
import { Modal, ModalProps } from '../Modal';
import { Button, ButtonSize, ButtonVariant } from '../../Button';
import { testQuestionValidationSchema } from '../../../../../store/validationSchemas';
import {
  TestFormValues,
  TestUtils
} from '../../../../../store/tests/TestUtils';
import { FormRadioGroup } from '../../form/FormRadioGroup/FormRadioGroup';
import {
  TestQuestionType,
  TestResultType
} from '../../../../../api/types/TestType';
import { RadioItemProps } from '../../RadioGroup/RadioGroup';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { ReactComponent as IconCorrect } from '../../../../../assets/img/icons/i-check.svg';
import { ReactComponent as IconIncorrect } from '../../../../../assets/img/icons/i-close.svg';
import { ReactComponent as IconInfo } from '../../../../../assets/img/icons/i-info-letter.svg';
import { Icon } from '../../Icon/Icon';
import { useMetricsContext } from '../../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../../store/metrics/types';
import { sectionLabelsById } from '../../../../../constants';
import { MDtoHTMLContainer } from '../../MDtoHTMLContainer/MDtoHTMLContainer';
import s from './ModalTest.module.scss';

export interface ModalTestProps
  extends Pick<ModalProps, 'isOpen' | 'onClose' | 'onClosed'> {
  stepIndex: number;
  question: TestQuestionType | null;
  questionsAmount: number;
  answer?: number;
  testCompleted: boolean;
  testResults: TestResultType[];
  onGoNext: (id: number) => void;
  onGoPrev: () => void;
  onSubscribe: () => void;
  onRestart: () => void;
}

export const ModalTest: React.FC<ModalTestProps> = ({
  stepIndex,
  question,
  questionsAmount,
  answer,
  testCompleted,
  testResults,
  onGoNext,
  onGoPrev,
  onSubscribe,
  onRestart,
  onClose,
  ...props
}) => {
  const { isMobile } = useWindowSize();
  const { params } = useMetricsContext();

  const modalOpen = props.isOpen;
  const isFirstStep = stepIndex === 0;

  const handleActionClick = useCallback(
    (label: string) => {
      if (!label) return;

      if (!testCompleted) {
        params({
          [sectionLabelsById.tests]: {
            Тест: {
              [stepIndex + 1]: {
                [label]: MetricsParams.Click
              }
            }
          }
        });
      } else {
        params({
          [sectionLabelsById.tests]: {
            Тест: {
              'Итог теста': {
                Кнопки: {
                  [label]: MetricsParams.Click
                }
              }
            }
          }
        });
      }
    },
    [params, stepIndex, testCompleted]
  );

  const handleFormSubmit = useCallback(
    (data, { resetForm }) => {
      onGoNext(+data.answer);
      resetForm();
      handleActionClick('Ответить');
    },
    [onGoNext, handleActionClick]
  );

  const answers: RadioItemProps[] = useMemo(() => {
    if (!question?.answers?.length) return [];
    return question.answers.map((answer) => ({
      label: answer.text,
      name: answer.id.toString()
    }));
  }, [question]);

  const correctAnswersAmount = useMemo(() => {
    return testResults.filter((r) => r.answer?.isCorrectAnswer).length;
  }, [testResults]);

  const initialValues: TestFormValues = useMemo(() => {
    return {
      answer: (answer || '').toString()
    };
  }, [answer]);

  const resultsTitle = useMemo(() => {
    if (!testCompleted || !testResults?.length) return '';
    return TestUtils.getResultsTitle(testResults);
  }, [testResults, testCompleted]);

  useEffect(() => {
    if (modalOpen) {
      if (!testCompleted) {
        params({
          [sectionLabelsById.tests]: {
            Тест: {
              [stepIndex + 1]: MetricsParams.View
            }
          }
        });
      } else {
        params({
          [sectionLabelsById.tests]: {
            Тест: {
              'Итог теста': {
                'Просмотр результатов': {
                  [correctAnswersAmount]: MetricsParams.View
                }
              }
            }
          }
        });
      }
    }
  }, [correctAnswersAmount, modalOpen, params, stepIndex, testCompleted]);

  const handleClose = () => {
    onClose?.();
    handleActionClick('Закрыть');
  };

  const handleGoPrev = () => {
    onGoPrev();
    handleActionClick('Назад');
  };

  const questionTitle = testCompleted
    ? resultsTitle
    : `Вопрос ${stepIndex + 1}/${questionsAmount}`;

  return (
    <Modal
      className={s.ModalTest}
      title={questionTitle}
      onClose={handleClose}
      {...props}
    >
      {() => (
        <>
          <div className={s.ModalTest__content}>
            {testCompleted ? (
              <>
                <div className={s.ModalTestResults__title}>
                  Правильных ответов: {correctAnswersAmount}/
                  {testResults.length}
                </div>
                <div className={s.ModalTestResults__items}>
                  {testResults.map((result, iResult) => (
                    <div
                      key={iResult}
                      className={clsx(s.ModalTestResult, {
                        [s.ModalTestResult_correct]:
                          result.answer?.isCorrectAnswer,
                        [s.ModalTestResult_incorrect]:
                          !result.answer?.isCorrectAnswer
                      })}
                    >
                      <div className={s.ModalTestResult__question}>
                        {result.question}
                      </div>
                      {result.answer && (
                        <>
                          <div className={s.ModalTestResult__answer}>
                            <Icon
                              className={s.ModalTestResult__answerIcon}
                              icon={
                                result.answer.isCorrectAnswer ? (
                                  <IconCorrect />
                                ) : (
                                  <IconIncorrect />
                                )
                              }
                              boxSize={'auto'}
                            />
                            {result.answer.text}
                          </div>
                          {result.answer.note && (
                            <div className={s.ModalTestResult__note}>
                              <Icon
                                className={s.ModalTestResult__noteIcon}
                                icon={<IconInfo />}
                                boxSize={'auto'}
                              />
                              <MDtoHTMLContainer
                                mdString={result.answer.note}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
                <div className={s.ModalTestResults__text}>
                  Приходите на вебинар, чтобы узнать больше о том, как не
                  попасться на уловки мошенников
                </div>
                <div className={s.ModalTestResults__actions}>
                  <Button
                    className={s.ModalTestResults__action}
                    size={isMobile ? ButtonSize.medium : ButtonSize.xlarge}
                    variant={ButtonVariant.ghost}
                    onClick={() => {
                      onRestart();
                      handleActionClick('Пройти тест заново');
                    }}
                  >
                    Пройти тест заново
                  </Button>
                  <Button
                    className={s.ModalTestResults__action}
                    size={isMobile ? ButtonSize.medium : ButtonSize.xlarge}
                    onClick={() => {
                      onSubscribe();
                      handleActionClick('Подписаться на рассылку');
                    }}
                  >
                    Подписаться на рассылку
                  </Button>
                </div>
              </>
            ) : (
              <Formik
                initialValues={initialValues}
                validationSchema={testQuestionValidationSchema}
                onSubmit={handleFormSubmit}
                enableReinitialize
              >
                {() => (
                  <Form className={s.Form}>
                    <div className={clsx(s.Form__line, s.Form__question)}>
                      {question?.question}
                    </div>

                    <div className={s.Form__line}>
                      <FormRadioGroup
                        name={'answer'}
                        items={answers}
                        vertical
                      />
                    </div>

                    <div className={s.Form__actions}>
                      {!isFirstStep && (
                        <Button
                          className={s.Form__action}
                          size={
                            isMobile ? ButtonSize.medium : ButtonSize.xlarge
                          }
                          variant={ButtonVariant.secondary}
                          onClick={handleGoPrev}
                        >
                          Назад
                        </Button>
                      )}
                      <Button
                        className={clsx(s.Form__action, s.Form__action_submit)}
                        size={isMobile ? ButtonSize.medium : ButtonSize.xlarge}
                        type={'submit'}
                      >
                        Ответить
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};
