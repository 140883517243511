import React, { useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import { useContextAxiosClient } from '@proscom/prostore-axios-react';
import { useAsyncOperation } from '@proscom/prostore-react';
import { Modal, ModalProps } from '../Modal';
import { Button, ButtonSize } from '../../Button';
import { FormTextField } from '../../form/FormTextField/FormTextField';
import { TextFieldSize } from '../../TextField/TextField';
import { FormCheckbox } from '../../form/FormCheckbox/FormCheckbox';
import { TextLink } from '../../TextLink/TextLink';
import {
  WebinarLiveSignUpFormValues,
  WebinarUtils
} from '../../../../../store/webinar/WebinarUtils';
import { webinarLiveSignUpValidationSchema } from '../../../../../store/validationSchemas';
import { Icon } from '../../Icon/Icon';
import { ReactComponent as IconMarker } from '../../../../../assets/img/icons/i-map-marker.svg';
import { useMetricsContext } from '../../../../../store/metrics/hooks/useMetricsContext';
import { MetricsGoals } from '../../../../../store/metrics/types';
import { useGetWebinar } from '../../../../../store/webinar/hooks/useGetWebinar';
import { Loader } from '../../Loader/Loader';
import { ResultAlert } from '../../ResultAlert/ResultAlert';
import { DateLabel } from '../../DateLabel/DateLabel';
import { agreementLinks } from '../../../../../constants';
import { FormTextArea } from '../../form/FormTextArea/FormTextArea';
import { TextAreaSize } from '../../TextField/TextArea';
import { parseApiError } from '../../../../../api/utils/errors';
import s from './ModalWebinarLiveSignUp.module.scss';

export interface ModalWebinarLiveSignUpProps
  extends Pick<ModalProps, 'isOpen' | 'onClose' | 'onClosed'> {
  webinarId?: number;
  onSignedUp: () => Promise<unknown>;
  onSignUpFailed: () => Promise<unknown>;
}

const initialValues: WebinarLiveSignUpFormValues = {
  name: '',
  surname: '',
  patronymic: '',
  birthdate: '',
  email: '',
  phone: '',
  question: '',
  noPatronymic: false,
  terms: false
};

export const ModalWebinarLiveSignUp: React.FC<ModalWebinarLiveSignUpProps> = ({
  webinarId,
  onSignedUp,
  onSignUpFailed,
  ...props
}) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const { reachGoal } = useMetricsContext();

  const {
    webinar,
    loading: webinarLoading,
    checkAvailablePlaces,
    checkAvailablePlacesLoading
  } = useGetWebinar(webinarId);

  const availablePlaces = WebinarUtils.countAvailablePlaces(webinar);

  const client = useContextAxiosClient();
  const webinarLiveSignUpMutation = useAsyncOperation((data) => {
    return client.post('/webinar-live-sign-ups', data);
  });
  const { run, loading } = webinarLiveSignUpMutation;

  const handleWebinarSignUp = useCallback(
    async (data) => {
      if (!webinar || webinar.is_canceled) return;

      try {
        if (!(await checkAvailablePlaces())) {
          setError('Ошибка записи на себинар. Закончились места для записи.');
          await onSignUpFailed();
          return;
        }

        const input = WebinarUtils.liveSignUpFromForm(data, webinar.id);

        // if (WebinarUtils.checkUserLiveSignUp(input, webinar)) {
        //   setError('Ошибка записи на вебинар. Такой человек уже записан.');
        //   return;
        // }

        const result = await run({
          data: input
        });

        if (result.status !== 200) {
          throw new Error(`Error: ${result.status} - webinar live sign up`);
        }

        setSuccess(true);
        setError('');
        reachGoal(MetricsGoals.OfflineWebinar);
        await onSignedUp();
      } catch (err) {
        setError(
          parseApiError(err, 'Ошибка записи на семинар. Попробуйте еще раз.')
        );
        console.error(err);
        await onSignUpFailed();
      }
    },
    [checkAvailablePlaces, reachGoal, run, webinar, onSignedUp, onSignUpFailed]
  );

  const handleClosed = useCallback(() => {
    setError('');
    setSuccess(false);
  }, []);

  const webinarName = webinar?.name;
  const webinarAddress = webinar?.live_address;
  const webinarCanceled = webinar?.is_canceled;

  const submitDisabled =
    loading || checkAvailablePlacesLoading || !availablePlaces;

  return (
    <Modal
      className={s.ModalWebinarLiveSignUp}
      title={webinarName ? `«${webinarName}»` : 'Запись на семинар'}
      onClosed={handleClosed}
      {...props}
    >
      {({ close }) =>
        webinarLoading ? (
          <div className={s.ModalWebinarLiveSignUp__loaderBox}>
            <Loader />
          </div>
        ) : (
          <>
            {!webinarCanceled && !success && webinarAddress && (
              <div className={s.Address}>
                <Icon
                  iconClassName={s.Address__icon}
                  icon={<IconMarker />}
                  boxSize={'auto'}
                />
                {webinarAddress}
                <DateLabel date={webinar?.date_start} />
              </div>
            )}

            {success ? (
              <ResultAlert className={s.ResultAlert}>
                Вы успешно зарегистрировались на очный семинар. <br />
                Собираясь на мероприятие, не забудьте взять с собой{' '}
                <span>ПАСПОРТ!</span>
              </ResultAlert>
            ) : (
              <div className={s.ModalWebinarLiveSignUp__hint}>
                {webinarCanceled ? (
                  <>
                    К сожалению, мероприятие отменено. Приносим свои извинения
                    за доставленные неудобства.
                  </>
                ) : (
                  <>
                    Заполните форму, и&nbsp;за&nbsp;несколько дней
                    до&nbsp;мероприятия мы свяжемся с&nbsp;вами, и&nbsp;подробно
                    расскажем как добраться до&nbsp;места проведения семинара.
                    <br />
                    <br />
                    <span>Важно!</span> Если вам не&nbsp;подходит формат очного
                    посещения, вы всегда можете посмотреть мероприятие
                    в&nbsp;формате вебинара. В&nbsp;таком случае просто закройте
                    это окно.
                  </>
                )}
              </div>
            )}

            {!webinarCanceled && (
              <div className={s.ModalWebinarLiveSignUp__content}>
                {success ? (
                  <Button
                    className={s.Success__submitBtn}
                    size={ButtonSize.xlarge}
                    onClick={close}
                  >
                    Хорошо
                  </Button>
                ) : (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={webinarLiveSignUpValidationSchema}
                    validateOnChange={false}
                    onSubmit={handleWebinarSignUp}
                  >
                    {({ values }) => (
                      <Form className={s.Form}>
                        <div className={s.Form__line}>
                          <FormTextField
                            name={'surname'}
                            label={'Фамилия *'}
                            placeholder={'Введите фамилию'}
                            size={TextFieldSize.xlarge}
                            disabled={loading}
                          />
                        </div>
                        <div className={s.Form__line}>
                          <FormTextField
                            name={'name'}
                            label={'Имя *'}
                            placeholder={'Введите имя'}
                            size={TextFieldSize.xlarge}
                            disabled={loading}
                          />
                        </div>
                        <div className={s.Form__line}>
                          <FormTextField
                            name={'patronymic'}
                            label={'Отчество *'}
                            placeholder={'Введите отчество'}
                            size={TextFieldSize.xlarge}
                            disabled={loading || values.noPatronymic}
                          />
                          <FormCheckbox
                            classes={{
                              root: s.Form__NoPatronymicCheckbox,
                              label: s.Form__NoPatronymicCheckboxLabel
                            }}
                            name={'noPatronymic'}
                            label={'У меня нет отчества'}
                          />
                        </div>
                        <div className={s.Form__line}>
                          <FormTextField
                            name={'birthdate'}
                            label={'Дата рождения *'}
                            placeholder={'Введите дату рождения'}
                            size={TextFieldSize.xlarge}
                            maskProps={{
                              mask: '99.99.9999'
                            }}
                            disabled={loading}
                          />
                        </div>
                        <div className={s.Form__line}>
                          <FormTextField
                            name={'email'}
                            label={'E-mail *'}
                            placeholder={'Введите E-mail'}
                            size={TextFieldSize.xlarge}
                            disabled={loading}
                          />
                        </div>
                        <div className={s.Form__line}>
                          <FormTextField
                            name={'phone'}
                            label={'Номер телефона *'}
                            placeholder={'Введите номер телефона'}
                            size={TextFieldSize.xlarge}
                            maskProps={{
                              mask: '+7 (999) 999-99-99'
                            }}
                            disabled={loading}
                          />
                        </div>
                        <div className={s.Form__line}>
                          <FormTextArea
                            name={'question'}
                            label={'Задать вопрос спикерам'}
                            placeholder={'Введите текст вашего вопроса'}
                            size={TextAreaSize.xlarge}
                            disabled={loading}
                            minRows={3}
                            maxRows={6}
                            maxLength={WebinarUtils.signUpQuestionMaxLength}
                            autoSize
                          />
                        </div>
                        <div className={s.Form__line}>
                          <div className={s.Form__termsCheckbox}>
                            <FormCheckbox
                              className={s.Form__termsCheckboxInput}
                              name={'terms'}
                            />
                            <div className={s.Form__termsCheckboxText}>
                              Нажимая на кнопку, я соглашаюсь на{' '}
                              <TextLink
                                className={s.Form__termsCheckboxLink}
                                href={agreementLinks.personalDataTerms.href}
                              >
                                обработку персональных данных
                              </TextLink>{' '}
                              и с{' '}
                              <TextLink
                                className={s.Form__termsCheckboxLink}
                                href={agreementLinks.userTerms.href}
                              >
                                правилами пользования Платформой
                              </TextLink>
                            </div>
                          </div>
                        </div>

                        {error && <div className={s.Form__error}>{error}</div>}

                        <div className={s.Form__actions}>
                          <Button
                            className={s.Form__submitBtn}
                            size={ButtonSize.xlarge}
                            type={'submit'}
                            disabled={submitDisabled}
                          >
                            {!availablePlaces
                              ? 'Свободных мест не осталось :('
                              : 'Записаться на очный семинар'}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            )}
          </>
        )
      }
    </Modal>
  );
};
