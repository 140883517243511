import React from 'react';
import ReactScrollToTopButton from 'react-scroll-to-top';
import { Icon } from '../../Icon/Icon';
import { ReactComponent as IconArr } from '../../../../../assets/img/icons/i-arrow-up.svg';
import { useMetricsContext } from '../../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../../store/metrics/types';
import s from './ScrollToTopButton.module.scss';

export const ScrollToTopButton = () => {
  const { params } = useMetricsContext();

  const handleClick = () => {
    params({
      Наверх: MetricsParams.Click
    });
  };

  return (
    <div className={s.ScrollToTopButtonWrapper} onClick={handleClick}>
      <ReactScrollToTopButton
        className={s.ScrollToTopButton}
        component={
          <Icon
            className={s.ScrollToTopButton__icon}
            icon={<IconArr />}
            boxSize={'auto'}
          />
        }
        top={300}
        smooth
      />
    </div>
  );
};
