import { useAxiosQuery } from '@proscom/prostore-axios-react';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { QueryResultType } from '../types/QueryResultType';
import { MainScreenType } from '../types/MainScreenType';

const queryOptions = {
  query: {
    url: '/main'
  },
  mapVariables: AxiosQueryStore.mapVariablesParams
};

export function useMainScreenQuery(): QueryResultType<MainScreenType> {
  return useAxiosQuery({
    queryOptions,
    variables: {
      populate: 'image'
    }
  });
}
