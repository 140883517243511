import React, { useMemo } from 'react';
import { isAfter, startOfDay } from 'date-fns';
import { LayoutContent } from '../../../layout/LayoutContent/LayoutContent';
import { Button, ButtonSize, ButtonVariant } from '../../Button';
import { ScrollLink } from '../../reactScroll/ScrollLink';
import { SECTION_IDS } from '../../../../../constants';
import { useWebinarsContext } from '../../../../../store/webinar/hooks/useWebinarsContext';
import { formatDate } from '../../../../hooks/useFormatDate';
import { useMetricsContext } from '../../../../../store/metrics/hooks/useMetricsContext';
import warnPicSrc from './assets/warn.svg';
import s from './BannerWebinarCancelled.module.scss';

const todayStart = startOfDay(new Date());

export const BannerWebinarCancelled = () => {
  const { futureWebinars } = useWebinarsContext();
  const { observeNode } = useMetricsContext();

  const webinar = useMemo(() => {
    if (!futureWebinars?.length) return undefined;
    return futureWebinars.find((w) => {
      return w.is_canceled && isAfter(new Date(w.date_start), todayStart);
    });
  }, [futureWebinars]);

  if (!webinar) return null;

  return (
    <LayoutContent
      ref={(r) => r && observeNode(SECTION_IDS.webinar_cancelled, r)}
      className={s.BannerWebinarCancelled}
      narrow
    >
      <div className={s.BannerWebinarCancelled__grid}>
        <div className={s.BannerWebinarCancelled__content}>
          <div className={s.BannerWebinarCancelled__date}>
            {formatDate(webinar.date_start, 'dd MMM, HH:mm')}
          </div>
          <h2 className={s.BannerWebinarCancelled__title}>
            ВНИМАНИЕ! ОТМЕНА СОБЫТИЯ
          </h2>
          <div className={s.BannerWebinarCancelled__text}>
            Семинар «{webinar.name}» отменяется.
            <br />
            Приносим свои извинения
          </div>
          <div className={s.BannerWebinarCancelled__actions}>
            <ScrollLink
              className={s.BannerWebinarCancelled__otherLink}
              to={SECTION_IDS.webinars}
            >
              <Button
                className={s.BannerWebinarCancelled__otherButton}
                size={ButtonSize.xlarge}
                variant={ButtonVariant.whiteSecondary}
              >
                Посмотреть другие
              </Button>
            </ScrollLink>
          </div>
        </div>
        <div className={s.BannerWebinarCancelled__pic}>
          <img
            className={s.BannerWebinarCancelled__picImg}
            src={warnPicSrc}
            alt=""
          />
        </div>
      </div>
    </LayoutContent>
  );
};
