import parsePhoneNumber from 'libphonenumber-js';
import { trimString } from '../../utils/string/trimString';

export type ShareStoryFormValues = {
  name: string;
  surname: string;
  patronymic?: string;
  email: string;
  phone?: string;
  message: string;
  attachments?: File[];
  noPatronymic: boolean;
  terms: boolean;
};

export type ShareStoryRequestValues = {
  name: string;
  surname: string;
  patronymic?: string;
  email: string;
  phone: string;
  message: string;
  attachments?: File[];
};

export class StoryUtils {
  static nameMaxLength = 100;
  static emailMaxLength = 100;
  static phoneMaxLength = 30;
  static messageMinLength = 20;
  static messageMaxLength = 4096;
  static attachmentsMaxAmount = 3;
  static attachmentsMaxSize = 10 * 1024 * 1024; // 10 MB

  static fromForm(data: ShareStoryFormValues): ShareStoryRequestValues {
    const parsedPhone = data.phone ? parsePhoneNumber(data.phone) : null;

    return {
      name: trimString(data.name),
      surname: trimString(data.surname),
      patronymic: data.noPatronymic ? undefined : trimString(data.patronymic),
      email: trimString(data.email).toLowerCase(),
      phone: parsedPhone?.number || '',
      message: data.message || '',
      attachments: data.attachments
    };
  }
}
