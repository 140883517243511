import React from 'react';
import { WebinarType } from '../../api/types/WebinarType';

export type WebinarsContextType = {
  closestWebinar: WebinarType | null;
  futureWebinars: WebinarType[] | null;
  pastWebinars: WebinarType[] | null;
  webinarsLoading: boolean;
  selectedWebinarId?: number;
  setSelectedWebinarId: (id?: number) => void;
  onWebinarLiveSignUp: (id?: number) => void;
  onWebinarsSubscription: () => void;
  onWebinarRemind: (id: number) => void;
};

export const WebinarsContext = React.createContext<WebinarsContextType>({
  futureWebinars: null,
  pastWebinars: null,
  closestWebinar: null,
  webinarsLoading: false,
  selectedWebinarId: undefined,
  setSelectedWebinarId: () => {},
  onWebinarLiveSignUp: () => {},
  onWebinarsSubscription: () => {},
  onWebinarRemind: () => {}
});
