import React from 'react';
import { LayoutContent } from '../../../../common/components/layout/LayoutContent/LayoutContent';
import { BaseLink } from '../../../../common/components/ui/BaseLink/BaseLink';
import {
  agreementLinks,
  SECTION_IDS,
  sectionLabelsById
} from '../../../../constants';
import { useMetricsContext } from '../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../store/metrics/types';
import s from './SectionAgreements.module.scss';

export const SectionAgreements: React.FC = () => {
  const { params, observeNode } = useMetricsContext();

  const handleActionClick = (label: string) => {
    if (label) {
      params({
        [sectionLabelsById.agreements]: {
          [label]: MetricsParams.Click
        }
      });
    }
  };

  return (
    <LayoutContent
      ref={(r) => r && observeNode(SECTION_IDS.agreements, r)}
      className={s.SectionAgreements}
      innerClassName={s.SectionAgreements__inner}
      narrow
    >
      <div className={s.SectionAgreements__links}>
        <BaseLink
          className={s.SectionAgreements__link}
          href={agreementLinks.userTerms.href}
          onClick={() => handleActionClick('Соглашение о пользовании')}
        >
          {agreementLinks.userTerms.label}
        </BaseLink>
        <BaseLink
          className={s.SectionAgreements__link}
          href={agreementLinks.personalDataTerms.href}
          onClick={() =>
            handleActionClick('Политика в отношении обработки данных')
          }
        >
          {agreementLinks.personalDataTerms.label}
        </BaseLink>
      </div>
    </LayoutContent>
  );
};
