import { createLoadUrl } from '../utils/createLoadUrl';
import { FileType, PopulatedType } from '../types/strapi.types';

export function createFileUrl(
  file: PopulatedType<FileType> | undefined
): string {
  if (!file?.data?.attributes) return '';

  return createLoadUrl(file.data.attributes.url);
}

export function useFileUrl(image: PopulatedType<FileType> | undefined): string {
  return createFileUrl(image);
}
