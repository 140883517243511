import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';
import { DefaultLayout } from '../common/components/layout/DefaultLayout/DefaultLayout';
import { navLinks } from '../constants';
import { IndexPage } from './index/IndexPage';
import { MaterialPage } from './material/MaterialPage';
import { ROUTE_HOME, ROUTE_MATERIAL } from './routes';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route
        Component={() => <DefaultLayout navLinks={navLinks[ROUTE_HOME]} />}
      >
        <Route path={ROUTE_HOME} Component={IndexPage} />
      </Route>

      <Route
        Component={() => <DefaultLayout navLinks={navLinks[ROUTE_MATERIAL]} />}
      >
        <Route path={ROUTE_MATERIAL} Component={MaterialPage} />
      </Route>

      {/* not found */}
      <Route
        path={'*'}
        Component={() => <Navigate to={ROUTE_HOME} replace />}
      />
    </Route>
  ),
  {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true
    }
  }
);
