import React from 'react';
import { PageLayout } from '../../common/components/layout/PageLayout/PageLayout';
import { ResultAlert } from '../../common/components/ui/ResultAlert/ResultAlert';
import s from './InDevPage.module.scss';

export function InDevPage() {
  return (
    <PageLayout className={s.InDevPage} centeredContent>
      <ResultAlert showIcon={false}>
        Сайт обновляется, приносим свои извинения за неудобства.
      </ResultAlert>
    </PageLayout>
  );
}
