import { useAxiosQuery } from '@proscom/prostore-axios-react';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { QueryResultType } from '../types/QueryResultType';
import { TestQuestionType } from '../types/TestType';

const queryOptions = {
  query: {
    url: '/tests'
  },
  mapVariables: AxiosQueryStore.mapVariablesParams
};

export function useTestQuestions(): QueryResultType<TestQuestionType[]> {
  return useAxiosQuery({
    queryOptions,
    variables: {
      populate: '*',
      sort: ['createdAt:desc']
    }
  });
}
