import { isNil } from 'lodash-es';

/**
 * isPositive - позволяет понять, является ли переданное число положительным
 *
 * @param value: number | undefined
 * @return boolean
 */
export function isPositive<V extends number>(value?: V): boolean {
  if (isNil(value)) return false;
  return value > 0;
}

/**
 * isNegative - позволяет понять, является ли переданное число отрицательным
 *
 * @param value: number
 * @return boolean
 */
export function isNegative<V extends number>(value?: V): boolean {
  if (isNil(value)) return false;
  return value < 0;
}

/**
 * getNumberSign - возвращает знак переданного числа (+ или -), либо пустую строку, если передано не число или ноль
 *
 * i.e.
 *
 * getNumberSign(123): "+"
 * getNumberSign(-123): "-"
 * getNumberSign(0): ""
 *
 * @param value: number | undefined
 * @return string
 */
export function getNumberSign<V extends number>(value?: V): string {
  if (isNil(value)) return '';
  return isPositive(value) ? '+' : isNegative(value) ? '-' : '';
}

/**
 * addNumberSign - возвращает переданное число со знаком (+ или -), либо value, если передано не число
 *
 * @param value: number | undefined
 * @return string | typeof value | undefined
 */
export function addNumberSign<V extends number>(
  value?: V
): string | V | undefined {
  if (isNil(value)) return value;

  if (isPositive(value)) {
    return `+ ${value}`;
  }

  if (isNegative(value)) {
    return `- ${value * -1}`;
  }

  return value;
}
