import React, { useState } from 'react';
import { isNil } from 'lodash-es';
import { LayoutContent } from '../../../../common/components/layout/LayoutContent/LayoutContent';
import { useStories } from '../../../../api/hooks/useStories';
import { useQueryLoading } from '../../../../store/api/hooks/useQueryLoading';
import { Button, ButtonVariant } from '../../../../common/components/ui/Button';
import {
  LayoutCard,
  LayoutCardVariant
} from '../../../../common/components/layout/LayoutCard/LayoutCard';
import { ModalShareStory } from '../../../../common/components/ui/Modal/ModalShareStory/ModalShareStory';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import { useMetricsContext } from '../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../store/metrics/types';
import { SECTION_IDS, sectionLabelsById } from '../../../../constants';
import { StoryCard } from './StoryCard/StoryCard';
import { ModalStory } from './ModalStory/ModalStory';
import { Story } from './ModalStory/Story';
import s from './SectionStories.module.scss';

const showItemsAmount = 4;

export const SectionStories: React.FC = () => {
  const { isDesktop } = useWindowSize();
  const { params, observeNode } = useMetricsContext();
  const [showMore, setShowMore] = useState(false);
  const [modalShareActive, setModalShareActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [activeStoryId, setActiveStoryId] = useState<number | null>(null);

  const storiesQuery = useStories();
  const loading = useQueryLoading(storiesQuery);
  const stories = storiesQuery.state.data;

  const activeStory = !isNil(activeStoryId)
    ? stories?.find((s) => s.id === activeStoryId)
    : undefined;

  const selectStory = (id: number | null) => {
    if (isNil(id)) {
      setActiveStoryId(null);
      setModalActive(false);
    } else {
      setActiveStoryId(id);
      setModalActive(true);

      const name = stories?.find((s) => s.id === id)?.title;
      if (name) {
        params({
          [sectionLabelsById.stories]: {
            История: {
              [name]: MetricsParams.Click
            }
          }
        });
      }
    }
  };

  const handleActionClick = (label: string) => {
    if (label) {
      params({
        [sectionLabelsById.stories]: {
          Кнопки: {
            [label]: MetricsParams.Click
          }
        }
      });
    }
  };

  const handleStoryActionClick = (label: string) => {
    const name = activeStory?.title;
    if (name && label) {
      params({
        [sectionLabelsById.stories]: {
          История: {
            [name]: {
              Модалка: {
                [label]: MetricsParams.Click
              }
            }
          }
        }
      });
    }
  };

  const handleShowMore = () => {
    const more = !showMore;
    setShowMore(more);

    const moreLabel = more ? 'Показать еще' : 'Свернуть';
    handleActionClick(moreLabel);
  };

  const actions = (
    <div className={s.SectionStories__actions}>
      <div className={s.SectionStories__actionsHint}>
        Тоже имели дело <br />с мошенниками?
      </div>
      <Button
        className={s.SectionStories__actionsButton}
        onClick={() => {
          setModalShareActive(true);
          handleActionClick('Поделиться историей');
        }}
      >
        Поделитесь историей
      </Button>
    </div>
  );

  const needShowMore = (stories?.length || 0) > showItemsAmount;
  const showAll = showMore || !needShowMore;
  const itemsToShow = showAll ? stories : stories?.slice(0, showItemsAmount);

  return (
    <LayoutContent
      ref={(r) => r && observeNode(SECTION_IDS.stories, r)}
      adaptive
    >
      <LayoutCard className={s.Card} variant={LayoutCardVariant.brown} adaptive>
        <div className={s.Card__content}>
          <div className={s.SectionStories__head}>
            <h2 className={s.SectionStories__title}>
              Разбираем реальные истории
            </h2>
            {isDesktop && actions}
          </div>

          {loading || (
            <div className={s.SectionStories__list}>
              {itemsToShow?.map((story) => (
                <StoryCard
                  key={story.id}
                  {...story}
                  onClick={() => selectStory(story.id)}
                />
              ))}
            </div>
          )}

          {needShowMore && (
            <div className={s.SectionStories__more}>
              <Button variant={ButtonVariant.ghost} onClick={handleShowMore}>
                {showMore ? 'Свернуть' : 'Показать ещё'}
              </Button>
            </div>
          )}

          {!isDesktop && actions}
        </div>
      </LayoutCard>

      <ModalStory
        isOpen={modalActive && !!activeStory}
        onClose={() => {
          setModalActive(false);
          handleStoryActionClick('Закрыть');
        }}
        onClosed={() => selectStory(null)}
      >
        {({ close, offsetRef }) =>
          activeStory && (
            <Story
              onGoBack={close}
              onAction={handleStoryActionClick}
              modalOffsetRef={offsetRef}
              {...activeStory}
            />
          )
        }
      </ModalStory>

      <ModalShareStory
        isOpen={modalShareActive}
        onClose={() => setModalShareActive(false)}
      />
    </LayoutContent>
  );
};
