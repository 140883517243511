import React from 'react';
import { GameResultType, GameTargetEnum } from '../../api/types/GameType';

export type GameContextType = {
  availableTargets: GameTargetEnum[];
  selectedTarget: GameTargetEnum | null;
  selectTarget: (target: GameTargetEnum | null) => void;
  restart: () => void;
  answers: Map<
    GameTargetEnum,
    { questionId: number; answerId: number; isCorrectAnswer: boolean }
  >;
  gameState: GameResultType;
  gameCompleted: boolean;
};

export const GameContext = React.createContext<GameContextType>({
  availableTargets: [],
  selectedTarget: null,
  selectTarget: () => {},
  restart: () => {},
  answers: new Map(),
  gameState: { completeAmount: 0, correctAmount: 0, totalAmount: 0 },
  gameCompleted: false
});

export const gameMetricsLabel = 'Безопасный клик';
