import React, { MutableRefObject, useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { Portal } from 'react-portal';
import { useClickOutside, useTimeoutRef } from '@proscom/ui-react';
import { useUpdateEffect } from 'react-use';
import { ReactComponent as IconClose } from '../../../../assets/img/icons/i-close.svg';
import { ButtonIcon, ButtonIconSize, ButtonIconVariant } from '../Button';
import { usePreventBodyScroll } from '../../../hooks/usePreventBodyScroll';
import { getCssVariable } from '../../../../utils/helpers/getCssVariable';
import s from './Modal.module.scss';

export const modalTransitionDelay = getCssVariable('--transition-modal-ms');

export interface ModalClasses {
  offset?: string;
  offsetContent?: string;
  overlay?: string;
  title?: string;
  content?: string;
}

export interface ModalProps {
  className?: string;
  classes?: ModalClasses;
  title?: string;
  offsetRef?: MutableRefObject<HTMLDivElement | null>;
  isOpen: boolean;
  onClose?: () => void;
  onClosed?: () => void;
  children?: ({
    close
  }: {
    close: ModalProps['onClose'];
    offsetRef?: ModalProps['offsetRef'];
  }) => React.ReactNode;
}

interface ModalCompProps extends ModalProps {
  isClosed: boolean;
  modalRef: MutableRefObject<HTMLDivElement | null>;
}

const ModalComponent: React.FC<ModalCompProps> = ({
  modalRef,
  offsetRef,
  className,
  classes,
  isOpen,
  isClosed,
  title,
  onClose,
  children
}) => {
  return (
    <div
      ref={modalRef}
      className={clsx(s.Modal, className, {
        [s.Modal_open]: isOpen
      })}
    >
      <ButtonIcon
        classes={{
          root: s.Modal__closeButton,
          icon: s.Modal__closeButtonIcon
        }}
        icon={<IconClose />}
        size={ButtonIconSize.xlarge}
        variant={ButtonIconVariant.inverted}
        onClick={onClose}
      />

      {title && (
        <h3 className={clsx(s.Modal__title, classes?.title)}>{title}</h3>
      )}

      {!isClosed && (
        <div className={clsx(s.Modal__content, classes?.content)}>
          {children?.({ close: onClose, offsetRef })}
        </div>
      )}
    </div>
  );
};

export const Modal = ({
  className,
  classes,
  title,
  offsetRef: propsOffsetRef,
  isOpen,
  onClose,
  onClosed,
  children
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const offsetRef = useRef<HTMLDivElement | null>(
    propsOffsetRef?.current ?? null
  );
  const animationTO = useTimeoutRef();
  const [isClosed, setClosed] = useState(!isOpen);

  /**
   * UPD: вроде как исправлено добавлением сброса animationTO при открытии модалки
   *
   * Видимость контента модалки зависит от isClosed.
   * isClosed становится true, когда отработает анимация закрытия модалки,
   * тогда контент модалки отмонтируется из ДОМа.
   *
   * Проблема в том, что при активации модалки сначала идет рендер модалки,
   * потом эффект с setClosed(false), а потом уже монтируется контент модалки.
   *
   * Из-за двойного рендера (модалка -> useEffect -> контент модалки)
   * иногда сначала рендерится пустая модалка и с задержкой появляется контент
   * (Визуально замечено в сафари, в хроме проблема не замечается)
   */
  useUpdateEffect(() => {
    if (isOpen) {
      animationTO.clear();
      setClosed(false);
    }
  }, [isOpen, animationTO]);

  const resetScroll = useCallback(() => {
    const offset = offsetRef.current;
    if (offset) {
      offset.scrollTo({
        top: 0
      });
    }
  }, []);

  const handleOnClosed = useCallback(() => {
    resetScroll();
    onClosed?.();
    setClosed(true);
  }, [resetScroll, onClosed]);

  /**
   * triggers closed-handler after closing-animation ends
   */
  useUpdateEffect(() => {
    if (!isOpen) {
      animationTO.set(() => {
        handleOnClosed();
      }, +modalTransitionDelay || 0);
    }
  }, [isOpen, animationTO, handleOnClosed]);

  const handleClickOutside = useCallback(() => {
    if (isOpen && onClose) {
      onClose();
    }
  }, [isOpen, onClose]);

  // useLockBodyScroll(isOpen);
  usePreventBodyScroll(isOpen);

  useClickOutside(modalRef, handleClickOutside);

  return (
    <Portal>
      <div
        className={clsx(s.ModalOverlay, classes?.overlay, {
          [s.ModalOverlay_open]: isOpen
        })}
      />

      <div
        className={clsx(s.ModalOffset, classes?.offset, {
          [s.ModalOffset_open]: isOpen
        })}
        ref={propsOffsetRef || offsetRef}
      >
        <div className={clsx(s.ModalOffset__content, classes?.offsetContent)}>
          <ModalComponent
            modalRef={modalRef}
            offsetRef={propsOffsetRef || offsetRef}
            className={className}
            classes={classes}
            title={title}
            isOpen={isOpen}
            isClosed={isClosed}
            onClose={onClose}
          >
            {children}
          </ModalComponent>
        </div>
      </div>
    </Portal>
  );
};
