import { useAxiosQuery } from '@proscom/prostore-axios-react';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { QueryResultType } from '../types/QueryResultType';
import { ScammersSayType } from '../types/ScammersSayType';

const queryOptions = {
  query: {
    url: '/scammers-messages'
  },
  mapVariables: AxiosQueryStore.mapVariablesParams
};

export function useScammersSayQuery(): QueryResultType<ScammersSayType[]> {
  return useAxiosQuery({
    queryOptions
  });
}
