import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { isNil, orderBy } from 'lodash-es';
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars-2';
import { LayoutContent } from '../../../../common/components/layout/LayoutContent/LayoutContent';
import { useWebinarsContext } from '../../../../store/webinar/hooks/useWebinarsContext';
import {
  LayoutCard,
  LayoutCardVariant
} from '../../../../common/components/layout/LayoutCard/LayoutCard';
import { Tabs } from '../../../../common/components/ui/Tabs/Tabs';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import { getCssVariable } from '../../../../utils/helpers/getCssVariable';
import { useMetricsContext } from '../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../store/metrics/types';
import { SECTION_IDS, sectionLabelsById } from '../../../../constants';
import {
  CalendarDataType,
  WebinarsCalendar
} from './WebinarsCalendar/WebinarsCalendar';
import { WebinarsList } from './WebinarsList/WebinarsList';
import { WebinarNodeType } from './WebinarsList/WebinarCard';
import s from './SectionWebinars.module.scss';

const selectionDelay = 2000;
document.documentElement.style.setProperty(
  '--webinar-selection-delay',
  `${selectionDelay}ms`
);

const headerHeight = parseFloat(getCssVariable('--header-height', 0));

const currentYear = new Date().getFullYear();

enum TabsEnum {
  Future,
  Past
}

const tabs = [
  {
    label: 'Предстоящие',
    value: TabsEnum.Future
  },
  {
    label: 'Прошедшие',
    value: TabsEnum.Past
  }
];

export interface SectionWebinarsProps {}

export const SectionWebinars: React.FC<SectionWebinarsProps> = () => {
  const {
    futureWebinars,
    pastWebinars,
    webinarsLoading,
    selectedWebinarId,
    setSelectedWebinarId,
    onWebinarLiveSignUp,
    onWebinarRemind,
    onWebinarsSubscription
  } = useWebinarsContext();
  const { params, observeNode } = useMetricsContext();
  const { isDesktop } = useWindowSize();
  const [activeTab, setActiveTab] = useState(TabsEnum.Future);
  const scrollRef = useRef<ScrollbarProps['ref']>();
  const selectionTO = useRef<NodeJS.Timeout>();
  const [webinarNodes, setWebinarNodes] = useState<WebinarNodeType[]>([]);

  const pastWebs = useMemo(() => {
    if (!pastWebinars?.length) return [];
    return pastWebinars.filter(
      (w) => new Date(w.date_start).getFullYear() === currentYear
    );
  }, [pastWebinars]);

  const futureWebs = useMemo(() => {
    if (!futureWebinars?.length) return [];
    return futureWebinars.filter(
      (w) => new Date(w.date_start).getFullYear() === currentYear
    );
  }, [futureWebinars]);

  const selectedWebinar = isNil(selectedWebinarId)
    ? undefined
    : futureWebs.find((w) => w.id === selectedWebinarId) ||
      pastWebs.find((w) => w.id === selectedWebinarId);

  const handleNodeReady = useCallback(({ id, node }: WebinarNodeType) => {
    if (!node) return;

    setWebinarNodes((prevNodes) => {
      return [...prevNodes.filter((n) => n.id !== id), { id, node }];
    });
  }, []);

  const resetSelection = useCallback(() => {
    setSelectedWebinarId(undefined);
  }, [setSelectedWebinarId]);

  const resetNodes = useCallback(() => {
    setWebinarNodes([]);
  }, []);

  const scrollToWebinar = useCallback(
    (id: WebinarNodeType['id']) => {
      const selectedWebinarNode = webinarNodes.find((w) => w.id === id)?.node;

      if (isDesktop) {
        const scroll = scrollRef.current as Scrollbars | undefined;
        if (scroll && selectedWebinarNode) {
          //@ts-ignore
          (scroll.view as HTMLDivElement).scrollTo({
            top: selectedWebinarNode.offsetTop - 40,
            behavior: 'smooth'
          });
        }
      } else {
        if (selectedWebinarNode) {
          window.scrollTo({
            top:
              window.scrollY +
              selectedWebinarNode.getBoundingClientRect().top -
              headerHeight -
              16,
            behavior: 'smooth'
          });
        }
      }
    },
    [webinarNodes, isDesktop]
  );

  useEffect(() => {
    if (selectedWebinar) {
      const nextTab = selectedWebinar.isPast ? TabsEnum.Past : TabsEnum.Future;
      if (nextTab !== activeTab) {
        resetNodes();
      }
      setActiveTab(nextTab);
    }
  }, [selectedWebinar, activeTab, resetNodes]);

  useEffect(() => {
    clearTimeout(selectionTO.current);
    const selectedWebinarNode = selectedWebinar
      ? webinarNodes.find((w) => w.id === selectedWebinar.id)
      : undefined;
    if (selectedWebinarNode) {
      scrollToWebinar(selectedWebinarNode.id);
    }
    selectionTO.current = setTimeout(resetSelection, selectionDelay);
  }, [resetSelection, scrollToWebinar, selectedWebinar, webinarNodes]);

  const handleTabChange = (tabId: TabsEnum) => {
    resetSelection();
    resetNodes();
    setActiveTab(tabId);

    const tabLabel = tabs.find((t) => t.value === tabId)?.label;
    if (tabLabel) {
      params({
        [sectionLabelsById.webinars]: {
          Вкладка: {
            [tabLabel]: MetricsParams.Click
          }
        }
      });
    }

    const scroll = scrollRef.current as Scrollbars | undefined;
    if (scroll) {
      scroll.scrollToTop();
    }
  };

  const calendarData: CalendarDataType[] = useMemo(() => {
    if (!pastWebs?.length && !futureWebs?.length) return [];
    return orderBy(
      [...(pastWebs || []), ...(futureWebs || [])].map((w, iW) => ({
        id: w.id,
        date: w.date_start,
        isOnlineOnly: !w.live_available_places,
        isPast: iW < (pastWebs?.length || 0)
      })),
      ['date'],
      ['asc']
    );
  }, [pastWebs, futureWebs]);

  const calendarComp = (
    <div className={s.SectionWebinars__calendar}>
      <WebinarsCalendar
        data={calendarData}
        onWebinarSelected={(id) => {
          setSelectedWebinarId(id);
          params({
            [sectionLabelsById.webinars]: {
              Календарь: MetricsParams.Click
            }
          });
        }}
      />
    </div>
  );

  const tabsComp = (
    <Tabs
      className={s.SectionWebinars__tabs}
      tabs={tabs}
      value={activeTab}
      onChange={handleTabChange}
    />
  );

  const headComp = (
    <div className={s.SectionWebinars__head}>
      <h2 className={s.SectionWebinars__title}>Наши мероприятия</h2>
      {isDesktop && tabsComp}
    </div>
  );

  const showPastWebinars = activeTab === TabsEnum.Past;
  const webinarsToShow = showPastWebinars ? pastWebs : futureWebs;

  return (
    <LayoutContent
      ref={(r) => r && observeNode(SECTION_IDS.webinars, r)}
      className={s.SectionWebinars}
      adaptive
    >
      {!isDesktop && (
        <div className={s.SectionWebinars__mobileTop}>
          <div className={s.SectionWebinars__mobileTopContent}>
            {headComp}
            {calendarComp}
          </div>
        </div>
      )}

      <LayoutCard className={s.Card} variant={LayoutCardVariant.brown} adaptive>
        <div className={s.Card__content}>
          {isDesktop && headComp}

          {webinarsLoading || (
            <LayoutCard className={s.SectionWebinars__card}>
              <div className={s.SectionWebinars__grid}>
                {!isDesktop && tabsComp}
                {isDesktop && calendarComp}
                <WebinarsList
                  ref={scrollRef}
                  webinars={webinarsToShow || []}
                  isPast={showPastWebinars}
                  selectedWebinarId={selectedWebinar?.id}
                  onRefReady={handleNodeReady}
                  onWebinarLiveSignUp={onWebinarLiveSignUp}
                  onWebinarRemind={onWebinarRemind}
                  onWebinarsSubscription={onWebinarsSubscription}
                  onScrollToWebinar={scrollToWebinar}
                />
              </div>
            </LayoutCard>
          )}
        </div>
      </LayoutCard>
    </LayoutContent>
  );
};
