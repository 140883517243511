import { AxiosError } from 'axios';

// TODO: проставить остальные коды ошибок
export const apiErrorMessages = {
  // VALIDATION_ERROR: '', // пропускаем
  FILE_SIZE_LIMIT_EXCEEDED: 'Превышен лимит размера файлов',
  WEBINAR_SUBSCRIPTION_EXISTS:
    'Подписка на рассылку с таким email-адресом уже оформлена',
  WEBINAR_REMIND_EXISTS:
    'Подписка на событие с таким email-адресом уже оформлена',
  WEBINAR_LIVE_SIGN_UP_EXISTS:
    'Регистрация на семинар с таким email-адресом уже оформлена'
};

export type ApiErrorType = AxiosError<{
  error: { details?: { code?: string } };
}>;

export function parseApiError(
  error?: any,
  defaultMessage: string = ''
): string {
  const errorDetailsCode = (error as ApiErrorType)?.response?.data?.error
    ?.details?.code;
  return apiErrorMessages[errorDetailsCode || ''] || defaultMessage;
}
