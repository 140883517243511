import { isNumberLike } from './numberLike';
import { getNumberSign } from './helpers';

export type FormatIntlOptionsType = Intl.NumberFormatOptions & {
  locale?: string;
  addSign?: boolean;
  formatPercent?: boolean;
  formatFilesize?: boolean;
};

const defaultLocale = 'Ru-ru';

const fileSizeUnits = ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ'];

/**
 * formatNumberIntl - дополненный вариант преобразования числа в формате INTL
 * - проверяет переданный аргумент value на принадлежность в числовым значениям
 * - дополнительно принимает options, включающий стандартные опции Intl.NumberFormatOptions
 * + строку локализации, возможность добавить к числу знак, форматирование числа в процент (с добавлением знака % в конце)
 *
 * i.e.
 *
 * formatNumberIntl(123): "123"
 * formatNumberIntl(1.23, { locale: "RU-ru" }): "1,23"
 * formatNumberIntl(123, { addSign: true }): "+ 123"
 * formatNumberIntl(123000, { addSign: true }): "+ 123 000"
 * formatNumberIntl(1.23, { formatPercent: true }): "123%"
 *
 * formatNumberIntl(undefined): ""
 *
 *
 * @param value: number
 * @param options: Intl.NumberFormatOptions & {
 *     locale?: string;
 *     addSign?: boolean;
 *     formatPercent?: boolean;
 * } | undefined
 * @return string
 */

export function formatNumberIntl(
  value: number,
  options: FormatIntlOptionsType = {}
): string {
  if (!isNumberLike(value)) return '';

  const { locale, addSign, formatPercent, formatFilesize, ...restOptions } =
    options;

  const intl = Intl.NumberFormat(locale || defaultLocale, restOptions);

  function format(val: number): string {
    return addSign
      ? `${getNumberSign(val)} ${intl.format(Math.abs(val))}`
      : intl.format(val);
  }

  if (formatFilesize) {
    let unitIndex = 0;
    while (value >= 1024 && unitIndex < fileSizeUnits.length - 1) {
      value /= 1024;
      unitIndex++;
    }
    const fileSizeUnit = fileSizeUnits[unitIndex];
    return [format(value), fileSizeUnit].filter(Boolean).join(' ');
  }

  if (formatPercent) {
    return format(value * 100) + '%';
  }

  return format(value);
}
