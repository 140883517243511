import React from 'react';
import { Button, ButtonSize } from '../../../../../common/components/ui/Button';
import { WebinarsContextType } from '../../../../../store/webinar/WebinarsContext';
import { useWindowSize } from '../../../../../common/hooks/useWindowSize';
import s from './WebinarsListPlaceholder.module.scss';

export interface WebinarsListPlaceholderProps {
  onWebinarsSubscription: WebinarsContextType['onWebinarsSubscription'];
}

export const WebinarsListPlaceholder = ({
  onWebinarsSubscription
}: WebinarsListPlaceholderProps) => {
  const { isMobile } = useWindowSize();

  return (
    <div className={s.WebinarsListPlaceholder}>
      <div className={s.WebinarsListPlaceholder__content}>
        <div className={s.WebinarsListPlaceholder__text}>
          Мы работаем над&nbsp;наполнением календаря мероприятий, чтобы
          предоставить вам еще больше полезной информации. Подпишитесь
          на&nbsp;нашу рассылку и&nbsp;оставайтесь в&nbsp;курсе всех обновлений.
        </div>
        <div className={s.WebinarsListPlaceholder__actions}>
          <Button
            size={isMobile ? ButtonSize.medium : ButtonSize.xlarge}
            onClick={onWebinarsSubscription}
          >
            Подписаться
          </Button>
        </div>
      </div>
    </div>
  );
};
