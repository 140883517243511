import React from 'react';
import clsx from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';
import { HelpText, HelpTextType } from '../HelpText/HelpText';
import s from './TextField.module.scss';

export interface TextAreaClasses {
  root?: string;
  label?: string;
  input?: string;
  helperText?: string;
}

export enum TextAreaSize {
  medium = 'medium',
  xlarge = 'xlarge'
}

export interface TextAreaProps
  extends Omit<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    'onChange' | 'size' | 'type' | 'style' | 'ref' | 'value'
  > {
  inputRef?: React.ForwardedRef<HTMLTextAreaElement>;
  classes?: TextAreaClasses;
  label?: string;
  value?: string;
  size?: TextAreaSize;
  errorMessage?: string;
  hintMessage?: string;
  autoSize?: boolean;
  maxRows?: number;
  minRows?: number;
  onChange?: (value: string, e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea: React.FC<TextAreaProps> = ({
  className,
  classes,
  label,
  value = '',
  size = TextAreaSize.medium,
  errorMessage,
  hintMessage,
  inputRef,
  autoSize,
  maxRows,
  minRows,
  maxLength,
  disabled,
  onChange,
  ...inputProps
}) => {
  const hasValue = !!value;
  const showSymbolsCounter = !!maxLength && !disabled && hasValue;

  const _inputProps = {
    ref: inputRef,
    className: clsx(
      s.TextField__input,
      s.TextField__input_textarea,
      s[`TextField__input_textarea_${size}`],
      {
        [s.TextField__input_autoSize]: autoSize,
        [s.TextField__input_error]: !!errorMessage
      },
      classes?.input
    ),
    maxLength,
    onChange: (e) => {
      onChange?.(e.target.value, e);
    },
    value,
    disabled,
    ...inputProps
  };

  return (
    <label className={clsx(s.TextField, className, classes?.root)}>
      {label && (
        <div className={clsx(s.TextField__label, classes?.label)}>{label}</div>
      )}
      <div className={s.TextField__inputWrapper}>
        {autoSize ? (
          <TextareaAutosize
            {..._inputProps}
            minRows={minRows || _inputProps.rows || 1}
            maxRows={maxRows}
            // cacheMeasurements
          />
        ) : (
          <textarea rows={_inputProps.rows || 1} {...inputProps} />
        )}
        {showSymbolsCounter && (
          <div
            className={clsx(s.TextArea__symbolsCounter, {
              [s.TextArea__symbolsCounter_limitReaching]:
                ((value?.length || 0) / maxLength) * 100 >= 75,
              [s.TextArea__symbolsCounter_limitReached]:
                value?.length === maxLength
            })}
          >
            {value?.length || 0}/{maxLength}
          </div>
        )}
      </div>
      <HelpText
        className={classes?.helperText}
        text={errorMessage || hintMessage}
        type={errorMessage ? HelpTextType.error : HelpTextType.hint}
      />
    </label>
  );
};
