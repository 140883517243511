import parsePhoneNumber from 'libphonenumber-js';
import { tryIsoDate } from '../../utils/date/date';
import { WebinarLiveSignUp, WebinarType } from '../../api/types/WebinarType';
import { trimString } from '../../utils/string/trimString';

export type WebinarLiveSignUpFormValues = WebinarLiveSignUp['attributes'] & {
  question: string;
  noPatronymic: boolean;
  terms: boolean;
};
export type WebinarLiveSignUpRequestValues = {
  name: string;
  surname: string;
  patronymic?: string;
  birthdate: Date;
  email: string;
  phone: string;
  webinar: number;
  question: string;
};

export type WebinarsSubscriptionFormValues = {
  name: string;
  surname: string;
  patronymic?: string;
  email: string;
  phone?: string;
  noPatronymic: boolean;
  terms: boolean;
};
export type WebinarsSubscriptionRequestValues = {
  name: string;
  surname: string;
  patronymic?: string;
  email: string;
  phone?: string;
};

export type WebinarRemindFormValues = {
  name: string;
  surname: string;
  patronymic?: string;
  email: string;
  terms: boolean;
  noPatronymic: boolean;
  webinar: number;
};
export type WebinarRemindRequestValues = {
  name: string;
  surname: string;
  patronymic?: string;
  email: string;
  webinar: number;
};

export class WebinarUtils {
  static nameMaxLength = 128;
  static signUpQuestionMaxLength = 512;

  static liveSignUpFromForm(
    data: WebinarLiveSignUpFormValues,
    webinarId: number
  ): WebinarLiveSignUpRequestValues {
    const parsedPhone = parsePhoneNumber(data.phone);

    const birthDateArr = data.birthdate.split('.');
    const birthdate = tryIsoDate(birthDateArr.reverse().join('-'));

    return {
      name: trimString(data.name),
      surname: trimString(data.surname),
      patronymic: data.noPatronymic ? undefined : trimString(data.patronymic),
      birthdate: birthdate as Date,
      email: trimString(data.email).toLowerCase(),
      phone: parsedPhone?.number || '',
      webinar: webinarId,
      question: data.question || ''
    };
  }

  static subscriptionFromForm(
    data: WebinarsSubscriptionFormValues
  ): WebinarsSubscriptionRequestValues {
    const parsedPhone = data.phone ? parsePhoneNumber(data.phone) : null;

    return {
      name: trimString(data.name),
      surname: trimString(data.surname),
      patronymic: data.noPatronymic ? undefined : trimString(data.patronymic),
      email: trimString(data.email).toLowerCase(),
      phone: parsedPhone?.number || ''
    };
  }

  static remindFromForm(
    data: WebinarRemindFormValues,
    webinarId: number
  ): WebinarRemindRequestValues {
    return {
      name: trimString(data.name),
      surname: trimString(data.surname),
      patronymic: data.noPatronymic ? undefined : trimString(data.patronymic),
      email: trimString(data.email).toLowerCase(),
      webinar: webinarId
    };
  }

  static countAvailablePlaces(webinar?: WebinarType | null): number | null {
    if (!webinar?.live_available_places) return null;
    const places = webinar.live_available_places || 0;
    const signUps = webinar.webinar_live_sign_ups_count || 0;
    return Math.max(places - signUps, 0);
  }
}
