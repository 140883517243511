import { useAxiosQuery } from '@proscom/prostore-axios-react';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { QueryResultType } from '../types/QueryResultType';
import { DocType } from '../types/DocumentType';

const queryOptions = {
  query: {
    url: '/documents'
  },
  mapVariables: AxiosQueryStore.mapVariablesParams
};

export function useDocuments(slug?: string): QueryResultType<DocType[]> {
  return useAxiosQuery({
    queryOptions,
    variables: {
      fields: slug
        ? ['name', 'content', 'updatedAt']
        : ['name', 'slug', 'updatedAt'],
      populate: 'image,file',
      sort: ['priority:desc'],
      filters: slug
        ? {
            slug: {
              $eq: slug
            }
          }
        : undefined
    }
  });
}
