import React from 'react';

export type UIContextType = {
  wasAutoScrolled: boolean;
  setWasAutoScrolled: () => void;
};

export const UIContext = React.createContext<UIContextType>({
  wasAutoScrolled: false,
  setWasAutoScrolled: () => {}
});
