import { startOfDay, formatISO } from 'date-fns';
import { WebinarType } from '../types/WebinarType';
import { QueryResultType } from '../types/QueryResultType';
import { QueryVarsType } from '../types/QueryVarsType';
import { useWebinars } from './useWebinars';

const vars: QueryVarsType = {
  filters: {
    date_start: {
      $gt: formatISO(startOfDay(new Date()))
    }
  },
  sort: ['date_start:asc'],
  populate: ['webinar_live_sign_ups']
};

export function useFutureWebinars(): QueryResultType<WebinarType[]> {
  return useWebinars(vars);
}
