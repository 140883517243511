export enum MetricsGoals {
  OnlineWebinar = 'online-webinar',
  OfflineWebinar = 'offline-webinar',
  ClickWebinar = 'click-webinar'
}

export enum MetricsParams {
  Click = 'click',
  View = 'view',
  Scroll = 'scroll'
}
