import React from 'react';
import clsx from 'clsx';
import { DivPropsWithoutRef } from '../../../types';
import s from './LayoutContent.module.scss';

export interface LayoutContentProps extends DivPropsWithoutRef {
  className?: string;
  innerClassName?: string;
  narrow?: boolean;
  adaptive?: boolean;
  children?: React.ReactNode;
}

export const LayoutContent = React.forwardRef<
  HTMLDivElement,
  LayoutContentProps
>(
  (
    { className, innerClassName, narrow, adaptive, children, ...props },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(s.LayoutContent, className, {
          [s.LayoutContent_adaptive]: adaptive
        })}
        {...props}
      >
        <div
          className={clsx(s.LayoutContent__inner, innerClassName, {
            [s.LayoutContent__inner_narrow]: narrow
          })}
        >
          {children}
        </div>
      </div>
    );
  }
);
