import { MetricsContextType } from './MetricsContext';

export class MetricsUtils {
  // @ts-ignore: ya-counter
  static yaCounter = window.yaCounter91805708;

  static reachGoal: MetricsContextType['reachGoal'] = (goal, params) => {
    try {
      MetricsUtils.yaCounter?.reachGoal(goal, params);
    } catch (err) {
      console.error(err);
    }
  };

  static params: MetricsContextType['params'] = (params) => {
    try {
      MetricsUtils.yaCounter?.params(params);
    } catch (err) {
      console.error(err);
    }
  };
}
