import { useEffect } from 'react';

export function usePreventBodyScroll(isLocked) {
  useEffect(() => {
    document.documentElement.classList.toggle('scroll-lock', isLocked);
    return () => {
      document.documentElement.classList.remove('scroll-lock');
    };
  }, [isLocked]);
}
