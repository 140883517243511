import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LayoutContent } from '../../../../common/components/layout/LayoutContent/LayoutContent';
import { useWebinarsContext } from '../../../../store/webinar/hooks/useWebinarsContext';
import {
  LayoutCard,
  LayoutCardVariant
} from '../../../../common/components/layout/LayoutCard/LayoutCard';
import { Button, ButtonVariant } from '../../../../common/components/ui/Button';
import { WebinarType } from '../../../../api/types/WebinarType';
import { Tabs, TabType } from '../../../../common/components/ui/Tabs/Tabs';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import { useMetricsContext } from '../../../../store/metrics/hooks/useMetricsContext';
import { MetricsParams } from '../../../../store/metrics/types';
import { SECTION_IDS, sectionLabelsById } from '../../../../constants';
import { WebinarArchiveCard } from './WebinarArchiveCard/WebinarArchiveCard';
import s from './SectionWebinarsArchive.module.scss';

const showItemsAmount = 3;
const currentYear = new Date().getFullYear();

export const SectionWebinarsArchive = () => {
  const { isDesktop } = useWindowSize();
  const { pastWebinars, webinarsLoading } = useWebinarsContext();
  const { params, observeNode } = useMetricsContext();
  const [activeTab, setActiveTab] = useState(-1);
  const [showMore, setShowMore] = useState(false);

  const webinars = useMemo(() => {
    if (!pastWebinars?.length) return [];
    return pastWebinars.filter((w) => !w.is_canceled);
  }, [pastWebinars]);

  const webinarsAmount = webinars?.length || 0;

  const groupsByYear = useMemo(() => {
    if (!webinars?.length) return [];

    const groups: { year: number; webinars: WebinarType[] }[] = [
      {
        year: currentYear,
        webinars: []
      },
      {
        year: currentYear - 1,
        webinars: []
      }
    ];

    webinars.forEach((w) => {
      const webYear = new Date(w.date_start).getFullYear();

      groups.forEach((g, iG) => {
        if (webYear === g.year) {
          groups[iG].webinars.push(w);
        }
      });
    });

    return groups;
  }, [webinars]);

  const tabs = useMemo(() => {
    return [
      ...groupsByYear
        .filter((g) => !!g.webinars.length)
        .map((g) => ({
          label: isDesktop
            ? `За ${g.year} год (${g.webinars.length})`
            : `${g.year} (${g.webinars.length})`,
          value: g.year
        })),
      {
        label: isDesktop
          ? `За всё время (${webinarsAmount})`
          : `Все (${webinarsAmount})`,
        value: -1
      }
    ] as TabType<number>[];
  }, [groupsByYear, webinarsAmount, isDesktop]);

  const handleTabChange = useCallback(
    (value: number) => {
      setActiveTab(value);

      const tabLabel = value === -1 ? 'За все время' : `За ${value} год`;
      params({
        [sectionLabelsById.webinars_past]: {
          Вкладка: {
            [tabLabel]: MetricsParams.Click
          }
        }
      });
    },
    [params]
  );

  useEffect(() => {
    setShowMore(false);
  }, [activeTab]);

  useEffect(() => {
    setActiveTab(tabs[0].value);
  }, [tabs]);

  const handleShowMore = () => {
    const more = !showMore;
    setShowMore(more);

    const moreLabel = more ? 'Показать еще' : 'Свернуть';
    params({
      [sectionLabelsById.webinars_past]: {
        Кнопки: {
          [moreLabel]: MetricsParams.Click
        }
      }
    });
  };

  const webinarsByTab =
    activeTab === -1
      ? webinars
      : groupsByYear.find((g) => g.year === activeTab)?.webinars;
  const needShowMore = (webinarsByTab?.length || 0) > showItemsAmount;
  const showAll = showMore || !needShowMore;
  const itemsToShow = showAll
    ? webinarsByTab
    : webinarsByTab?.slice(0, showItemsAmount);

  return (
    <LayoutContent
      ref={(r) => r && observeNode(SECTION_IDS.webinars_past, r)}
      adaptive
    >
      <LayoutCard className={s.Card} variant={LayoutCardVariant.gray} adaptive>
        <div className={s.Card__content}>
          <div className={s.SectionWebinarsArchive__head}>
            <h2 className={s.SectionWebinarsArchive__title}>Архив вебинаров</h2>
            <Tabs
              className={s.SectionWebinarsArchive__tabs}
              tabs={tabs}
              value={activeTab}
              onChange={handleTabChange}
            />
          </div>

          {webinarsLoading || (
            <div className={s.SectionWebinarsArchive__list}>
              {itemsToShow?.map((item) => (
                <WebinarArchiveCard key={item.id} {...item} />
              ))}
            </div>
          )}

          {needShowMore && (
            <div className={s.SectionWebinarsArchive__more}>
              <Button variant={ButtonVariant.ghost} onClick={handleShowMore}>
                {showMore ? 'Свернуть' : 'Показать ещё'}
              </Button>
            </div>
          )}
        </div>
      </LayoutCard>
    </LayoutContent>
  );
};
