import { AxiosClientsManager } from '@proscom/prostore-axios-react';
import { createDefaultClient, createSimpleClient } from './store/client';

const simpleInstance = createSimpleClient();
// const authStore = new AuthStore({
//     localStorage,
//     client: simpleInstance
// });
// authStore.registerListener();

const defaultInstance = createDefaultClient();

export const axiosContext = new AxiosClientsManager({
  default: defaultInstance,
  simple: simpleInstance
});
