import React, { useEffect, useRef } from 'react';
import { Element, scroller } from 'react-scroll';
import { useHash } from 'react-use';
import { SECTION_IDS } from '../../../../constants';
import { reactScrollDefaults } from '../../../constants';
import { useApi } from '../../../../store/api/hooks/useApi';
import { useUI } from '../../../../store/ui/hooks/useUI';

export interface ScrollElementProps {
  name: SECTION_IDS;
  scrollToElementOnLoad?: boolean;
  children?: React.ReactNode;
}

export const ScrollElement = ({
  name,
  scrollToElementOnLoad = true,
  children
}: ScrollElementProps) => {
  const { allLoaded } = useApi();
  const { wasAutoScrolled, setWasAutoScrolled } = useUI();
  const [hash] = useHash();
  const hashRef = useRef(hash);

  useEffect(() => {
    if (scrollToElementOnLoad) {
      const hashName = (hashRef.current || '').substring(1);
      if (!wasAutoScrolled && allLoaded && hashName === name) {
        setWasAutoScrolled();
        scroller.scrollTo(name, reactScrollDefaults);
      }
    }
  }, [
    scrollToElementOnLoad,
    name,
    allLoaded,
    wasAutoScrolled,
    setWasAutoScrolled
  ]);

  return <Element name={name}>{children}</Element>;
};
