import React from 'react';
import clsx from 'clsx';
import { BaseLink, BaseLinkProps } from '../BaseLink/BaseLink';
import s from './TextLink.module.scss';

export enum TextLinkVariant {
  black = 'black',
  red = 'red'
}

export interface TextLinkProps extends BaseLinkProps {
  variant?: TextLinkVariant;
  noUnderline?: boolean;
  noHover?: boolean;
  showArrow?: boolean;
  isExternal?: boolean;
}

export const TextLink: React.FC<TextLinkProps> = ({
  className,
  variant = TextLinkVariant.black,
  noUnderline,
  noHover,
  showArrow,
  isExternal,
  children,
  ...props
}) => {
  return (
    <BaseLink
      className={clsx(s.TextLink, className, {
        [s.TextLink_noUnderline]: noUnderline,
        [s.TextLink_noHover]: noHover,
        [s[`TextLink_${variant}`]]: variant
      })}
      {...props}
    >
      {children}
    </BaseLink>
  );
};
