import React from 'react';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { LayoutContent } from '../../../../common/components/layout/LayoutContent/LayoutContent';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../../common/components/ui/Button';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import { SECTION_IDS } from '../../../../constants';
import { ScrollLink } from '../../../../common/components/ui/reactScroll/ScrollLink';
import { useWebinarsContext } from '../../../../store/webinar/hooks/useWebinarsContext';
import { useMetricsContext } from '../../../../store/metrics/hooks/useMetricsContext';
import { Timer } from './Timer/Timer';
import s from './SectionTimer.module.scss';

const today = new Date();
const daysToWebinarToShowTimer = 14;

export interface SectionTimerProps {}

export const SectionTimer: React.FC<SectionTimerProps> = () => {
  const { isDesktop } = useWindowSize();
  const { closestWebinar, setSelectedWebinarId } = useWebinarsContext();
  const { observeNode } = useMetricsContext();

  if (
    !closestWebinar ||
    closestWebinar.is_canceled ||
    differenceInCalendarDays(new Date(closestWebinar.date_start), today) >
      daysToWebinarToShowTimer
  ) {
    return null;
  }

  const date_start = closestWebinar.date_start;

  const timer = (
    <div className={s.SectionTimer__timer}>
      <p className={s.SectionTimer__timerTitle}>До события осталось:</p>
      <Timer date={date_start || ''} />
    </div>
  );

  return (
    <LayoutContent
      ref={(r) => r && observeNode(SECTION_IDS.timer, r)}
      className={s.SectionTimer}
      narrow
    >
      <div className={s.SectionTimer__grid}>
        <div className={s.SectionTimer__content}>
          <p className={s.SectionTimer__subTitle}>Тема этого месяца:</p>
          <h2 className={s.SectionTimer__title}>{closestWebinar.name}</h2>

          {!isDesktop && timer}

          <div className={s.SectionTimer__actions}>
            <ScrollLink
              className={s.SectionTimer__moreLink}
              to={SECTION_IDS.webinars}
            >
              <Button
                className={s.SectionTimer__moreButton}
                size={ButtonSize.xlarge}
                variant={ButtonVariant.whiteSecondary}
                onClick={() => setSelectedWebinarId(closestWebinar.id)}
              >
                Подробнее
              </Button>
            </ScrollLink>
          </div>
        </div>
        {isDesktop && timer}
      </div>
    </LayoutContent>
  );
};
