import { getCssVariable } from '../utils/helpers/getCssVariable';

const headerHeight = parseFloat(getCssVariable('--header-height', 0));
const pageLayoutGap = parseFloat(getCssVariable('--page-layout-gap', 0));

export const reactScrollDefaults = {
  smooth: true,
  offset: -(headerHeight + pageLayoutGap),
  duration: 500
};

export const reactScrollLinkDefaults = {
  hashSpy: true,
  // spyThrottle: 500
  ...reactScrollDefaults
};

export const externalLinkHtmlProps = {
  target: '_blank',
  rel: 'noopener noreferrer'
};
