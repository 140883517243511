import React from 'react';
import { AnyObject } from '../../utils/commonTypes';
import { SECTION_IDS } from '../../constants';
import { MetricsGoals } from './types';

export type MetricsContextType = {
  reachGoal: (goal: MetricsGoals | string, params?: AnyObject) => void;
  params: (params?: AnyObject | AnyObject[]) => void;
  observeNode: (id: SECTION_IDS, node: HTMLElement) => void;
};

export const MetricsContext = React.createContext<MetricsContextType>({
  reachGoal: () => {},
  params: () => {},
  observeNode: () => {}
});
