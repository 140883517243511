function env<T = string | undefined>(
  variable: string | undefined,
  defaultValue?: T
) {
  if (typeof variable === 'undefined') {
    return defaultValue;
  }
  return variable;
}

function requireEnv(variable: string | undefined, name: string) {
  if (typeof variable === 'undefined') {
    throw new Error(`Variable ${name} is required`);
  }
  return variable;
}

export const hostUrl = requireEnv(
  process.env.REACT_APP_HOST_URL,
  'REACT_APP_HOST_URL'
);

export const apiUrl = requireEnv(
  process.env.REACT_APP_API_URL,
  'REACT_APP_API_URL'
);

export const inDev =
  env(process.env.REACT_APP_ENV, 'production') === 'development';

// export const apiGraphqlUrl = apiUrl + '/graphql';

export const personalDataTermsUrl =
  'https://www.mos.ru/dit/documents/informatcionnaia-bezopasnost/view/274898220/';
export const userTermsUrl = 'https://www.mos.ru/legal/rules/';
