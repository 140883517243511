import { useAxiosQuery } from '@proscom/prostore-axios-react';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { skipIfNull } from '@proscom/prostore';
import { useMemo } from 'react';
import { WebinarType } from '../types/WebinarType';
import { QueryParamsType, QueryVarsType } from '../types/QueryVarsType';

const queryOptions: QueryParamsType<QueryVarsType | null, WebinarType | null> =
  {
    skipQuery: skipIfNull(null),
    mapVariables: AxiosQueryStore.mapVariablesParams
  };

export function useWebinar(id?: number) {
  const options = useMemo(() => {
    return {
      ...queryOptions,
      query: {
        url: `/webinars/${id}`
      }
    };
  }, [id]);

  return useAxiosQuery({
    queryOptions: options,
    variables: !id
      ? null
      : {
          populate: ['webinar_live_sign_ups']
        }
  });
}
