import { useAxiosQuery } from '@proscom/prostore-axios-react';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { WebinarType } from '../types/WebinarType';
import { QueryResultType } from '../types/QueryResultType';
import { QueryVarsType } from '../types/QueryVarsType';

const queryOptions = {
  query: {
    url: '/webinars'
  },
  mapVariables: AxiosQueryStore.mapVariablesParams
};

export function useWebinars(
  input?: QueryVarsType
): QueryResultType<WebinarType[]> {
  return useAxiosQuery({
    queryOptions,
    variables: input
  });
}
