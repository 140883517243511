import { TestQuestionType, TestResultType } from '../../api/types/TestType';

export type TestFormValues = {
  answer: string | null;
};

const testsAmountToPick = 10;

export class TestUtils {
  static pickTestQuestions(
    questions?: TestQuestionType[] | null
  ): TestQuestionType[] {
    if (!questions?.length) return [];
    const quests = questions.slice(0);
    quests.sort(() => 0.5 - Math.random());
    return quests.slice(0, testsAmountToPick);
  }

  static getResultsTitle(results: TestResultType[]): string {
    const resultsAmount = results.length;
    const correctResultsAmount = results.filter(
      (r) => r.answer?.isCorrectAnswer
    ).length;
    const correctResultsPercent = +(
      (correctResultsAmount / resultsAmount) *
      100
    ).toFixed();

    if (correctResultsPercent >= 85) {
      return 'Отличный результат!';
    }
    if (correctResultsPercent >= 60) {
      return 'Неплохой результат';
    }
    return 'Вас легко обмануть :(';
  }
}
