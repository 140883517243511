import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { ReactComponent as IconSuccess } from '../../../../assets/img/icons/result-alert/i-success.svg';
import { ReactComponent as IconError } from '../../../../assets/img/icons/result-alert/i-error.svg';
import { Icon } from '../Icon/Icon';
import s from './ResultAlert.module.scss';

export enum ResultAlertVariant {
  Success = 'success',
  Error = 'error'
}

const icons = {
  [ResultAlertVariant.Success]: IconSuccess,
  [ResultAlertVariant.Error]: IconError
};

export interface ResultAlertProps {
  className?: string;
  variant?: ResultAlertVariant;
  showIcon?: boolean;
}

export const ResultAlert = ({
  className,
  variant = ResultAlertVariant.Success,
  showIcon = true,
  children
}: PropsWithChildren<ResultAlertProps>) => {
  const IconComp = icons[variant];

  return (
    <div
      className={clsx(s.ResultAlert, s[`ResultAlert_${variant}`], className)}
    >
      {showIcon && (
        <Icon
          className={s.ResultAlert__icon}
          icon={<IconComp />}
          boxSize={40}
        />
      )}
      <div className={s.ResultAlert__content}>{children}</div>
    </div>
  );
};
