export function getCssVariable(
  varName: string,
  defaultValue?: string | number
): string {
  return (
    getComputedStyle(document.documentElement).getPropertyValue(varName) ||
    defaultValue?.toString() ||
    ''
  );
}
