import React, { MouseEventHandler, MutableRefObject } from 'react';
import clsx from 'clsx';
import s from './Icon.module.scss';

export enum IconBoxSize {
  small = 16,
  medium = 24,
  large = 32,
  xlarge = 48
}

export interface IconProps {
  className?: string;
  iconClassName?: string;
  icon?: React.ReactNode;
  /**
   * размер контейнера иконки
   */
  boxSize?: IconBoxSize | number | 'auto';
  /**
   * размер самой иконки в контейнере
   */
  iconSize?: number;
  iconRef?: MutableRefObject<any>;
  children?: React.ReactNode;
  onClick?: MouseEventHandler;
}

export const Icon = React.memo(function Icon({
  className,
  iconClassName,
  icon,
  boxSize = IconBoxSize.medium,
  iconSize,
  iconRef,
  children,
  onClick
}: IconProps) {
  const boxStyle =
    boxSize !== 'auto'
      ? {
          minWidth: `${boxSize}px`,
          width: `${boxSize}px`,
          height: `${boxSize}px`
        }
      : {};

  const iconStyle = iconSize
    ? {
        width: `${iconSize}px`,
        height: `${iconSize}px`
      }
    : {};

  const clickable = !!onClick;
  const IconComp = clickable ? 'button' : 'div';

  return (
    <IconComp
      ref={iconRef}
      className={clsx(s.Icon, className, {
        [s.Icon_clickable]: clickable
      })}
      style={boxStyle}
      onClick={onClick}
    >
      {icon && (
        <div className={clsx(s.Icon__icon, iconClassName)} style={iconStyle}>
          {icon}
        </div>
      )}
      {children}
    </IconComp>
  );
});
