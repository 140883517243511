import React, { MutableRefObject, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { externalLinkHtmlProps } from '../../../constants';

export interface BaseLinkProps {
  className?: string;
  type?: 'button' | 'submit';
  to?: string;
  href?: string;
  text?: ReactNode;
  title?: string;
  linkRef?: MutableRefObject<any>;
  isClickable?: boolean;
  children?: ReactNode;
  tabIndex?: number;
  onClick?: (e: React.MouseEvent<any>) => void;
  onFocusCapture?: (e: React.FocusEvent<any>) => void;
  onBlurCapture?: (e: React.FocusEvent<any>) => void;
  onMouseMove?: (e: React.MouseEvent<any>) => void;
  onMouseLeave?: (e: React.MouseEvent<any>) => void;
}

export const BaseLink: React.FC<BaseLinkProps> = ({
  type = 'button',
  to,
  href,
  linkRef,
  isClickable = true,
  ...props
}) => {
  if (!isClickable) {
    return <span ref={linkRef} {...props} />;
  }

  if (to) {
    return <Link to={to} ref={linkRef} {...props} />;
  }

  if (href) {
    // Контент передаётся через props.children
    /* eslint-disable jsx-a11y/anchor-has-content */
    return (
      <a href={href} ref={linkRef} {...externalLinkHtmlProps} {...props} />
    );
    /* eslint-enable jsx-a11y/anchor-has-content */
  }

  return <button type={type} ref={linkRef} {...props} />;
};
