import { useAxiosQuery } from '@proscom/prostore-axios-react';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { QueryResultType } from '../types/QueryResultType';
import { BannersDataType } from '../types/BannerType';

const queryOptions = {
  query: {
    url: '/banner'
  },
  mapVariables: AxiosQueryStore.mapVariablesParams
};

export function useBannersQuery(): QueryResultType<BannersDataType> {
  return useAxiosQuery({
    queryOptions,
    variables: {
      populate: {
        event_banners: {
          populate: 'image',
          filters: {
            enabled: {
              $eq: true
            }
          }
        }
      }
    }
  });
}
