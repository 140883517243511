import React, { MouseEvent, MouseEventHandler } from 'react';
import clsx from 'clsx';
import s from './Tabs.module.scss';

export type TabLabelType = string;
export type TabValueType = string | number;

export type TabType<V = TabValueType> = {
  label?: TabLabelType;
  value: V;
  disabled?: boolean;
  onClick?: MouseEventHandler;
};

export interface TabsProps<V> {
  className?: string;
  tabs: TabType<V>[];
  value: TabType<V>['value'];
  onChange: (value: TabType<V>['value']) => boolean | void;
}

export const Tabs = <V,>({
  className,
  tabs,
  value,
  onChange
}: TabsProps<V>) => {
  const selectedTab = tabs.find((t) => t.value === value);

  const handleTabClick = (e: MouseEvent, tab: TabType<V>) => {
    if (tab.disabled) return;
    const prevent = tab.onClick?.(e);
    if (!prevent) {
      onChange(tab.value);
    }
  };

  return (
    <div className={clsx(s.Tabs, className)}>
      {tabs.map((tab, iTab) => (
        <button
          key={iTab}
          className={clsx(s.Tab, {
            [s.Tab_active]: selectedTab?.value === tab.value
          })}
          disabled={tab.disabled}
          onClick={(e) => handleTabClick(e, tab)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};
