import { useAxiosQuery } from '@proscom/prostore-axios-react';
import { AxiosQueryStore } from '@proscom/prostore-axios';
import { QueryResultType } from '../types/QueryResultType';
import { CallCenterItemType } from '../types/CallCenterItemType';

const queryOptions = {
  query: {
    url: '/call-center-items'
  },
  mapVariables: AxiosQueryStore.mapVariablesParams
};

export function useCallCenterItems(): QueryResultType<CallCenterItemType[]> {
  return useAxiosQuery({
    queryOptions,
    variables: {
      populate: '*',
      sort: ['name:asc'],
      pagination: {
        pageSize: 500
      }
    }
  });
}
