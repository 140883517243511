import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useControlledState } from '@proscom/ui-react';
import { ReactComponent as IconChecked } from '../../../../assets/img/icons/i-check.svg';
import { InputPropsWithoutRef, LabelPropsWithoutRef } from '../../../types';
import s from './Checkbox.module.scss';

export type CheckboxNameType = string;

export enum CheckboxVariant {
  Checkbox = 'checkbox',
  Radio = 'radio'
}

export interface CheckboxSwitchClasses {
  root?: string;
  switch?: string;
  switchChecked?: string;
  icon?: string;
  iconChecked?: string;
}

export interface CheckboxSwitchProps {
  className?: string;
  classes?: CheckboxSwitchClasses;
  variant?: CheckboxVariant;
  checked?: boolean;
}

export const CheckboxSwitch: React.FC<CheckboxSwitchProps> = ({
  className,
  classes,
  variant,
  checked
}) => {
  const isCheckbox = variant === CheckboxVariant.Checkbox;

  return (
    <div className={clsx(s.Checkbox__switchBox, className, classes?.root)}>
      <div
        className={clsx(
          s.Checkbox__switch,
          s[`Checkbox__switch_${variant}`],
          classes?.switch,
          {
            [s.Checkbox__switch_checked]: checked,
            [classes?.switchChecked ?? '']: checked
          }
        )}
      >
        <div
          className={clsx(
            s.Checkbox__switchIcon,
            s[`Checkbox__switchIcon_${variant}`],
            classes?.icon,
            {
              [s.Checkbox__switchIcon_checked]: checked,
              [classes?.iconChecked ?? '']: checked
            }
          )}
        >
          {isCheckbox && <IconChecked />}
        </div>
      </div>
    </div>
  );
};

export interface CheckboxClasses {
  root?: string;
  label?: string;
  checkbox?: CheckboxSwitchClasses;
}

export interface BaseCheckboxProps extends Omit<InputPropsWithoutRef, 'name'> {
  classes?: CheckboxClasses;
  name?: CheckboxNameType;
  label?: React.ReactNode;
  variant?: CheckboxVariant;
  error?: boolean;
  rootProps?: LabelPropsWithoutRef;
}

export const BaseCheckbox: React.FC<BaseCheckboxProps> = ({
  className,
  classes,
  label,
  variant = CheckboxVariant.Checkbox,
  rootProps = {},
  error,
  ...props
}) => {
  return (
    <label
      className={clsx(s.Checkbox, className, classes?.root, {
        [s.Checkbox_checked]: props?.checked,
        [s.Checkbox_disabled]: props?.disabled,
        [s.Checkbox_error]: error
      })}
      {...rootProps}
    >
      <input className={s.Checkbox__input} type="checkbox" {...props} />

      <CheckboxSwitch
        classes={classes?.checkbox}
        variant={variant}
        checked={props?.checked}
      />

      {label && (
        <div className={clsx(s.Checkbox__label, classes?.label)}>{label}</div>
      )}
    </label>
  );
};

export interface CheckboxProps
  extends Omit<BaseCheckboxProps, 'onChange' | 'value' | 'defaultValue'> {
  value?: boolean;
  defaultValue?: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  className,
  value,
  defaultValue,
  disabled,
  onChange,
  ...props
}) => {
  const [checked, handleChange] = useControlledState(
    value,
    defaultValue,
    false,
    onChange
  );

  const handleCheckChange = useCallback(
    (e) => {
      handleChange(e.target.checked);
    },
    [handleChange]
  );

  return (
    <BaseCheckbox checked={checked} onChange={handleCheckChange} {...props} />
  );
};
