import React from 'react';
import { EMDASH } from '@proscom/ui-utils';
import { LayoutCard } from '../../../../../common/components/layout/LayoutCard/LayoutCard';
import { StoryType } from '../../../../../api/types/StoryType';
import { useImageUrl } from '../../../../../api/hooks/useImageUrl';
import s from './StoryCard.module.scss';

export interface StoryCardProps extends StoryType {
  onClick: () => void;
}

export const StoryCard: React.FC<StoryCardProps> = ({
  title,
  image,
  onClick
}) => {
  const imageSrc = useImageUrl(image);

  return (
    <LayoutCard className={s.StoryCard} onClick={onClick}>
      <div className={s.StoryCard__pic}>
        <img className={s.StoryCard__picImg} src={imageSrc} alt={title} />
      </div>
      <div className={s.StoryCard__cardTitle}>{title || EMDASH}</div>
    </LayoutCard>
  );
};
