import { isNil } from 'lodash-es';
import { format, isValid, parseISO } from 'date-fns';

export const DATE_FORMAT_FULL = 'dd MMMM yyyy';
export const DATE_FORMAT_DAY_MONTH = 'dd MMMM';
export const DATETIME_FORMAT_FULL = 'dd MMMM yyyy, HH:mm';
export const DATE_FORMAT_SHORT = 'dd.MM.yy';
export const DATE_FORMAT_SHORT_FULL_YEAR = 'dd.MM.yyyy';
export const MONTH_FORMAT_FULL = 'MMMM';
export const MONTH_FORMAT_SHORT = 'MMM';
export const DAY_FORMAT_FULL = 'EEEE';
export const DAY_FORMAT_SHORT = 'EEEEEE';
export const MONTHS = [
  {
    name: 'Январь',
    shortName: 'Янв',
    unitName: 'Января'
  },
  {
    name: 'Февраль',
    shortName: 'Фев',
    unitName: 'Февраля'
  },
  {
    name: 'Март',
    shortName: 'Мар',
    unitName: 'Марта'
  },
  {
    name: 'Апрель',
    shortName: 'Апр',
    unitName: 'Апреля'
  },
  {
    name: 'Май',
    shortName: 'Май',
    unitName: 'Мая'
  },
  {
    name: 'Июнь',
    shortName: 'Июн',
    unitName: 'Июня'
  },
  {
    name: 'Июль',
    shortName: 'Июл',
    unitName: 'Июля'
  },
  {
    name: 'Август',
    shortName: 'Авг',
    unitName: 'Августа'
  },
  {
    name: 'Сентябрь',
    shortName: 'Сен',
    unitName: 'Сентября'
  },
  {
    name: 'Октябрь',
    shortName: 'Окт',
    unitName: 'Октября'
  },
  {
    name: 'Ноябрь',
    shortName: 'Ноя',
    unitName: 'Ноября'
  },
  {
    name: 'Декабрь',
    shortName: 'Дек',
    unitName: 'Декабря'
  }
];

export function tryParseIso(string?: string) {
  if (!string) return null;
  const date = parseISO(string);
  if (isValid(date)) {
    return date;
  }
  return null;
}
export function tryIsoDate(string?: string) {
  if (!string) return null;
  try {
    const date = new Date(string);
    if (isValid(date)) {
      return date;
    }
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export function addZero(value: number): string {
  return !isNaN(value) && !isNil(value)
    ? `${value < 10 ? '0' : ''}${value}`
    : '';
}

/**
 * Достаёт дату из ISO-строки и возвращает объект Date который в локальном
 * часовом поясе указывает на начало дня этой даты
 *
 * @param dateString - строка в формате ISO
 */
export function getDateUtc<T extends string | undefined | null>(
  dateString: T
): T | string {
  return dateString && dateString.slice(0, 4 + 2 + 2 + 2) + 'T00:00:00';
}

export function convertToUtcStr(date?: Date): string;
export function convertToUtcStr(
  date?: Date | number | string | null
): string | undefined;
export function convertToUtcStr(date?: Date | number | string | null) {
  const d = date && new Date(date);
  const dateString = d ? format(d, 'yyyy-MM-dd') + 'T00:00:00Z' : undefined;

  return dateString && isValid(new Date(dateString)) ? dateString : undefined;
}

/**
 * Смещает ISO-строку из UTC в локальный часовой пояс с сохранением
 * времени
 *
 * @param date - строка в формате ISO
 */
export function parseIsoToLocal(date?: string): string {
  if (!date) return '';
  if (date[date.length - 1] !== 'Z') return date;
  return date.slice(0, date.length - 1);
}
