import { createLoadUrl } from '../utils/createLoadUrl';
import { ImageType, PopulatedType } from '../types/strapi.types';

export enum ImageSize {
  small = 'small',
  thumbnail = 'thumbnail'
}

export function createImageUrl(
  image: PopulatedType<ImageType> | undefined,
  size?: ImageSize
): string {
  if (!image?.data?.attributes) return '';

  let url = '';

  switch (size) {
    case ImageSize.small:
      url = image.data.attributes.formats.small.url;
      break;
    case ImageSize.thumbnail:
      url = image.data.attributes.formats.thumbnail.url;
      break;
    default:
      url = image.data.attributes.url;
      break;
  }

  return createLoadUrl(url);
}

export function useImageUrl(
  image: PopulatedType<ImageType> | undefined,
  size?: ImageSize
): string {
  return createImageUrl(image, size);
}
