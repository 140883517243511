import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { omit } from 'lodash-es';
import { setupCache } from 'axios-cache-adapter';
import { apiUrl } from '../config';

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  limit: 100,
  exclude: {
    // Only exclude PUT, PATCH and DELETE methods from cache
    methods: ['put', 'patch', 'delete']
    // query: false
  }
  // debug: true
});

const baseApiUrl = apiUrl;

function convertResponseData(data: AxiosResponse['data']) {
  return {
    ...omit(data, ['attributes']),
    ...data.attributes
  };
}

export function createSimpleClient(): AxiosInstance {
  return axios.create({ baseURL: baseApiUrl, adapter: cache.adapter });
}

export function createDefaultClient(): AxiosInstance {
  const defaultInstance = axios.create({
    // axios 0.21.0 игнорирует параметр url
    method: 'GET',
    adapter: cache.adapter
  });

  defaultInstance.interceptors.request.use((config) => {
    const domain = config.params?.domain || baseApiUrl;
    const baseConfig: AxiosRequestConfig = {
      ...config,
      baseURL: domain,
      // необходимо для таких сложных params, как, например, массив объектов
      paramsSerializer: ({ domain, ...params }) =>
        qs.stringify(params, { encodeValuesOnly: true })
    };
    return baseConfig;
  });

  // error handling
  defaultInstance.interceptors.response.use((response) => {
    // response.data может не быть объектом (а быть, например, числом или строкой)
    if (typeof response.data === 'object' && response.data !== null) {
      // попытка в нормальные сообщения об ошибках
      if ('errorcode' in response.data) {
        return Promise.reject(
          response.data?.message || JSON.stringify(response.data)
        );
      }
    } else if (typeof response.data === 'string') {
      // и для двойных склеенных ошибок
      if (response.data.includes('errorcode')) {
        try {
          // попытка вытащить сообщение об ошибке и отсюда
          return Promise.reject(
            JSON.parse(response.data.split('\n')[0]).message
          );
        } catch (err) {
          return Promise.reject(response.data);
        }
      }
    }

    // return response;

    const resData = response.data?.data;
    const resDataIsArray = Array.isArray(resData);

    return {
      ...response,
      data: resData
        ? resDataIsArray
          ? resData.map(convertResponseData)
          : convertResponseData(resData)
        : null
    };
  });

  return defaultInstance;
}
