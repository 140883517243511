import React from 'react';
import clsx from 'clsx';
import s from './HelpText.module.scss';

export enum HelpTextType {
  error = 'error',
  hint = 'hint'
}

export interface HelpTextProps {
  className?: string;
  type: HelpTextType;
  text?: React.ReactNode;
  children?: React.ReactNode;
}

export const HelpText: React.FC<HelpTextProps> = ({
  className,
  type,
  text,
  children
}) => {
  if (text || children) {
    return (
      <div className={clsx(s.HelpText, s[`HelpText_${type}`], className)}>
        {text || children}
      </div>
    );
  }
  return null;
};
