import React from 'react';

export type TestsContextType = {
  testCompleted: boolean;
  startTest: () => void;
};

export const TestContext = React.createContext<TestsContextType>({
  testCompleted: false,
  startTest: () => {}
});
