import React, { ReactNode } from 'react';

export enum ButtonSize {
  medium = 'medium',
  xlarge = 'xlarge'
}

export enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  ghost = 'ghost',
  white = 'white',
  whiteSecondary = 'whiteSecondary',
  whiteGhost = 'whiteGhost'
}

export interface ButtonClasses {
  root?: string;
  iconLeft?: string;
  iconRight?: string;
  icon?: string;
  content?: string;
}

export interface BaseButtonBaseProps {
  className: string;
  children: React.ReactNode;
}

export interface BaseButtonProps {
  className?: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  icon?: ReactNode;
  isIconButton?: boolean;
  classes?: ButtonClasses;
  children?: React.ReactNode;
  renderBase: (props: BaseButtonBaseProps) => JSX.Element;
}
