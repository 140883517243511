import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { EMDASH } from '@proscom/ui-utils';
import { CallCenterItemType } from '../../../../../api/types/CallCenterItemType';
import { TextLink } from '../../../../../common/components/ui/TextLink/TextLink';
import { CreatePhoneNumberType } from '../../../../../api/utils/createPhoneNumber';
import { ReactComponent as IconLink } from '../../../../../assets/img/icons/i-link.svg';
import { BaseLink } from '../../../../../common/components/ui/BaseLink/BaseLink';
import { Icon } from '../../../../../common/components/ui/Icon/Icon';
import {
  Tooltip,
  TooltipProps
} from '../../../../../common/components/ui/Tooltip/Tooltip';
import { useWindowSize } from '../../../../../common/hooks/useWindowSize';
import s from './CallCenterItem.module.scss';

interface WebsiteLinkProps {
  link: string;
  tooltipProps?: Omit<TooltipProps, 'targetNode' | 'children'>;
  children?: React.ReactNode;
}

const WebsiteLink = ({ link, children }: WebsiteLinkProps) => {
  const [ttActive, setTTActive] = useState(false);
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  return (
    <>
      <BaseLink
        className={s.Link}
        linkRef={linkRef}
        href={link}
        onMouseMove={() => setTTActive(true)}
        onMouseLeave={() => setTTActive(false)}
      >
        <Icon className={s.Link__icon} icon={<IconLink />} boxSize={'auto'} />
      </BaseLink>

      {ttActive && (
        <Tooltip
          className={s.Tooltip}
          classes={{ content: s.Tooltip__content }}
          targetNode={linkRef.current}
          tooltipOptions={{
            placement: 'top-end',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [20, 10]
                }
              }
            ]
          }}
        >
          {children}
        </Tooltip>
      )}
    </>
  );
};

export interface CallCenterItemProps
  extends Omit<CallCenterItemType, 'phoneNumber'> {
  className?: string;
  cardStyled?: boolean;
  phone: CreatePhoneNumberType | null;
}

export const CallCenterItem: React.FC<CallCenterItemProps> = ({
  className,
  cardStyled,
  name,
  organisation,
  phone,
  note,
  link
}) => {
  const { isDesktop } = useWindowSize();

  return (
    <div
      className={clsx(s.CallCenterItem, className, {
        [s.CallCenterItem_card]: cardStyled
      })}
    >
      <div className={clsx(s.CallCenterItem__col, s.CallCenterItem__col_name)}>
        <BaseLink
          className={s.CallCenterItem__name}
          href={!isDesktop && link ? link : ''}
          isClickable={!!(!isDesktop && link)}
        >
          {name || EMDASH}

          {isDesktop && link && (
            <WebsiteLink link={link}>
              <div className={s.Tooltip__title}>Переход на сайт</div>
              <div className={s.Tooltip__website}>{link}</div>
            </WebsiteLink>
          )}
        </BaseLink>
        {organisation && (
          <div className={s.CallCenterItem__org}>{organisation}</div>
        )}
      </div>
      <div className={clsx(s.CallCenterItem__col, s.CallCenterItem__col_phone)}>
        <TextLink
          className={s.CallCenterItem__phone}
          href={phone?.solid ? `tel:${phone?.solid}` : undefined}
          noUnderline
        >
          {phone?.formatted || EMDASH}
        </TextLink>
        {note && <div className={s.CallCenterItem__note}>{note}</div>}
      </div>
    </div>
  );
};
