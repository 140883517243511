import { TestAnswerType } from './TestType';
import { ImageType, PopulatedType } from './strapi.types';

export enum GameTargetEnum {
  Phone = 'Phone',
  TV = 'TV',
  Laptop = 'Laptop',
  Letters = 'Letters',
  Car = 'Car',
  Mobile = 'Mobile',
  Person = 'Person',
  Girl = 'Girl',
  Cat = 'Cat',
  Suitcase = 'Suitcase'
}

export type GameQuestionType = {
  id: number;
  target: GameTargetEnum;
  title: string;
  question: string;
  image?: PopulatedType<ImageType>;
  answers: TestAnswerType[];
};

export type GameResultType = {
  completeAmount: number;
  correctAmount: number;
  totalAmount: number;
};
