import { useField } from 'formik';
import React, { useCallback } from 'react';
import { BaseCheckbox, BaseCheckboxProps } from '../../Checkbox/Checkbox';

export interface FormCheckboxProps extends Omit<BaseCheckboxProps, 'name'> {
  name: string;
}

export function FormCheckbox({ name, ...props }: FormCheckboxProps) {
  const [field, meta, helpers] = useField(name);
  const { value } = field;

  const { error, touched } = meta;

  return (
    <BaseCheckbox
      checked={!!value}
      error={!!(touched && error)}
      {...field}
      {...props}
    />
  );
}

interface FormCheckboxArrayProps extends FormCheckboxProps {
  checkedValue: string | number;
}

export function FormCheckboxArray({
  name,
  checkedValue,
  ...props
}: FormCheckboxArrayProps) {
  const [field, meta, { setValue }] = useField(name);
  const { value } = field;

  const { error, touched } = meta;

  const handleChange = useCallback(() => {
    const currentValue = value || [];
    setValue(
      currentValue.includes(checkedValue)
        ? currentValue.filter((x) => x !== checkedValue)
        : [...currentValue, checkedValue]
    );
  }, [setValue, value, checkedValue]);

  return (
    <BaseCheckbox
      {...field}
      checked={value?.includes(checkedValue)}
      onChange={handleChange}
      error={!!(touched && error)}
      {...props}
    />
  );
}
