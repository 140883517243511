import React from 'react';
import { formatDate } from '../../../../../common/hooks/useFormatDate';
import { DateIndicator, DateIndicatorVariant } from './DateIndicator';
import s from './WebinarsCalendar.module.scss';

const monthsArray = new Array(12).fill(null).map((_, i) => {
  const d = new Date();
  d.setDate(1);
  d.setMonth(i);
  return formatDate(d, 'LLLL');
});

export type CalendarDataType = {
  id: number;
  date: string;
  isOnlineOnly: boolean;
  isPast: boolean;
};

export interface WebinarsCalendarProps {
  data: CalendarDataType[];
  onWebinarSelected: (id: number) => void;
}

export const WebinarsCalendar = React.memo(
  ({ data, onWebinarSelected }: WebinarsCalendarProps) => {
    const groups = monthsArray.map((m, i) => {
      return data.filter((item) => {
        const dMonth = new Date(item.date).getMonth();
        return i === dMonth;
      });
    });

    return (
      <div className={s.WebinarsCalendar}>
        {groups.map((group, iGroup) => (
          <div key={iGroup} className={s.Group}>
            <div className={s.Group__content}>
              <div className={s.Group__month}>{monthsArray[iGroup]}</div>
              <div className={s.Group__events}>
                {group.map((w, iW) => (
                  <DateIndicator
                    key={iW}
                    date={w.date}
                    variant={
                      w.isOnlineOnly
                        ? DateIndicatorVariant.Orange
                        : DateIndicatorVariant.Green
                    }
                    disabled={w.isPast}
                    onClick={() => onWebinarSelected(w.id)}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
);
