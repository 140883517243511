import React, { FocusEventHandler, useCallback } from 'react';
import clsx from 'clsx';
import {
  DropzoneOptions,
  FileError,
  FileRejection,
  useDropzone
} from 'react-dropzone';
import { flatten, isNil, unionWith, uniqBy } from 'lodash-es';
import { ReactComponent as IconClose } from '../../../../assets/img/icons/i-close.svg';
import { ReactComponent as IconDownload } from '../../../../assets/img/icons/i-download.svg';
import { ButtonIcon } from '../Button';
import { Icon } from '../Icon/Icon';
import { HelpText, HelpTextType } from '../HelpText/HelpText';
import { formatNumberIntl } from '../../../../utils/number/intl';
import s from './FileUpload.module.scss';

type ErrorPropsType = {
  maxSize?: number;
  // maxFiles?: number;
  // accept?: string[];
};

const errorFns = {
  'file-invalid-type': (cfg: ErrorPropsType) => 'Недопустимый формат файла',
  'file-too-large': (cfg: ErrorPropsType) =>
    `Максимальный размер файла: ${formatNumberIntl(cfg.maxSize || 0, {
      formatFilesize: true
    })}`
};

function convertError(error: FileError, cfg: ErrorPropsType): string {
  return errorFns[error.code]?.(cfg) || error.message;
}

function concatFiles(value?: File[], newFiles?: File[]): File[] {
  return unionWith(
    value || [],
    newFiles || [],
    (l, r) => l.name === r.name && l.lastModified === r.lastModified
  );
}

function getFileName(file: File): string {
  const nameArray = file.name.split('.');
  return nameArray.slice(0, -1).join('');
}

function getFileExt(file: File): string {
  const nameArray = file.name.split('.');
  return nameArray[nameArray.length - 1];
}

export enum FileUploadSize {
  medium = 'medium',
  xlarge = 'xlarge'
}

export interface FileUploadProps
  extends Pick<DropzoneOptions, 'accept' | 'maxSize' | 'maxFiles'> {
  className?: string;
  label?: string;
  value?: File[];
  size?: FileUploadSize;
  errorMessage?: string;
  hintMessage?: string;
  onChange: (files: File[]) => void;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  onError?: (error: string) => void;
}

export const FileUpload = ({
  className,
  label,
  value,
  size = FileUploadSize.medium,
  maxFiles,
  maxSize,
  errorMessage,
  hintMessage,
  onChange,
  onFocus,
  onBlur,
  onError,
  ...dzProps
}: FileUploadProps) => {
  const onRemove = (file: File, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (value?.length) {
      onChange(
        value.filter(
          (f) => f.name !== file.name && f.lastModified !== file.lastModified
        )
      );
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length) {
        const errMessages = uniqBy(
          flatten(rejectedFiles.map((f) => f.errors)),
          'code'
        ).map((err) =>
          convertError(err, {
            maxSize
          })
        );
        onError?.(errMessages.join('\r\n'));
      }

      if (acceptedFiles.length) {
        const maxFilesToAccept = isNil(maxFiles)
          ? acceptedFiles.length
          : maxFiles - (value?.length || 0);

        if (
          !isNil(maxFilesToAccept) &&
          acceptedFiles.length > maxFilesToAccept
        ) {
          onError?.(`Максимум файлов: ${maxFiles} шт.`);
        }

        onChange(concatFiles(value, acceptedFiles.slice(0, maxFilesToAccept)));
      }
    },
    [onError, maxFiles, maxSize, value, onChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    preventDropOnDocument: true,
    onDrop,
    maxSize,
    ...dzProps
  });

  const rootProps = getRootProps();
  const inputProps = getInputProps();

  return (
    <label
      className={clsx(s.FileUpload, className)}
      onClick={rootProps.onClick}
    >
      {label && <span className={s.FileUpload__label}>{label}</span>}
      <div
        {...rootProps}
        className={clsx(s.FileUpload__dropzone, rootProps.className, {
          [s.FileUpload__dragging]: isDragActive,
          [s.FileUpload__error]: errorMessage
        })}
      >
        <input {...inputProps} />

        <div
          className={clsx(s.FileUpload__title, s[`FileUpload__title_${size}`])}
        >
          <Icon
            className={s.FileUpload__titleIcon}
            icon={<IconDownload />}
            boxSize={24}
          />
          Перетащите файлы в область для загрузки
        </div>

        {!!value?.length && (
          <div className={s.FileUpload__files}>
            <div className={s.FileUpload__filesLabel}>Выбранные файлы:</div>
            {value?.map((f) => (
              <div key={`${f.name}-${f.lastModified}`} className={s.File}>
                <div className={s.File__name}>
                  <span className={s.File__nameName}>{getFileName(f)}</span>
                  <span className={s.File__nameExt}>.{getFileExt(f)}</span>
                </div>
                <ButtonIcon
                  className={s.File__removeButton}
                  icon={<IconClose />}
                  onClick={(e) => onRemove(f, e)}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <HelpText
        text={errorMessage || hintMessage}
        type={errorMessage ? HelpTextType.error : HelpTextType.hint}
      />
    </label>
  );
};
